import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Breadcrumb, Select
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class AddLga extends React.Component {

    static contextType = AuthContext
    state = {
        state_id: "",
        name: "",
        loading: false,
        states: [],
        countries: [],
        country: ""
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/countries`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {
            this.setState({
                isFetching: false,
                countries: res1.data.entity
            })
        }));
    }

    fetchStates() {
        const filterState = this.state.country ? `?country=${encodeURIComponent(this.state.country)}` : ''
        axios.all([
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {
            this.setState({
                isFetching: false,
                states: res1.data.entity.states
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            state_id: this.state.state_id,
        }
        if (!this.state.state_id || !this.state.name) {
            return this.props.userStore.openNotificationWithIcon('error', 'LGA/District Name and State/Region Name required')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/lga`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added country")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/lgas">LGA / DistrictManagement</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New LGA / District
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Add New LGA / District</Title>
                        <Form onSubmit={this.handleSubmit} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>Name</span>}>
                                <Input onChange={(e) => this.setState({ name: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>Country</span>}>
                                <Select defaultValue="Select Country" onChange={(value) => this.setState({ country: value }, () => { this.fetchStates() })}>
                                    {this.state.countries.map((item) => {
                                        return (
                                            <Option key={item._id} value={item.code}>{item.country}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                colon={false}
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>State</span>}>
                                <Select defaultValue="Select State" onChange={(value) => this.setState({ state_id: value })}>
                                    {this.state.states.map((item) => {
                                        return (
                                            <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddLga);
