import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb, Row, Col, Spin } from 'antd';
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck, faTasks, faMapMarkedAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
// import { Link } from 'react-router-dom'
// import moment from 'moment'
import Drawer from './Drawer'
import config from '../../config'

// const { RangePicker } = DatePicker;


// const SubMenu = Menu.SubMenu;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

@inject('userStore')
@observer
class VerificationDashboard extends React.Component {
    state = {
        isFetching: true,
        whiteListed: 0,
        kycCaptured: 0,
        gpsMapped: 0,
        verifiedFarms: 71,
        kycVerified: 0
    };

    // handleChange = val => {
    //     this.setState({ crop: val }, () => {
    //         this.filterByCrop()
    //     })
    // };

    componentDidMount() {
        if (this.props.userStore.role.role === "Admin") {
            this.fetchContentAdmin()
        }
        this.fetchDash()
    }


    async fetchDash() {
        await axios({
            url: `${config.baseUrl}/dashboard/dv2`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res => {
            console.log(res)
            this.setState({
                isFetching: false,
                bvnVerified: res.data.entity.bvn_verified,
                totalPool: res.data.entity.total_pool,
                gpsMapped: res.data.entity.gps_mapped,
                submitted: res.data.entity.submitted,
            })
        })).catch(err => {
            this.setState({ isFetching: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }
    async fetchContentAdmin() {
        await axios.all([
            axios({
                url: `${config.baseUrl}/config/crop_profiles`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/admin/dashboard`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/admin/tenants`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3) => {
            console.log(res2)
            this.setState({
                isFetching: false,
                data: res2.data.entity,
                crops: res1.data.entity.profiles,
                tenants: res3.data.entity,

            })

        })).catch(err => {
            this.setState({ isFetching: false })

        });
    }




    onChange = (e) => {
        const data = {
            start: e[0],
            end: e[1]
        }
        axios({
            url: `${config.baseUrl}/dashboard/map`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ activities: res.data.entity })

        })
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    {/* <div className={classes.toolbar} /> */}
                    <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                        <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Verification Dashboard</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        {this.state.isFetching ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Spin />
                            </div> :
                            <div>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <div style={{
                                            border: '3px solid #f2f3f4',

                                            backgroundColor: 'white',
                                            padding: 20,
                                            borderRadius: 15,
                                            boxShadow: "3px 3px 10px #f2f3f4"
                                        }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontSize: 12, fontWeight: '700' }}>Whitelisted Farmers</p>
                                                <FontAwesomeIcon icon={faTasks} color="#1890ff" style={{ fontSize: 24 }} />
                                            </div>
                                            <h1 style={{ fontWeight: '700' }}>{this.state.whiteListed}</h1>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            border: '3px solid #f2f3f4',

                                            backgroundColor: 'white',
                                            padding: 20,
                                            borderRadius: 15,
                                            boxShadow: "3px 3px 10px #f2f3f4"
                                        }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontSize: 12, fontWeight: '700' }}>Total KYC Captured</p>
                                                <FontAwesomeIcon icon={faUserCheck} color="#1890ff" style={{ fontSize: 24 }} />
                                            </div>
                                            <h1 style={{ fontWeight: '700' }}>{this.state.kycCaptured}</h1>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            border: '3px solid #f2f3f4',

                                            backgroundColor: 'white',
                                            padding: 20,
                                            borderRadius: 15,
                                            boxShadow: "3px 3px 10px #f2f3f4"
                                        }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontSize: 12, fontWeight: '700' }}>Total Mapped Farms</p>
                                                <FontAwesomeIcon icon={faMapMarkedAlt} color="#1890ff" style={{ fontSize: 24 }} />
                                            </div>
                                            <h1 style={{ fontWeight: '700' }}>{this.state.gpsMapped}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ height: 30 }}></div>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <div style={{
                                            border: '3px solid #f2f3f4',

                                            backgroundColor: 'white',
                                            padding: 20,
                                            borderRadius: 15,
                                            boxShadow: "3px 3px 10px #f2f3f4"
                                        }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontSize: 12, fontWeight: '700' }}>Verified Farms</p>
                                                <FontAwesomeIcon icon={faCheck} color="#1890ff" style={{ fontSize: 24 }} />
                                            </div>
                                            <h1 style={{ fontWeight: '700' }}>{this.state.verifiedFarms}</h1>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{
                                            border: '3px solid #f2f3f4',

                                            backgroundColor: 'white',
                                            padding: 20,
                                            borderRadius: 15,
                                            boxShadow: "3px 3px 10px #f2f3f4"
                                        }}>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <p style={{ fontSize: 12, fontWeight: '700' }}>KYC Verified</p>
                                                <FontAwesomeIcon icon={faCheck} color="#1890ff" style={{ fontSize: 24 }} />
                                            </div>
                                            <h1 style={{ fontWeight: '700' }}>{this.state.kycVerified}</h1>
                                        </div>
                                    </Col>
                                    <Col span={8}></Col>
                                </Row>
                            </div>
                        }

                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(VerificationDashboard);
