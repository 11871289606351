import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import classNames from 'classnames';
import axios from 'axios';
import { Typography, Table, Button, Tag, Tooltip, Icon } from 'antd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import config from '../../config'
import { formatNumber } from '../../config/utils';

const { Title } = Typography;
const { Column } = Table;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: '#203a45',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});


@inject('userStore')
@observer
class UploadStatus extends React.Component {

    state = {
        isLoading: true,
        deleting: false,
        data: [],
        recordToDelete: "",
        pagination: {
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
        }

    }

    componentDidMount() {
        this.fetchContent()

        setInterval(() => this.fetchContent(), 15000)
    }

    componentWillMount() {
        clearInterval()
    }


    async fetchContent() {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.uploadBaseUrl}/upload/status`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            this.setState({ isLoading: false })
            const statusArray = []
            const data = Object.entries(res.data)

            data.forEach(item => {
                const status = {
                    name: item[0],
                    ...item[1]
                }
                statusArray.push(status)
            })
            this.setState({ data: statusArray })
        }).catch(err => {
            console.log(err.response)
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    deleteStatus = async (record) => {
        this.setState({ deleting: true, recordToDelete: record.name })
        await axios({
            url: `${config.uploadBaseUrl}/upload/status/delete/${record.name}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            console.log(res)
            this.setState({ deleting: false })
            this.fetchContent()
        }).catch(err => {
            this.setState({ deleting: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    renderTag = (status) => {
        switch (status) {
            case "In Progress":
                return <Tag color="orange">{status}</Tag>
            case "Done":
                return <Tag color="green">{status}</Tag>
            default:
                return <Tag>{status}</Tag>
        }
    }

    renderPerc = (record) => {
        let done = record.done || 0
        let skipped = record.skipped || 0
        let total = record.total || 0

        let percentage = (done + skipped) / total

        return (percentage * 100).toFixed(1)
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* <Drawer /> */}
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: false,
                    })}
                >
                    <Toolbar>

                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>Upload Status</Title>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title="Refresh Data">
                                <Button type="default" onClick={() => this.fetchContent()}>
                                    <Icon type="undo" size={36} style={{ color: '#2A3F54' }} />
                                </Button>
                            </Tooltip>
                        </div>
                        <br />
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            pagination={this.state.pagination}
                            rowKey={record => record.name}
                            dataSource={this.state.data}>
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"

                                sorter={(a, b) => a.name.localeCompare(b.name)}

                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text) => (
                                    <span>
                                        {this.renderTag(text)}

                                    </span>
                                )}
                            />
                            <Column
                                title="Done"
                                dataIndex="done"
                                key="done"
                                render={(text) => (
                                    <span>
                                        {text ? formatNumber(text) : 0}
                                    </span>
                                )}
                            />
                            <Column
                                title="Skipped"
                                dataIndex="skipped"
                                key="skipped"
                                render={(text) => (
                                    <span>
                                        {text ? formatNumber(text) : 0}
                                    </span>
                                )}
                            />
                            <Column
                                title="Total"
                                dataIndex="total"
                                key="total"
                                render={(text) => (
                                    <span>
                                        {text ? formatNumber(text) : 0}
                                    </span>
                                )}
                            />
                            <Column
                                title="% Completed"
                                dataIndex=""
                                key="completed"
                                render={(text, record) => (
                                    <span>
                                        {this.renderPerc(record)}
                                        {/* {text ? formatNumber(text) : 0} */}
                                    </span>
                                )}
                            />
                            <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Button
                                            loading={this.state.deleting && record.name === this.state.recordToDelete}
                                            type="danger"
                                            onClick={() => this.deleteStatus(record)}>
                                            Delete
                                        </Button>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(UploadStatus);
