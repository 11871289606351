import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Typography, InputNumber, Button, Row, Col, Card, Form, Table, Tag, Tooltip, Icon, Breadcrumb, Skeleton, Modal
} from 'antd';
import PaystackButton from 'react-paystack';
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;
const { Column } = Table;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class Setup extends React.Component {

    static contextType = AuthContext
    state = {
        name: "",
        crop: "",
        val: "",
        data: [],
        recipients: [],
        loading: true,
        details: null,
        modalVisible: false,
        amount: 0,
        info: "",
        p_key: "",
        ref: "",
        email: "",
        payDisabled: true
    }

    componentDidMount() {
        this.getDetails()

    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    };

    handleOk = async (e) => {
        if (!this.state.amount) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please enter an amount')
        }
        await this.initPayment().then(() => {
            this.payRef.payWithPaystack()
        })
        this.setState({ modalVisible: false })

    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
            amount: 0
        });
    };

    getDetails() {
        axios.all([
            axios({
                url: `${config.baseUrl}/sms/scheduled`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ loading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/sms/details`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ loading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2) => {
            console.log(res1, res2)
            this.setState({
                loading: false,
                data: res1.data.entity,
                details: res2.data.entity,
            })
        }));
    }

    initPayment = async () => {
        const data = {
            amount: this.state.amount
        }
        await axios({
            url: `${config.baseUrl}/payments/sms`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                payDisabled: false,
                amount: res.data.entity.amount,
                email: res.data.entity.email,
                p_key: res.data.entity.p_key,
                ref: res.data.entity.ref,
                info: res.data.entity.info

            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    callback = (paystackResponse) => {
        this.verify(paystackResponse)
    }

    close = () => {
        this.setState({ amount: 0 })
    }

    verify = async (paystackResponse) => {
        const data = {
            code: paystackResponse.reference
        }
        await axios({
            url: `${config.baseUrl}/payments/verify`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {

            return this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleChangeAmount = (amount) => {
        this.setState({ amount })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Communication
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Setup
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Card
                                style={{ height: 230 }}
                                bodyStyle={{ color: '#116E6E', fontWeight: 'bold' }}
                                headStyle={{ backgroundColor: '#009688', color: '#fff' }}
                                title="Sender Details">
                                {this.state.details ? <div>
                                    <Title level={4}>Sender ID</Title>
                                    <Title level={3}>{this.state.details.sms_sender_id}</Title>
                                </div> : <Skeleton active />}
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                style={{ height: 230 }}
                                bodyStyle={{ color: '#116E6E', fontWeight: 'bold' }}
                                headStyle={{ backgroundColor: '#009688', color: '#fff' }}
                                title="SMS Credit">
                                {this.state.details ? <div>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Title style={{ color: '#2A3F54' }} level={4}>Available Balance</Title>
                                            <Title style={{ color: '#2A3F54' }} level={4}>
                                                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(parseInt(this.state.details.sms_balance))}
                                            </Title>
                                        </Col>
                                        <Col span={12}>
                                            <Title style={{ color: '#2A3F54' }} level={4}>Price per SMS</Title>
                                            <Title style={{ color: '#2A3F54' }} level={4}>
                                                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(4)}
                                            </Title>
                                        </Col>
                                    </Row>
                                </div> : <Skeleton active />}

                                <Button type="primary" onClick={this.showModal} style={{ background: '#009688', marginTop: 10 }}>Recharge SMS</Button>
                            </Card>
                        </Col>
                    </Row>
                    <div>
                        <div>
                            <Link to="/communication/new_sms">
                                <Button type="primary" className={classes.button} style={{ background: '#009688' }}>
                                    Add New Schedule
                                </Button>
                            </Link>
                        </div>
                        <div className='table-container'>
                            <Title style={{ color: '#2A3F54' }} level={4}>Scheduled Messages</Title>
                            <Table
                                bordered
                                loading={this.state.loading}
                                rowKey={record => record._id}
                                dataSource={this.state.data}>
                                <Column
                                    title="Date"
                                    dataIndex="scheduled_date"
                                    key="scheduled_date"

                                />
                                <Column
                                    title="Status"
                                    dataIndex="status"
                                    key="status"
                                    render={(text) => (
                                        <span>
                                            {text === 0

                                                ?
                                                <Tag color="red">Not Sent</Tag>
                                                :
                                                <Tag color="green">Sent</Tag>
                                            }
                                        </span>
                                    )}
                                />
                                <Column
                                    title="Recipients"
                                    dataIndex="recipients"
                                    key="recipients"
                                    render={(text) => (
                                        <span>
                                            {text === 0

                                                ?
                                                <Tag color="red">Not Sent</Tag>
                                                :
                                                <Tag color="green">Sent</Tag>
                                            }
                                        </span>
                                    )}
                                />
                                <Column
                                    title="Recipients' group"
                                    dataIndex="group"
                                    key="group"
                                    render={(text) => (
                                        <span>
                                            {text === 0

                                                ?
                                                <Tag color="red">Not Sent</Tag>
                                                :
                                                <Tag color="green">Sent</Tag>
                                            }
                                        </span>
                                    )}
                                />
                                <Column
                                    title="Action"
                                    key="actions"
                                    render={(text, record) => (
                                        <span>
                                            <Tooltip title="Download">
                                                <Button style={{ marginLeft: 2 }} type="default">
                                                    <Icon type="download" style={{ color: '#ea1b2a' }} />
                                                </Button>
                                            </Tooltip>
                                        </span>
                                    )}
                                />
                            </Table>
                            <Modal
                                title="Recharge SMS"
                                visible={this.state.modalVisible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button key="back" onClick={this.handleCancel}>
                                        Cancel
                                    </Button>,
                                    <Button key="ok" onClick={this.handleOk}>
                                        Ok
                                    </Button>,
                                    <PaystackButton
                                        key="pay"
                                        text="Pay"
                                        className="payButton"
                                        callback={this.callback}
                                        close={this.close}
                                        disabled={this.state.payDisabled}
                                        embed={false}
                                        ref={(payRef) => this.payRef = payRef}
                                        reference={this.state.ref}
                                        email={this.state.email}
                                        amount={(this.state.amount)}
                                        paystackkey={this.state.p_key}
                                        tag="button"
                                    />
                                ]}
                            >
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Amount">
                                    <InputNumber value={this.state.amount} autoFocus onChange={(e) => this.handleChangeAmount(e)} style={{ width: '100%' }} />
                                </Form.Item>
                                <br />
                            </Modal>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Setup);
