import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb, Row, Col, Card, Tag, Tooltip, Icon } from 'antd';

import Map from '../farm/FarmMap';


import Drawer from '../dashboard/Drawer'

// const { Panel } = Collapse;
const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

function VerificationDetails(props) {

    const { classes } = props;

    console.log(props.location)

    const { state } = props.location


    const getPoints = () => {
        const { coord } = props.location.state
        const coordPair = coord.split(",")
        const polygon = coordPair.map(pair => {
            const obj = {
                lat: parseFloat(pair.split(":")[0]),
                lng: parseFloat(pair.split(":")[1])
            }
            return obj
        })
        return polygon
    }

    const renderVerificationCheck = (data) => {
        switch (data) {
            case "YES":
                return <Tag color="green">{data}</Tag>
            case "NO":
                return <Tag color="red">{data}</Tag>
            default:
                return <Tag color="orange">{data}</Tag>
        }

    }

    const renderWithin = (data) => {
        if (data === "true") {
            return <Tag color="green">True</Tag>
        }
        else {
            return <Tag color="red">False</Tag>
        }
    }

    // const renderCoord = () => {
    //     const { coord } = state
    //     const coordPair = coord.split(",")
    //     return coordPair.map((pair, i) => <p key={i}>{pair},</p>)
    // }
    const points = getPoints()
    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Verification Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <div>
                        <h5>Unique ID: {state.reference}</h5>
                        <Row>
                            <Col span={12}>
                                {points && points.length &&
                                    <Map
                                        height="40vh"
                                        points={points}
                                    />
                                }
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={11}>
                                <Card title="User Provided Info" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}> Partner</h6>
                                                <p>{state.partner}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}> Crop</h6>
                                                <p> {state.crop}</p>
                                            </div>
                                            <div>
                                                <Tooltip title={state.coord}>
                                                    <h6 style={{ fontWeight: 800, cursor: 'pointer' }}> Coordinates   <span><Icon type="info-circle" /></span></h6>
                                                </Tooltip>
                                                {/* <Collapse defaultActiveKey={['0']}>
                                                    <Panel header="Click to View" key="1">
                                                        {renderCoord()}
                                                    </Panel>
                                                </Collapse> */}

                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>Country</h6>
                                                <p>{state.country || "N/A"}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>State</h6>
                                                <p>{state.state || "N/A"}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>LGA</h6>
                                                <p>{state.lga || "N/A"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 50 }}></div>
                        <Row gutter={24}>
                            <Col span={11}>
                                <Card title="Actions" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Vegetation Check:</h6>
                                        <h6>{renderVerificationCheck(state.status)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within Country:</h6>
                                        <h6>{renderWithin(state.within_country)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within State:</h6>
                                        <h6>{renderWithin(state.within_state)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within LGA:</h6>
                                        <h6>{renderWithin(state.within_lga)}</h6>
                                    </div>
                                </Card>

                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Card title="Returned Info" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Country:</h6>
                                        <h6>{state.returned_country}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>State:</h6>
                                        <h6>{state.returned_state}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>LGA:</h6>
                                        <h6>{state.returned_lga}</h6>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(VerificationDetails);
