import React from 'react'
import userStore from '../store/User'

export const AuthContext = React.createContext()


class Provider extends React.Component {
    constructor() {
        super()
        userStore.getUserFromStorage()
        this.state = {
            authenticated: localStorage.getItem('user-auth') ? true : false,
            // firstname: "",
            // lastname: "",
            // email: "",
            // token: "",
            // userToken: ""
        }
    }

    setUser =  (data) => {
        userStore.setUser(data)
        this.setState({
            // firstname: data.user.first_name,
            // lastname: data.user.last_name,
            // email: data.user.email,
            // userToken: data.user.token,
            // token: data.token,
            authenticated: true
       })
        // localStorage.setItem('user-auth',JSON.stringify(data))
    }

    // getUserFromStorage = async () => {
    //     const data = await localStorage.getItem('user-auth')
    //     if(data !== null) {
    //         const parsed = JSON.parse(data)
    //         await this.setState({
    //             firstname: parsed.user.first_name,
    //             lastname: parsed.user.last_name,
    //             email: parsed.user.email,
    //             userToken: parsed.user.token,
    //             token: parsed.token,
    //         })
    //     }
    //     else {
    //         return
    //     }

    // }

    logout = () => {
        userStore.logout()
        this.setState({
            // firstname: "",
            // lastname: "",
            // email: "",
            // userToken: "",
            // token: "",
            authenticated: false
        })
        // this.openNotificationWithIcon('success', "You have been logged out")
    }

    //  openNotificationWithIcon = (type, message) => {
    //     notification[type]({
    //         style: {
    //             marginTop: 50
    //         },
    //         message,
    //     });
    // };

    render() {
        return (
            <AuthContext.Provider value={{
                ...this.state, 
                setUser: (user) => this.setUser(user),
                // openNotificationWithIcon: (type, message) => this.openNotificationWithIcon(type, message),
                logout: this.logout
            }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export default Provider