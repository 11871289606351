import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    // InfoWindow,
    Polygon
} from "react-google-maps";
import { observer, inject } from 'mobx-react'



const CustomSkinMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={20}
            mapTypeId="satellite"
            // center={{ lat: 5.5262733, lng: 7.5261817 }}
            center={
                props.center
            }
        >
            {props.data && props.data.map(item => {
                return <Polygon
                    // path={item.points && reversed(item.points)}
                    key={item.id}
                    path={item.points}
                    options={{
                        fillColor: "#009688",
                        fillOpacity: 0.4,
                        strokeColor: "#000",
                        strokeOpacity: 1,
                        strokeWeight: 3
                    }}>
                </Polygon>
            })}
        </GoogleMap>
    ))
);

@inject('userStore')
@observer
class Maps extends React.Component {

    state = {
        activeMarker: 0,
    }



    setActiveMarker = (markerid) => {
        this.setState({ activeMarker: markerid })
    }


    render() {
        return (
            <CustomSkinMap
                activeMarker={this.state.activeMarker}
                data={this.props.data}
                center={this.props.center}
                setActiveMarker={this.setActiveMarker}
                userStore={this.props.userStore}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `50vh` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        )
    }
}

export default Maps
