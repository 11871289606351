import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Checkbox, DatePicker, Row, Col, Breadcrumb
} from 'antd';
import { Link } from 'react-router-dom'
import Papa from 'papaparse'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const Option = Select.Option;
const { Title } = Typography;
const { TextArea } = Input;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class NewMessage extends React.Component {

    static contextType = AuthContext
    state = {
        message_type: "",
        message_group: "",
        message_cluster: "",
        message: "",
        date: "",
        recipients: "",
        loading: false,
        showingMessageGroup: false,
        showingTime: false,
        clusters: []
    }

    componentDidMount() {
        this.fetchContent()
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        if (!this.state.message) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please enter a message to send')
        }
        if (!this.state.message_type) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a message type')
        }
        if (this.state.message_type === "group" && !this.state.message_group) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a message group')
        }
        if (this.state.message_type === "group" && !this.state.message_cluster) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a message cluster')
        }
        if (this.state.message_type === "custom" && !this.state.recipients) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply message recipients')
        }
        let data = {}
        if (this.state.message_type === "group") {
            data = {
                message_type: this.state.message_type,
                message_group: this.state.message_group,
                message_cluster: this.state.message_cluster,
                message: this.state.message,
                scheduled_date: this.state.date
            }
        }
        else {
            data = {
                message_type: "custom",
                recipients: this.state.recipients,
                message: this.state.message,
                scheduled_date: this.state.date
            }
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/sms`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            console.log(res)
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully Sent Message")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    fetchContent = async () => {
        await axios({
            url: `${config.baseUrl}/clusters`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ clusters: res.data.entity })

        }).catch((err) => {
            this.setState({ isFetching: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSchedule = (val) => {
        this.setState({
            showingTime: !this.state.showingTime,
            val
        })
    }

    handleFile = (e) => {
        const file = (e.target.files[0])
        Papa.parse(file, {
            delimiter: ",",
            complete: (results) => this.parseResults(results)
        })
    }

    parseResults = (results) => {
        if (results.errors.length > 0) {
            this.props.userStore.openNotificationWithIcon('error', "Error parsing CSV file")
            return
        }
        const recipients = results.data.join()
        this.setState({ recipients })
    }

    recipients = () => {
        if (!this.state.message_type) {
            return null
        }
        else if (this.state.message_type === "custom") {
            return (
                <Form.Item
                    style={{ margin: 0 }}
                    label="recipients">
                    <TextArea value={this.state.recipients} onChange={(e) => this.setState({ recipients: e.target.value })} />
                    <Row gutter={12}>
                        <Col span={6}>
                            <div>
                                <Button onClick={() => this.fileInput.click()} htmlFor="files">Select CSV File</Button>
                                <input
                                    accept="csv"
                                    onChange={this.handleFile}
                                    ref={fileInput => this.fileInput = fileInput}
                                    style={{ display: 'none' }} type="file" />
                            </div>
                        </Col>
                        <Col span={6}>
                            <div>
                                <Button onClick={this.downloadFile} htmlFor="files">Download CSV Template</Button>
                            </div>
                        </Col>
                    </Row>
                </Form.Item>
            )
        }
        else {
            return (
                <Form.Item
                    label="Recipients">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Select defaultValue="Select Group" onChange={(value) => this.setState({ message_group: value })}>
                                <Option value="lead">Lead Farmers</Option>
                                <Option value="farmers">All Farmers</Option>
                            </Select>
                        </Col>
                        <Col span={12}>
                            <Select defaultValue="Select Cluster" onChange={(value) => this.setState({ message_cluster: value })}>
                                {this.state.clusters.map((item) => {
                                    return (
                                        <Option key={item._id} value={item._id}>{item.name}</Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                </Form.Item>

            )
        }
    }

    downloadFile = () => {
        axios({
            url: 'https://test.tradebuza.com/files/download/smsTest.csv',
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'smsTest.csv');
            document.body.appendChild(link);
            link.click();
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Communication
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/communication/sms_history">Sent Messages</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                New Message
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div> :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Compose New Message</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    label="Type of  Message">
                                    <Select defaultValue="Select Message Type" onChange={(value) => this.setState({ message_type: value })}>
                                        <Option value="group">Group Message</Option>
                                        <Option value="custom">Custom Message</Option>
                                    </Select>
                                </Form.Item>
                                {this.recipients()}
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Message">
                                    <TextArea onChange={(e) => this.setState({ message: e.target.value })} />
                                </Form.Item>
                                <Form.Item>
                                    <Checkbox onChange={(e) => this.handleSchedule(e.target.value)} value="schedule">Schedule Message</Checkbox>
                                </Form.Item>
                                {this.state.showingTime &&
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        label="Schedule Date">
                                        <DatePicker onChange={(_, date) => this.setState({ date })} />
                                    </Form.Item>}
                                <Form.Item>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(NewMessage);
