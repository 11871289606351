import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Breadcrumb
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'

const Option = Select.Option;
const { Title } = Typography;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class AssignFarm extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        ...this.props.location.state,
        sub_cluster: null,
        sub_clusters: [],
        farmer_id: this.props.location.state.farmer._id,
        farmers: [],
        fetchingFarmers: false
    }


    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/subclusters`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            // axios({
            //     url: `${config.baseUrl}/farmers`,
            //     method: 'GET',
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${this.props.userStore.token}`
            //     },
            // }).catch(err => {
            //     this.setState({ isFetching: false })
            //     this.props.userStore.openNotificationWithIcon('error', err.message)
            // })
        ]).then(axios.spread((res1) => {
            console.log(res1)
            this.setState({
                isFetching: false,
                // farmers: res2.data.aaData,
                sub_clusters: res1.data.aaData
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            farmer_id: this.state.farmer_id,
            sub_cluster: this.state.sub_cluster
        }
        if (!this.state.sub_cluster) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select a sub cluster')
        }
        if (!this.state.farmer) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select a farmer')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/farm/${this.props.location.state.farm_id}/assign`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully assigned Farm")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleFilter = async (query) => {
        this.setState({ fetchingFarmers: true })
        await axios({
            url: `${config.baseUrl}/farmers?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            const farmers = res.data.aaData.filter(item => item.farm_lot !== 1)
            console.log(farmers)
            this.setState({ fetchingFarmers: false, farmers })
        })
    }


    render() {
        console.log(this.state)
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Assign Farm
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Assign Farm</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Lot ID">
                                    <Input disabled value={`${this.state.farm_id} / ${this.state.size}Ha`} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Farmer">
                                    <Select
                                        defaultValue="Select Farmer"
                                        showSearch
                                        filterOption={false}
                                        notFoundContent={this.state.fetchingFarmers ? <Spin size="small" /> : null}
                                        onSearch={this.handleFilter}
                                        onChange={(value) => this.setState({ farmer_id: value })}>
                                        {this.state.farmers.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.farmer_id} / {item.first_name} {item.last_name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Sub Cluster">
                                    <Select defaultValue="Select Sub Cluster" onChange={(value) => this.setState({ sub_cluster: value })}>
                                        {this.state.sub_clusters.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AssignFarm);
