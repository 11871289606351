import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Icon, Tooltip, Modal, Tag, Breadcrumb, Carousel, Select, Spin } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'

import config from '../../config'

const { Title } = Typography;
const { Column } = Table
const { Option } = Select



const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    }
});


@inject('userStore')
@observer
class AgentActivities extends React.Component {

    state = {
        data: [],
        sub_clusters: [],
        tasks: [],
        categories: [],
        farmers: [],
        pagination: {
            pageSize: 10,
            pageSizeOptions: ['10', '20', '50', '100'],
            showSizeChanger: true,
            showTotal: (total, range) => {
                return `Showing ${range[0]}-${range[1]} of ${total} items`
            }
        },
        isLoading: true,
        pageUrl: 0,
        visible: false,
        imageUrl: '',
        currentActivity: null,
        activeFilters: { category: "", task: "", farmer: "" },
        fetchingFarmers: false
    }

    componentDidMount() {
        this.fetchContent()
    }

    showModal = (text) => {
        if (text.images && text.images.length) {
            this.setState({ currentActivity: text, visible: true })
        }
        else {
            this.props.userStore.openNotificationWithIcon('error', "No images to display")
        }
    };

    async fetchContent() {
        axios.all([
            await axios({
                url: `${config.baseUrl}/config/categories`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/farm/activities?page=${this.state.pageUrl}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/config/tasks`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/farmers`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3, res4) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res2.data.iTotalRecords
            pagination.current = parseInt(res2.data.current_page + 1)//sets pagination page to 1
            this.setState({
                data: res2.data.aaData,
                categories: res1.data.entity.categories,
                tasks: res3.data.entity.tasks,
                farmers: res4.data.aaData,
                pagination,
                isLoading: false,
            })
        }));
    }

    checkFilter = (pagination) => {
        //set page if pagination
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }
        //construct URL
        const filterPage = page === 0 ? '' : `&page=${page}`
        const filterPageSize = `?per_page=${pageSize}`
        const filterCategory = this.state.activeFilters.category ? `&category=${this.state.activeFilters.category}` : ''
        const filterTask = this.state.activeFilters.task ? `&task=${this.state.activeFilters.task}` : ''
        const filterFarmer = this.state.activeFilters.farmer ? `&farmer=${this.state.activeFilters.farmer}` : ''

        const filterPath = filterPageSize + filterPage + filterCategory + filterTask + filterFarmer


        let url = `${config.baseUrl}/farm/activities${filterPath}`;

        this.filter(url)
    }


    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.current = parseInt(res.data.current_page + 1)//sets pagination page to 1
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })

        this.setState({ isLoading: true })
        await axios({
            url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            this.setState({
                isLoading: false,
                data: res.data.aaData,
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    renderStatus = (status) => {
        if (status === "skipped") {
            return (
                <Tag color="blue">Skipped</Tag>
            )
        }
        else if (status === "done") {
            return (
                <Tag color="green">Done</Tag>
            )
        }
        else if (status === "delayed") {
            return (
                <Tag color="red">Delayed</Tag>
            )
        }
        else if (status === "ongoing") {
            return (
                <Tag color="orange">Ongoing</Tag>
            )
        }
        else if (status === "pending") {
            return (
                <Tag color="grey">Pending</Tag>
            )
        }
        else {
            return (
                <Tag color="white"></Tag>
            )
        }
    }


    onSearch = async (query) => {
        this.setState({ fetchingFarmers: true })
        await axios({
            url: `${config.baseUrl}/farmers?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            // const farmers = res.data.aaData.filter(item => item.farm_lot !== 1)
            this.setState({ fetchingFarmers: false, farmers: res.data.aaData })
        }).catch(err => {
            this.setState({ fetchingFarmers: false })
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/dashboard">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/activities/dashboard">Farm Activities Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Agent Activities
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Agent Activities</Title>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>

                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Category"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, category: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>

                                {this.state.categories.map(item => (
                                    <Option key={item._id} value={item._id}>{item.category}</Option>
                                ))}

                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                showSearch
                                onSearch={this.onSearch}
                                filterOption={false}
                                notFoundContent={this.state.fetchingFarmers ? <Spin size="small" /> : null}
                                defaultValue="Farmer"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, farmer: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>

                                {this.state.farmers.map(item => (
                                    <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                ))}

                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Task"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, task: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                {this.state.tasks.map(item => (
                                    <Option key={item._id} value={item._id}>{item.task}</Option>
                                ))}
                            </Select>
                        </div>
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>
                            <Column
                                title="Unit ID"
                                dataIndex="farm.farm_id"
                                key="farm.farm_id"
                            />
                            <Column
                                title="Agent Name"
                                dataIndex="agent"
                                key="agent"
                                render={(text, record) => (
                                    <span>
                                        {`${text.first_name} ${text.last_name}`}
                                    </span>
                                )}
                            />
                            <Column
                                title="Farmer Name"
                                dataIndex="farmer"
                                key="farmer"
                                render={(text, record) => (
                                    <span>
                                        {text ? `${text.first_name} ${text.last_name}` : ''}
                                    </span>
                                )}
                            />
                            <Column
                                title="Date Created"
                                dataIndex="created"
                                key="created"
                            />
                            <Column
                                title="Category"
                                dataIndex="category.category"
                                key="category.category"
                            />
                            <Column
                                title="Task"
                                dataIndex="task.task"
                                key="task.task"
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                filters={[{
                                    text: 'Done',
                                    value: 'done',
                                },
                                {
                                    text: 'Delayed',
                                    value: 'delayed',
                                },
                                {
                                    text: 'Skipped',
                                    value: 'skipped',
                                }
                                ]}
                                onFilter={(value, record) => record.status === value}
                                render={(text) => (
                                    <span>
                                        {this.renderStatus(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Tooltip title="View">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.showModal(text)}>
                                                <Icon type="eye" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                )}
                            />
                        </Table>
                        <div className="modal-container">
                            <Modal
                                title="Tracked Images"
                                visible={this.state.visible}
                                // onOk={handleSubmit}
                                onCancel={() => this.setState({ visible: false })}
                                footer={null}
                            >
                                <Carousel>
                                    {this.state.currentActivity && this.state.currentActivity.images && this.state.currentActivity.images.map(activity => {
                                        return (
                                            <div className="img-cont" key={activity._id}>
                                                <img alt="activity" className="responsive-img" src={activity.image} />

                                            </div>
                                        )
                                    })}

                                </Carousel>
                                {this.state.currentActivity && !this.state.currentActivity.images && <div>
                                    No Images
                                </div>
                                }
                            </Modal>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AgentActivities);

