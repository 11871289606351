import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Typography, Button, Breadcrumb, Select
} from 'antd';
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
    }
});

@inject('userStore')
@observer
class Utils extends React.Component {

    static contextType = AuthContext
    state = {
        loading: true,
        busy: false,
        tenants: [],
        tenant_id: null
    }


    componentDidMount() {
        this.fetchData()
    }


    fetchData = async () => {
        await axios({
            url: `${config.baseUrl}/tenants`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                loading: false,
                tenants: res.data.entity.tenants
            })

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    onSearch = async (query) => {
        await axios({
            url: `${config.baseUrl}/tenants?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                tenants: res.data.entity.tenants
            })
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Utils
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Download Farmer Dump</Title>
                        <Form onSubmit={this.handleSubmit} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Select Tenant">
                                <Select
                                    autoClearSearchValue={false}
                                    showSearch
                                    onSearch={this.onSearch}
                                    defaultValue="Select Tenant"
                                    onChange={(value) => this.setState({ tenant_id: value })}>
                                    {this.state.tenants.map((item) => {
                                        return (
                                            <Option key={item._id} value={item.tenant_id}>{item.name}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <Button className={classes.button} loading={this.state.busy} type="primary" htmlType="submit">
                                    {this.state.busy ? "" : "Download"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Utils);
