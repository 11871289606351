import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import classNames from 'classnames';
import axios from 'axios'
import { Typography, Table, Input, Button } from 'antd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import config from '../../config'


const { Title } = Typography;
const { Column } = Table;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: '#203a45',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});

@inject('userStore')
@observer
class Active extends React.Component {

    state = {
        isLoading: true,
        data: [],
        tenant_id: this.props.location.search.split("=")[1],
        number: { association: "", no: "", state_id: "" },
        loading: false
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        const tenant_id = this.props.location.search.split("=")[1]
        axios.all([
            axios({
                url: `${config.baseUrl}/util/associations?t=${tenant_id}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/states`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((data, states) => {
            console.log(data)
            this.setState({
                isLoading: false,
                data: data.data.entity,
                states: states.data.entity.states,
            })

        }));
    }

    editNumber = async () => {
        const data = {
            ...this.state.number,
            association: this.state.number.association.split("State")[1]
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/util/associations`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            this.setState({ loading: false, number: { association: "", state_id: "", no: "" } })
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            console.log(err.response)
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* <Drawer /> */}
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: false,
                    })}
                >
                    <Toolbar>

                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>Associations</Title>
                        <br />
                        {/* <p style={{ color: '#2A3F54' }}>Filters</p> */}
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Season"
                            >
                                <Option value="">All</Option>
                                <Option value="">Rice</Option>
                                <Option value="">Cassava</Option>
                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="State"
                            >
                                <Option value="">All</Option>
                                <Option value="">Kano</Option>
                                <Option value="">Kaduna</Option>
                                <Option value="">Bauchi</Option>
                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="LGA"
                            >
                                <Option value="">All</Option>
                                <Option value="">Dawanau</Option>
                                <Option value="">Zaria</Option>
                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Association"
                            >
                                <Option value="">All</Option>
                                <Option value="">Cassave Growers Association</Option>
                                <Option value="">Rubies</Option>
                            </Select>
                            <RangePicker
                                ranges={{
                                    Today: [moment(), moment().add(1, 'days')],
                                    'Yesterday': [moment().add(-1, 'days'), moment()],
                                    'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                    'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                }}
                            />
                        </div> */}
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            pagination={this.state.pagination}
                            rowKey={record => `${record.home_state}${record.client_name}`}
                            dataSource={this.state.data}>
                            <Column
                                title="State"
                                dataIndex="home_state"
                                key="home_state"

                            />
                            <Column
                                title="Association"
                                dataIndex="client_name"
                                key="client_name"
                            />
                            <Column
                                title=""
                                render={(text, record) => (
                                    <Input
                                        type="number"
                                        value={`${record.home_state}${record.client_name}` === this.state.number.association ? this.state.number.no : ""}
                                        onChange={(e) => {
                                            this.setState({
                                                number: { association: `${record.home_state}${record.client_name}`, no: e.target.value, state_id: record.state_id }
                                            })
                                        }} />
                                )}
                            />
                            <Column
                                title="Action"
                                render={(text, record) => (
                                    <span>
                                        {`${record.home_state}${record.client_name}` === this.state.number.association && this.state.number.no
                                            ?
                                            <Button type="link" loading={this.state.loading} onClick={() => this.editNumber()}>Save</Button>
                                            :
                                            null}
                                    </span>

                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Active);
