import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "../dashboard/Drawer";
import { Link } from "react-router-dom";
import {
  Button,
  Typography,
  Table,
  Icon,
  Tooltip,
  Select,
  Popconfirm,
  Breadcrumb,
} from "antd";
import { inject, observer } from "mobx-react";
import axios from "axios";
import config from "../../config";

const Option = Select.Option;
const { Title } = Typography;
const { Column } = Table;

// const columns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Description',
//     dataIndex: 'description',
//     key: 'description',
//   },
//   {
//     title: 'Tenant ID',
//     dataIndex: 'tenant_id',
//     key: 'tenant_id',
//   },
// ];

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    // background: '#2A3F54',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: "#fff",
    // '&:hover': {
    //   background: '#1d2c3a',
    // },
    // '&:focus': {
    //   outline: 0,
    //   background: '#1d2c3a',
    // },
  },
  menuBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

@inject("userStore")
@observer
class FarmActivities extends React.Component {
  state = {
    isLoading: true,
    cropData: [],
    data: [],
    dataFilter: [],
  };

  componentDidMount() {
    this.getData();
    this.fetchContent();
  }

  fetchContent = async () => {
    await axios({
      url: `${config.baseUrl}/config/crop_profiles`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ isFetching: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.setState({ cropData: res.data.entity.profiles });
      })
      .catch((err) => {
        this.setState({ isFetching: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  getData = async () => {
    await axios({
      url: `${config.baseUrl}/config/tasks`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.setState({ data: res.data.entity.tasks });
      })
      .then(() => {
        this.setState({ dataFilter: this.state.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  confirmDelete = async (task) => {
    this.setState({ isLoading: true });
    await axios({
      url: `${config.baseUrl}/config/task/${task._id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        this.props.userStore.openNotificationWithIcon(
          "success",
          res.data.status.desc
        );
        this.getData();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.props.userStore.openNotificationWithIcon("error", err.message);
      });
  };

  handleChange = (val) => {
    if (val === "All") {
      this.setState({ data: this.state.dataFilter });
    } else {
      this.setState({
        data: this.state.dataFilter.filter(
          (item) =>
            item.category.crop_profile !== null &&
            item.category.crop_profile._id === val
        ),
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#f2f3f4",
              padding: 10,
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item>Manage Farm Activities</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: "#2A3F54" }} level={3}>
              Farming Activities Configuration
            </Title>
          </div>
          <div className={classes.menuBar}>
            <div>
              <Select
                defaultValue='Select crop'
                style={{ width: 120, marginRight: 10 }}
                onChange={this.handleChange}
              >
                <Option value='All'>All</Option>
                {this.state.cropData.map((item) => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {item.crop_name}
                    </Option>
                  );
                })}
              </Select>
              <Link to='/farm_activities/categories'>
                <Button
                  type='primary'
                  className={classes.button}
                  style={{ background: "#009688" }}
                >
                  Manage Categories
                </Button>
              </Link>
            </div>
            <div>
              <Link
                style={{ marginRight: 10 }}
                to='/farm_activities/categories/new'
              >
                <Button
                  type='primary'
                  className={classes.button}
                  style={{ background: "#009688" }}
                >
                  Add Category
                </Button>
              </Link>
              <Link to='/farm_activities/categories/addTask'>
                <Button
                  type='primary'
                  className={classes.button}
                  style={{ background: "#009688" }}
                >
                  Add Task
                </Button>
              </Link>
            </div>
          </div>

          <div
            className='table-container'
            style={{ padding: 24, background: "#fff" }}
          >
            <Title style={{ color: "#2A3F54" }} level={4}>
              All Activities
            </Title>
            <Table
              bordered
              loading={this.state.isLoading}
              rowKey={(record) => record._id}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: () => { console.log(record) },       // click row
              //   };
              // }}
              dataSource={this.state.data}
            >
              <Column
                title='Category'
                dataIndex='category.category'
                key='category'
              />
              <Column
                title='Task'
                dataIndex='task'
                key='task'
                render={(text, record) => <span>{text}</span>}
              />
              <Column
                title='Crop Profile'
                dataIndex='category.crop_profile.crop_name'
                key='crop_profile'
                render={(text, record) => <span>{text}</span>}
              />
              <Column
                title='Expected Done Date'
                dataIndex='due_date'
                key='due_date'
                render={(text, record) => <span>{text}</span>}
              />
              <Column
                title='Weight on Yield'
                dataIndex='category.crop_profile.yield'
                key='yield'
              />
              <Column
                title='Actions'
                key='actions'
                render={(text, record) => (
                  <span>
                    {/* <Tooltip title='Edit Activity'>
                      <Button style={{ marginLeft: 2 }} type='default'>
                        <Icon type='edit' />
                      </Button>
                    </Tooltip> */}
                    <Tooltip title='Delete Activity'>
                      <Popconfirm
                        title='Are you sure you want to delete this activity?'
                        onConfirm={() => this.confirmDelete(text)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button style={{ marginLeft: 2 }} type='default'>
                          <Icon type='delete' style={{ color: "#ea1b2a" }} />
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  </span>
                )}
              />
            </Table>
          </div>
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FarmActivities);
