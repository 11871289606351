import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthRoute, UnauthRoute } from 'react-router-auth'
import Login from './components/auth/Login';
import { AuthContext } from './context/AuthContext'
import './App.css';
import Forgot from './components/auth/Forgot';
import Activities from './components/dashboard/Activities';
import AgentActivities from './components/dashboard/AgentActivities';
import Reset from './components/auth/Reset'
import Users from './components/users/Users'
import Groups from './components/users/Groups';
import Tenants from './components/tenants/Tenants';
import AddTenant from './components/tenants/AddTenant';
import AddUser from './components/users/AddUser';
import Profile from './components/users/Profile';
import AddGroup from './components/users/AddGroup';
import EditTenant from './components/tenants/EditTenant'
import EditUser from './components/users/EditUser';
import EditGroup from './components/users/EditGroup';
import CropProfiles from './components/configuration/CropProfiles';
import AddCropProfile from './components/configuration/AddCropProfile';
import EditCropProfile from './components/configuration/EditCropProfile';
import FarmSeasons from './components/configuration/FarmSeasons';
import NewSeason from './components/configuration/NewSeason';
import FarmActivities from './components/configuration/FarmActivities';
import ManageCategories from './components/configuration/ManageCategories';
import EditCategory from './components/configuration/EditCategory';
import AddCategory from './components/configuration/AddCategory';
import AddTask from './components/configuration/AddTask';
import FarmLots from './components/farm/FarmLots';
import AddFarmUnit from './components/farm/AddFarmUnit';
import EditFarm from './components/farm/EditFarm';
import AssignFarm from './components/farm/AssignFarm';
import Clusters from './components/farm/Clusters';
import ClusterDetails from './components/farm/ClusterDetails';
import AddCluster from './components/farm/AddCluster';
import SubClusters from './components/farm/SubClusters';
import AddSubCluster from './components/farm/AddSubCluster';
import EditSubCluster from './components/farm/EditSubCluster';
import NewMessage from './components/communication/NewMessage';
import MessageHistory from './components/communication/MessageHistory';
import Farmers from './components/users/Farmers';
import AddFarmer from './components/users/AddFarmer';
import EditFarmer from './components/users/EditFarmer';
import Lots from './components/farm/Lots';
import Setup from './components/communication/Setup';
import EditCluster from './components/farm/EditCluster';
import Collections from './components/collection/Collections';
import SourcingAgent from './components/sourcingAgent/SourcingAgent';
import AddSourcingAgent from './components/sourcingAgent/AddSourcingAgent';
import AddCollection from './components/collection/AddCollection';
import PaymentSettings from './components/payment/Settings';
import Settings from './components/settings/Settings';
import Payments from './components/payment/Payments';
import PaymentCollections from './components/payment/Collections'
import DashboardIndex from './components/dashboard/DashboardIndex';
import Dvalco1 from './components/dashboard/Dvalco1';
import ServiceProvider from './components/serviceprovider/ServiceProvider';
import AddServiceProvider from './components/serviceprovider/AddServiceProvider';
import Hazie from './components/dashboard/Hazie';
import StagingFarms from './components/farm/StagingFarms';
import ProgrammeIndex from './components/dashboard/ProgrammeIndex';
import VerificationDashboard from './components/dashboard/VerificationDashboard';
import DistributionDashboard from './components/dashboard/DistributionDashboard';

import Dashboard from './components/dashboard/Dashboard';
import Active from './components/misc/Active';
import History from './components/misc/History';
import Client from './components/misc/Client';
import Input from './components/input/Input';
import ManualInput from './components/input/ManualInput';
import Verification from './components/input/Verification';
import VerificationDetails from './components/input/VerificationDetails';
import Utils from './components/utils/Utils';
import UploadStatus from './components/misc/UploadStatus';
import Jobs from './components/jobs/Jobs';
import Downloads from './components/downloads/Downloads';
import Review from './components/review/Review';
import ReviewDetails from './components/review/ReviewDetails';
import Polygons from './components/dashboard/Polygons';
import PendingReview from './components/review/PendingReview';
import PendingDetails from './components/review/PendingDetails';

import MaybeResults from './components/input/MaybeResults';
import MaybeDetails from './components/input/MaybeDetails';
import ClusterManagement from './components/farm/ClusterManagement';
import LandAgreement from './components/farm/LandAgreement';
import ReviewFarms from './components/review/ReviewFarms';
import ReviewFarmDetails from './components/review/ReviewFarmDetails';
import Countries from './components/countries/Countries';
import Currencies from './components/currencies/Currencies';
import AddCountry from './components/countries/AddCountry';
import AddCurrency from './components/currencies/AddCurrency';
import States from './components/states/States';
import AddState from './components/states/AddState';
import Lgas from './components/lgas/Lgas';
import AddLga from './components/lgas/AddLga';
import AgentLogin from './components/auth/AgentLogin';
import AgentFarmerUnits from './components/users/AgentFarmerUnits';
import AgentAddFarmUnit from './components/farm/AgentAddFarmUnit';


class App extends Component {

  static contextType = AuthContext

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/agent" component={AgentLogin} />
          {/* AgentFarmerUnits */}
          <Route exact path="/farmers-units" component={AgentFarmerUnits}  />
          <Route exact path="/add-farmer" component={AgentAddFarmUnit} />
          
          <Route exact path="/active-mapping" component={Active} />
          <Route exact path="/historical-mapping" component={History} />
          <Route exact path="/upload-status" component={UploadStatus} />
          <Route exact path="/associations" component={Client} />
          <Route exact path="/maybe-results" component={MaybeResults} />
          <Route exact path="/maybe-details" component={MaybeDetails} />
          <UnauthRoute exact path="/" component={Login} redirectTo="/dashboard" authenticated={this.context.authenticated} />
          <UnauthRoute exact path="/forgot" component={Forgot} redirectTo="/dashboard" authenticated={this.context.authenticated} />
          <UnauthRoute exact path="/reset-password/:token" component={Reset} redirectTo="/dashboard" authenticated={this.context.authenticated} />
          <UnauthRoute exact path="/forgot" component={Forgot} redirectTo="/dashboard" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard" component={DashboardIndex} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/gps" component={Dvalco1} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/overview" component={ProgrammeIndex} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/verification" component={VerificationDashboard} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/polygons" component={Polygons} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/admin" component={Dashboard} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/dashboard/3" component={DistributionDashboard} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/activities/dashboard" component={Activities} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/activities/view" component={AgentActivities} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/users" component={Users} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/users/add" component={AddUser} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/users/edit/:id" component={EditUser} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/tenants" component={Tenants} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/tenants/add" component={AddTenant} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/tenants/:id" component={EditTenant} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/groups" component={Groups} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/groups/add" component={AddGroup} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/groups/edit/:id" component={EditGroup} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/profile" component={Profile} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/crop_profiles" component={CropProfiles} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/crop_profiles/add" component={AddCropProfile} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/crop_profiles/edit/:id" component={EditCropProfile} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/seasons" component={FarmSeasons} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/seasons/new" component={NewSeason} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm_activities" component={FarmActivities} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm_activities/categories" component={ManageCategories} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm_activities/categories/new" component={AddCategory} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm_activities/categories/addtask" component={AddTask} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm_activities/categories/:id" component={EditCategory} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms" component={FarmLots} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/add" component={AddFarmUnit} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/clusters" component={Clusters} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/clusters/add" component={AddCluster} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/clusters/edit/:id" component={EditCluster} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/clusters/:id" component={ClusterDetails} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/sub-clusters" component={SubClusters} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/sub-clusters/add" component={AddSubCluster} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/sub-clusters/edit/:id" component={EditSubCluster} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/sub-clusters/:id" component={Lots} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/:id/assign" component={AssignFarm} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farms/:id" component={EditFarm} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/communication/new_sms" component={NewMessage} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/communication/setup" component={Setup} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/communication/sms_history" component={MessageHistory} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farmers" component={Farmers} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farmers/add" component={AddFarmer} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farmers/:id" component={EditFarmer} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/collections" component={Collections} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/collections/add" component={AddCollection} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/collections/settings" component={Settings} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/sourcing-agents" component={SourcingAgent} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/sourcing-agents/add" component={AddSourcingAgent} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/payments/transactions" component={Payments} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/payments/collections" component={PaymentCollections} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/payments/settings" component={PaymentSettings} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/settings" component={Settings} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/service-providers" component={ServiceProvider} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/service-providers/add" component={AddServiceProvider} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/hazie" component={Hazie} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farmers-pg" component={StagingFarms} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/input" component={Input} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/utils" component={Utils} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/jobs" component={Jobs} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/downloads" component={Downloads} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/manual-input" component={ManualInput} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/verification" component={Verification} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/verification-details" component={VerificationDetails} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/reviews" component={Review} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/pending-reviews" component={PendingReview} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/pending-reviews/:farmerId" component={PendingDetails} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/review-details/:farmerId" component={ReviewDetails} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm-cluster-management" component={ClusterManagement} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/farm-cluster-management/land-agreement" component={LandAgreement} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/review-farms" component={ReviewFarms} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/review-farms/:clusterId" component={ReviewFarmDetails} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/countries" component={Countries} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/countries/add" component={AddCountry} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/currencies" component={Currencies} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/currencies/add" component={AddCurrency} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/states" component={States} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/states/add" component={AddState} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/lgas" component={Lgas} redirectTo="/" authenticated={this.context.authenticated} />
          <AuthRoute exact path="/lgas/add" component={AddLga} redirectTo="/" authenticated={this.context.authenticated} />
        </Switch>
      </div>
    );
  }
}

export default App;
