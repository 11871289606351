import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, Breadcrumb, Button, Icon, Tag } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import moment from 'moment'
import config from '../../config'
const { Title } = Typography;
const { Column } = Table

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});


@inject('userStore')
@observer
class Downloads extends React.Component {

    state = {
        downloads: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,

    }

    componentDidMount() {
        this.fetchContent()
    }

    downloadKYF = (record) => {
        window.open(record.link, "_blank")
    }

    downloadCoords = (record) => {
        window.open(record.link2, "_blank")
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/downloads`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {
            this.setState({
                isLoading: false,
                downloads: res1.data.entity,
            })
            console.log(res1)
        }));
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Downloads
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Downloads</Title>
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.downloads}>

                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                            />

                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text, record) => (
                                    <span>
                                        {text.toLowerCase() === "pending"
                                            ?
                                            <Tag color="orange">{text}</Tag>
                                            :
                                            <Tag color="green">{text}</Tag>
                                        }
                                    </span>
                                )}
                            />
                            <Column
                                title="Date Created"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LL, h:mm:A')}
                                    </span>
                                )}
                            />
                            <Column
                                title="Download KYF"
                                render={(_, record) => {
                                    if (!record.link) {
                                        return <span></span>
                                    }
                                    return (
                                        <Button style={{ marginRight: 2 }
                                        } type="default" onClick={() => this.downloadKYF(record)}>
                                            <Icon type="download" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                            <Column
                                title="Download Coordinates"
                                render={(_, record) => {
                                    if (!record.link2) {
                                        return <span></span>
                                    }
                                    return (
                                        <Button style={{ marginRight: 2 }
                                        } type="default" onClick={() => this.downloadCoords(record)}>
                                            <Icon type="download" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Downloads);
