import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import classNames from 'classnames';
import axios from 'axios'
import moment from 'moment'
import { Typography, Table, Select, DatePicker } from 'antd';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table;

const { Option } = Select;
const { RangePicker } = DatePicker;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: '#203a45',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});

@inject('userStore')
@observer
class Active extends React.Component {

    state = {
        isLoading: true,
        data: [],
        pagination: { pageSize: 10 },
        pageUrl: 0,
        states: [],
        lgas: [],
        seasons: [],
        associations: [],
        activeFilters: { season: "", association: "", date: [] },
        stateFilters: { state: "", lga: "" },
        tenant_id: this.props.location.search.split("=")[1],
        query: ""
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        const tenant_id = this.props.location.search.split("=")[1]
        axios.all([
            axios({
                url: `${config.baseUrl}/subcluster/active?t=${tenant_id}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/project_names?t=${tenant_id}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/states`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmers/client_name?t=${tenant_id}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((data, seasons, states, associations) => {
            console.log(data)
            const pagination = { ...this.state.pagination };
            pagination.total = data.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(data.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: data.data.aaData,
                states: states.data.entity.states,
                seasons: seasons && seasons.data.entity,
                associations: associations && associations.data.entity,
                pagination,
            })

        }));
    }

    setLGAs = async (name) => {
        if (!name) {
            this.setState({ lgas: [] })
            return
        }
        const state = this.state.states.find(item => item.name === name)
        this.setState({ lgas: state.lgas })
    }


    checkFilter = (pagination) => {
        //set page if pagination
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({pagination: {...this.state.pagination, pageSize}})
        }
        else {
            page = 0;
            pageSize = 10
        }
        let query = this.state.query.split(" State")[0]
        let season = this.state.activeFilters.season
        let association = this.state.activeFilters.association
        let date = this.state.activeFilters.date
        let url =
            `${config.baseUrl}/subcluster/active?t=${this.state.tenant_id}${page === 0 ? '' : `&page=${page}`}&per_page=${pageSize}${query && `&query=${query}`}${association && `&association=${association}`}${season && `&season=${season}`}${date.length ? `&date_from=${date[0]}&date_to=${date[1]}` : ""}`;

        this.filter(url)
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* <Drawer /> */}
                <AppBar
                    position="fixed"
                    className={classNames(classes.appBar, {
                        [classes.appBarShift]: false,
                    })}
                >
                    <Toolbar>

                    </Toolbar>
                </AppBar>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>Active Mapping</Title>
                        <br />
                        <p style={{ color: '#2A3F54' }}>Filters</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Filter Season"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, season: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}>
                                <Option value="">All</Option>
                                {this.state.seasons.filter(item => item.season).map((item) => {
                                    return (
                                        <Option key={item.season} value={item.season}>{item.season}</Option>
                                    )
                                })}
                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Filter State"
                                onChange={(value) => {
                                    this.setState({
                                        query: value,
                                        // stateFilters: { ...this.state.stateFilters, state: value, lga: "" }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                    this.setLGAs(value)
                                }}
                            >
                                <Option value="">All</Option>

                                {this.state.states.map(item => (
                                    <Option key={item.state_id} value={item.name}>{item.name}</Option>
                                ))}

                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Filter LGA"
                                onChange={(value) => {
                                    this.setState({
                                        query: value,
                                        // activeFilters: { ...this.state.activeFilters, query: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                {this.state.lgas.map(item => (
                                    <Option key={item.local_id} value={item.local_name}>{item.local_name}</Option>
                                ))}
                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Filter Associations"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, association: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                {this.state.associations.map((item, i) => (

                                    <Option key={i} value={item.client_name}>{item.client_name}</Option>
                                ))}
                            </Select>
                            <RangePicker
                                ranges={{
                                    Today: [moment(), moment()],
                                    'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                    'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                    'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                }}
                                onChange={(_, date) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, date }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            />
                        </div>
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            pagination={this.state.pagination}
                            onChange={this.checkFilter}
                            rowKey={record => record._id}
                            dataSource={this.state.data}>
                            <Column
                                title="Agent"
                                dataIndex="agent"
                                key="agent"
                                render={(text, record) => (
                                    <span>
                                        {text.first_name} {text.last_name}
                                    </span>
                                )}
                            />
                            <Column
                                title="Phone Numner"
                                dataIndex="agent.mobile"
                                key="agent.mobile"
                            />
                            <Column
                                title="Date Last Synced"
                                dataIndex="agent.last_login"
                                key="agent.last_login"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LLLL')}
                                    </span>
                                )}
                            />
                            <Column
                                title="Sub Cluster"
                                dataIndex="name"
                                key="name"
                            />
                            <Column
                                title="Assigned Farms"
                                dataIndex="assigned"
                                key="assigned"
                            />
                            <Column
                                title="Submitted Farmers"
                                dataIndex="submitted"
                                key="submitted"
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Active);
