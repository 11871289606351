import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Breadcrumb, InputNumber, Spin, Select
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class AddSourcingAgent extends React.Component {

    static contextType = AuthContext
    state = {
        farmers: 0,
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        loading: false,
        account_no: "",
        account_name: "",
        isFetching: true,
        banks: [],
        filterBanks: [],
        resolving: false,
        resolved: false,
        bank_code: ""
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/banks/all`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            // axios({
            //     url: `${config.baseUrl}/farmers`,
            //     method: 'GET',
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${this.props.userStore.token}`
            //     },
            // }).catch(err => {
            //     this.setState({ isFetching: false })
            //     this.props.userStore.openNotificationWithIcon('error', err.message)
            // })
        ]).then(axios.spread((res1) => {
            this.setState({
                isFetching: false,
                banks: res1.data.entity.data
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const bank = this.state.banks.filter((item) => item.code === this.state.bank_code)[0].name

        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            email: this.state.email,
            bank,
            bank_code: this.state.bank_code,
            account_no: this.state.account_no,
            account_name: this.state.account_name,
            farmers: this.state.farmers
        }
        console.log(data)

        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/sourcing`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added Sourcing Agent")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    resolveBank = async (account_no) => {
        console.log(this.state.bank_code)
        this.setState({ resolving: true, account_name: "" })
        await axios({
            url: `${config.baseUrl}/banks/resolve/${account_no}/${this.state.bank_code}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                console.log(res.data)
                this.setState({ resolving: false, resolved: true })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                resolving: false,
                resolved: true,
                account_name: res.data.entity.data.account_name,
                // account_no: res.data.entity.data.account_no
            })

        }).catch((err) => {
            this.setState({ resolving: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    searchBanks = (val) => {
        // this.setState((prevState) => ({ 
        //     banks: prevState.banks.filter(bank => )
        //  }))
        console.log(val)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/sourcing-agents">Sourcing Agents</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Sourcing Agent
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Add New Sourcing Agent</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="First Name">
                                    <Input onChange={(e) => this.setState({ first_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Last Name">
                                    <Input onChange={(e) => this.setState({ last_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Email">
                                    <Input onChange={(e) => this.setState({ email: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Phone Number">
                                    <Input onChange={(e) => this.setState({ mobile: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Bank">
                                    <Select
                                        // onSearch={(val) => this.searchBanks(val)}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        defaultValue="Select Bank" 
                                        onChange={(value) => {
                                            this.setState({ bank_code: value })
                                            console.log(value)
                                        }}>
                                        {this.state.banks.map((item) => {

                                            return (
                                                <Option key={item.id} value={item.code}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Account Number">
                                    <Input maxLength={10} onChange={(e) => {
                                        if (e.target.value.length === 10) {
                                            this.setState({ account_no: e.target.value })
                                            this.resolveBank(e.target.value)
                                        }
                                    }} />
                                    {this.state.resolving && <Spin />}
                                    {this.state.account_name && <Title level={4}>{this.state.account_name}</Title>}
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Number of Farmers">
                                    <InputNumber min={1} onChange={(val) => this.setState({ farmers: val })} />
                                </Form.Item>
                                <Form.Item>
                                    <Button disabled={!this.state.resolved} className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }

                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddSourcingAgent);
