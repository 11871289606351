import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Typography, Card, Col, Row, DatePicker, Breadcrumb, Spin, Icon, Button } from 'antd';
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import moment from 'moment'

import Drawer from './Drawer'
import config from '../../config'
import { formatNumber } from '../../config/utils'
import { Tooltip } from '@material-ui/core';

const { Column } = Table

const Title = Typography.Title;
const { RangePicker } = DatePicker;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    cardContent: {
        backgroundColor: '#116E6E'
    },
    input: {
        color: '#116E6E'
    },
    card: {
        maxWidth: 400,
        marginBottom: theme.spacing.unit * 2.2,
    },
    cardTitle: {
        color: '#fff'
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    select: {
        '&:before': {
            borderColor: '#116E6E',
        },
        '&:after': {
            borderColor: '#116E6E',
        }
    },
    menuItem: {
        color: '#464D69'
    },

});


@inject('userStore')
@observer
class MetaDash extends React.Component {
    state = {
        data: null,
        loading: true,
        pagination: { pageSize: 10 },
        date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
    };

    handleChange = val => {
        this.setState({ crop: val }, () => {
            this.filterByCrop()
        })
    };

    componentDidMount() {
        this.fetchData(this.state.date)
        setInterval(() => {
            this.fetchData(this.state.date);
        }, 300000);
    }


    componentWillUnmount() {
        clearInterval()
    }

    fetchData = async (defaultDate = []) => {
        this.setState({ loading: true })

        const dateFrom = defaultDate && defaultDate.length ? `?date_from=${defaultDate[0]}` : ''
        const dateTo = defaultDate && defaultDate.length ? `&date_to=${defaultDate[1]}` : ''

        const filter = dateFrom + dateTo;
        await axios({
            url: `${config.baseUrl}/dashboard/dv1${filter}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            const pagination = { ...this.state.pagination };
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            const data = res.data.entity
            this.setState({ data, loading: false, pagination })

        }).catch((err) => {
            console.log(err.response)
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    filterData = async (date) => {
        let url;
        if (!date[0].length && !date[1].length) {
            url = `${config.baseUrl}/dashboard/dv1`
        }
        else {
            url = `${config.baseUrl}/dashboard/dv1?date_from=${date[0]}&date_to=${date[1]}`
        }
        this.setState({ loading: true })
        await axios({
            url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            console.log(res)
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            const pagination = { ...this.state.pagination };
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            const data = res.data.entity
            this.setState({ data, loading: false, pagination })

        }).catch((err) => {
            console.log(err)
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    calcKyc = (key) => {
        const tableData = this.state.data.table_data
        const sum = tableData.reduce((a, b) => a + parseInt(b[key]), 0)
        return formatNumber(sum);
    }

    render() {
        const { classes } = this.props;
        const data = this.state.data
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    {/* <div className={classes.toolbar} /> */}
                    <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                        <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>GPS Capture Overview</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <div>
                            <Title level={4}>GPS Capture</Title>
                        </div>
                        <div style={{ margin: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            {this.state.loading ? <Spin /> : null}
                        </div>
                        <div className="flex-container">
                            <div style={{ marginBottom: 20, }}>
                                <RangePicker
                                    defaultValue={[moment(), moment()]}
                                    ranges={{
                                        Today: [moment(), moment()],
                                        'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                        'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                        'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                        'All Time': [],
                                    }}
                                    onChange={(_, date) => {
                                        this.setState({
                                            date
                                        }, () => {
                                            this.filterData(date)
                                        });
                                    }} />
                            </div>
                            <div>
                                <Tooltip title="Refresh Data">
                                    <Button type="default" onClick={() => this.fetchData(this.state.date)}>
                                        <Icon type="undo" size={36} style={{ color: '#2A3F54' }} />
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card style={{ backgroundColor: '#fafafa' }}>
                                    <div>
                                        <Title style={{ color: '#3f8600', textAlign: 'center' }}>{this.state.data ? this.calcKyc('total_kyc_captured') : 0}</Title>

                                    </div>
                                    <div>
                                        <h6 style={{ textAlign: 'center' }}>Total KYC Captured</h6>
                                    </div>
                                    {/* <Statistic
                                        valueStyle={{ color: '#3f8600' }}
                                        title="Total KYC Captured"
                                        value={this.calculateKyc(data ? data.table_data : 0)}
                                    /> */}
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card style={{ backgroundColor: '#fafafa' }}>
                                    <div>
                                        <Title style={{ color: '#1890ff', textAlign: 'center' }}>{this.state.data ? this.calcKyc('farms_mapped_no') : 0}</Title>
                                    </div>
                                    <div>
                                        <h6 style={{ textAlign: 'center' }}>Total Mapped Farms</h6>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <div style={{ margin: '30px 0' }}>
                            <Table
                                loading={this.state.loading}
                                rowKey={record => record.client_name + record.home_state}
                                dataSource={
                                    data
                                        ?
                                        data.table_data.filter(item => parseInt(item.total_kyc_captured) > 0 || parseInt(item.farms_mapped_no) > 0)
                                        :
                                        []
                                }
                                pagination={this.state.pagination}
                            >

                                <Column
                                    title="State"
                                    dataIndex="home_state"
                                    key="home_state"
                                />
                                <Column
                                    title="Association"
                                    dataIndex="client_name"
                                    key="client_name"
                                    sorter={(a, b) => a.client_name.localeCompare(b.client_name)}
                                />
                                <Column
                                    title="Total KYC Captured"
                                    dataIndex="total_kyc_captured"
                                    key="total_kyc_captured"
                                    sorter={(a, b) => a.total_kyc_captured - b.total_kyc_captured}
                                    render={(text, record) => (
                                        <span>
                                            {formatNumber(parseInt(text))}
                                        </span>
                                    )}

                                />
                                <Column
                                    title="Mapped Farms"
                                    dataIndex="farms_mapped_no"
                                    key="farms_mapped_no"
                                    sorter={(a, b) => a.farms_mapped_no - b.farms_mapped_no}
                                    render={(text, record) => (
                                        <span>
                                            {formatNumber(parseInt(text))}
                                        </span>
                                    )}
                                />
                            </Table>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(MetaDash);
