import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Provider from '../src/context/AuthContext'
import { Provider as MobXProvider } from 'mobx-react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import userStore from './store/User'
import * as serviceWorker from './serviceWorker';


const Root = (
    <MobXProvider userStore={userStore}>
        <Provider>
            <Router>
                <App />
            </Router>
        </Provider>
    </MobXProvider>
)
ReactDOM.render(Root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
