import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Table, Tooltip, Icon, Breadcrumb } from 'antd';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: '#fff'
  }
});

@inject('userStore')
@observer
class Groups extends React.Component {

  state = {
    isLoading: true,
    data: []
  }

  componentDidMount() {
    this.getData()

  }

  getData = async () => {
    await axios({
      url: `${config.baseUrl}/role`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      this.setState({ data: res.data.entity })

    }).catch((err) => {
      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                Role Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: '#2A3F54' }} level={3}>User Role Management</Title>
          </div>
          <div>
            <Link to="/groups/add">
              <Button type="primary" className={classes.button}>
                Create New User Role
              </Button>
            </Link>
          </div>
          <div className='table-container' style={{ padding: 24, background: '#fff' }}>
            <Title style={{ color: '#2A3F54' }} level={4}>All Roles</Title>
            <Table
              bordered
              loading={this.state.isLoading}
              rowKey={record => record._id}
              dataSource={this.state.data}>
              <Column
                title="ID"
                dataIndex="_id"
                key="_id"
              />
              <Column
                title="Role"
                dataIndex="role"
                key="role"
              />
              <Column
                title="Description"
                dataIndex="desc"
                key="desc"
              />
              <Column
                title="Action"
                key="action"
                render={(text) => (
                  <span>
                    <Tooltip title="Edit Group">
                      <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/groups/edit/${text._id}`, text)}>
                        <Icon type="edit" style={{ color: '#2A3F54' }} />
                      </Button>
                    </Tooltip>
                  </span>
                )}

              />
            </Table>
          </div>
        </main>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(Groups);
