import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import { pickBy, identity } from 'lodash';
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import { Link } from 'react-router-dom'
import {
    Form, Input, Icon, Typography, Button, Modal, Select, Spin, Row, Col, DatePicker, Breadcrumb, Avatar, Table, Tag
} from 'antd';
import moment from 'moment';
import config from '../../config'
import Map from '../farm/FarmMap'

const Option = Select.Option;
const TextArea = Input.TextArea;
const { Title } = Typography;
const { Column } = Table;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class EditFarmer extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        states: [],
        locals: [],
        banks: [],
        _id: this.props.location.state,
        selectedState: null,
        selectedLGA: null,
        farmer: null,
        mapVisible: false,
        selectedPoints: [],
        farms: []
    }

    componentDidMount() {
        this.fetchContent()
    }

    getId = () => {
        let FMR_ID = this.props.location.pathname.split("/")[2]
        let _idStr = FMR_ID.split("-")[1]
        let id = parseInt(_idStr, 10)

        return id
    }


    fetchContent() {
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''
        axios.all([
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/banks`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmer/${this.getId()}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            // axios({
            //     url: `${config.baseUrl}/farmer/input/${this.getId()}`,
            //     method: 'GET',
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${this.props.userStore.token}`
            //     },
            // }).catch(err => {
            //     this.setState({ isFetching: false })
            //     this.props.userStore.openNotificationWithIcon('error', err.message)
            // }),
        ]).then(axios.spread((res1, res2, res3) => {

            this.setState({
                isFetching: false,
                states: res1.data.entity.states,
                banks: res2.data.entity,
                ...res3.data.entity,
                farms: !res3.data.entity.farm ? [] : res3.data.entity.farm instanceof Array ? res3.data.entity.farm : Array.of(res3.data.entity.farm),
                selectedState: res3.data.entity.home_state ? res3.data.entity.home_state.state_id : "",
                selectedLGA: res3.data.entity.home_lga ? res3.data.entity.home_lga.local_id : "",
                mapped_by: res3.data.entity.mapped_by ? res3.data.entity.mapped_by.email : "",
                land_agreement_image: res3.data.entity.farm_cluster ? res3.data.entity.farm_cluster.land_agreement_image : "",
                contact_person: res3.data.entity.farm_cluster ? res3.data.entity.farm_cluster.contact_person : "",
                contact_person_email: res3.data.entity.farm_cluster ? res3.data.entity.farm_cluster.contact_person_email : "",
                cluster_size: res3.data.entity.farm_cluster ? res3.data.entity.farm_cluster.cluster_size : "",
            })

        }));
    }

    handleMapOkay = () => {
        this.setState({
            mapVisible: false,
        });
    };

    handleMapCancel = () => {
        this.setState({
            mapVisible: false,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            middle_name: this.state.middle_name,
            mobile: this.state.mobile,
            bvn: this.state.bvn,
            gender: this.state.gender,
            loan: this.state.loan,
            status: this.state.status,
            lead_farmer: this.state.lead_farmer,
            bank: this.state.bank,
            account_no: this.state.account_no,
            home_address: this.state.home_address,
            home_state: this.state.selectedState,
            home_lga: this.state.selectedLGA,
            dob: this.state.dob,
            coordinates: this.state.coordinates,
            land_agreement_image: this.state.land_agreement_image,
            image: this.state.image,
            mapped_by: this.state.mapped_by,
            contact_person_email: this.state.contact_person_email,
            contact_person: this.state.contact_person,
            cluster_size: this.state.cluster_size,
            farm_size: this.state.farm_size,
            cluster_point: this.state.cluster_point,
            client_name: this.state.client_name,


        }
        if (!this.state.first_name || !this.state.last_name) {
            return this.props.userStore.openNotificationWithIcon('error', 'Firstname and Lastname required')
        }
        if (!this.state.mobile) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a mobile number')
        }


        const cleanedObject = pickBy(data, identity)

        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/farmer/${this.getId()}`,
            method: 'PUT',
            data: cleanedObject,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully updated Farmer")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    setLGAs = () => {
        const state = this.state.states.find(item => item.state_id === this.state.selectedState)
        return state.lgas
    }

    showPoints = (points) => {
        this.setState({ selectedPoints: points, mapVisible: true })
    }


    render() {
        const { classes } = this.props;
        const { role } = this.props.userStore.role
        const disableButton = role === "Project Manager" || role === "Guest"



        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farmers">Farmer Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Edit Farmer
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <Row gutter={24}>
                            <Col span={10}>
                                {role !== "Guest" &&
                                    <>
                                        <Avatar shape="square" size={256} style={{ width: '90%' }} icon="user" src={this.state.image} />
                                        <div style={{ marginTop: 10 }}>
                                            {this.state.bvn_validate ?
                                                <Tag color="green">BVN Verified</Tag>
                                                :
                                                <Tag color="red">BVN not Verified</Tag>
                                            }
                                        </div>
                                        {role !== "Aggregator" &&
                                            <div style={{ marginTop: 10, display: 'flex' }}>
                                                <h5 style={{ marginRight: 30 }}>Input Confirmed</h5>
                                                {this.state.input_confirmation === 0 ?
                                                    <Tag color="red">No</Tag>
                                                    :
                                                    <Tag color="green">Yes</Tag>
                                                }
                                            </div>

                                        }

                                    </>
                                }
                                <div style={{ marginTop: 10 }}>
                                    <h5>{role === "Guest" ? `Stakeholder:` : `Client:`} {this.state.client_name}</h5>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h5 style={{ marginTop: 20, marginRight: 10 }} level={4}>Farms</h5>
                                </div>
                                <Table
                                    bordered
                                    rowKey={record => record.farm_id}
                                    dataSource={this.state.farms}
                                >
                                    <Column
                                        title="Farm ID"
                                        dataIndex="farm_id"
                                        key="farm_id"
                                    />
                                    <Column
                                        title="Crop"
                                        dataIndex="crop.crop_name"
                                        key="crop.crop_name"
                                    />
                                    {role === "Guest" ?
                                        <Column
                                            title="View Polygon"
                                            key="actions"
                                            render={(text, record) => (
                                                <span>
                                                    {record.points ?
                                                        <Button style={{ marginRight: 2 }} type="default" onClick={() => this.showPoints(record.points)}>
                                                            <Icon type="eye" style={{ color: '#2A3F54' }} />
                                                        </Button>
                                                        :
                                                        <p>No Polygon</p>
                                                    }
                                                </span>
                                            )}
                                        /> :
                                        <Column
                                            title="View"
                                            key="actions"
                                            render={(text, record) => (
                                                <span>
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/${text._id}`, text._id)}>
                                                        <Icon type="eye" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </span>
                                            )}
                                        />
                                    }

                                </Table>
                            </Col>
                            <Col span={14}>
                                <div>
                                    <Title style={{ color: '#2A3F54' }} level={3}> Edit Farmer</Title>
                                    <Form onSubmit={this.handleSubmit} className={classes.form}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="First Name">
                                            <Input disabled={disableButton} value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Middle Name">
                                            <Input disabled={disableButton} value={this.state.middle_name} onChange={(e) => this.setState({ middle_name: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Last Name">
                                            <Input disabled={disableButton} value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Phone Number">
                                            <Input disabled={disableButton} value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Date of Birth">
                                                    <DatePicker
                                                        disabled={disableButton}
                                                        value={this.state.dob ? moment(this.state.dob) : ''}
                                                        onChange={(_, dob) => this.setState({ dob })} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Gender">
                                                    <Select disabled={disableButton} defaultValue="Select Gender" value={this.state.gender} onChange={(value) => this.setState({ gender: value })}>
                                                        <Option value={"MALE"}>Male</Option>
                                                        <Option value={"FEMALE"}>Female</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {role === "Guest" &&
                                            <>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="CBN ID">
                                                    <Input disabled type="text" value={this.state.cbn_applicant_id} onChange={(e) => this.setState({ cbn_id: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Project Name">
                                                    <Input disabled type="text" value={this.state.project_name} onChange={(e) => this.setState({ project_name: e.target.value })} />
                                                </Form.Item>
                                            </>
                                        }
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Season">
                                            <Input disabled type="text" value={this.state.project_name} onChange={(e) => this.setState({ project_name: e.target.value })} />
                                        </Form.Item>
                                        {this.props.userStore.role.role !== "Aggregator" &&
                                            <>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Programme">
                                                    <Input disabled type="text" value={this.state.programme} onChange={(e) => this.setState({ programme: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Association">
                                                    <Input type="text" value={this.state.client_name} onChange={(e) => this.setState({ client_name: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="BVN">
                                                    <Input type="text" disabled value={this.state.bvn} onChange={(e) => this.setState({ bvn: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Image">
                                                    <Input type="text" value={this.state.image} onChange={(e) => this.setState({ image: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Land Agreemant Image">
                                                    <Input type="text" value={this.state.land_agreement_image} onChange={(e) => this.setState({ land_agreement_image: e.target.value })} />
                                                </Form.Item>
                                            </>
                                        }

                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Coordinates">
                                            <TextArea disabled={disableButton} value={this.state.coordinates} onChange={(e) => this.setState({ coordinates: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Mapped By">
                                            <Input type="text" value={this.state.mapped_by} onChange={(e) => this.setState({ mapped_by: e.target.value })} />
                                        </Form.Item>
                                        <br />
                                        {this.props.userStore.role.role !== "Aggregator" &&
                                            <>
                                                <Title style={{ color: '#2A3F54' }} level={4}>Cluster Information</Title>
                                                <Form.Item
                                                    colon={false}
                                                    style={{ margin: 0 }}
                                                    label="Cluster Point">
                                                    <Input value={this.state.cluster_point} onChange={(e) => this.setState({ cluster_point: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    colon={false}
                                                    style={{ margin: 0 }}
                                                    label="Contact Person">
                                                    <Input value={this.state.contact_person} onChange={(e) => this.setState({ contact_person: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    colon={false}
                                                    style={{ margin: 0 }}
                                                    label="Contact Person Email">
                                                    <Input value={this.state.contact_person_email} onChange={(e) => this.setState({ contact_person_email: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    colon={false}
                                                    style={{ margin: 0 }}
                                                    label="Cluster Size (Ha)">
                                                    <Input value={this.state.cluster_size} type="number" onChange={(e) => this.setState({ cluster_size: e.target.value })} />
                                                </Form.Item>
                                                <Form.Item
                                                    colon={false}
                                                    style={{ margin: 0 }}
                                                    label="Farm Size (Ha)">
                                                    <Input value={this.state.farm_size} type="number" onChange={(e) => this.setState({ farm_size: e.target.value })} />
                                                </Form.Item>
                                            </>

                                        }

                                        <br />

                                        <br />
                                        <Title style={{ color: '#2A3F54' }} level={4}>Other Information</Title>
                                        {role !== "Guest" &&
                                            <Form.Item
                                                style={{ margin: 0 }}
                                                label="Loan Amount">
                                                <Input onChange={(e) => this.setState({ loan: e.target.value })} />
                                            </Form.Item>
                                        }
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Home Address">
                                            <TextArea disabled={disableButton} value={this.state.home_address} onChange={(e) => this.setState({ home_address: e.target.value })} />
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Home State">
                                                    <Select disabled={disableButton} defaultValue="Select State" value={this.state.selectedState} onChange={(value) => {
                                                        this.setState({ selectedState: value, selectedLGA: "Select LGA" })
                                                    }}>
                                                        {this.state.states.map((item) => {
                                                            return (
                                                                <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>

                                            </Col>
                                            {/* <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="LGA">
                                                    <Select disabled={disableButton} defaultValue="Select LGA" value={this.state.selectedLGA} onChange={(value) => this.setState({ selectedLGA: value })}>
                                                        {this.setLGAs().map((item) => {
                                                            return (
                                                                <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                        <Form.Item style={{ margin: 0 }}>
                                            <Button disabled={disableButton} className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                                {this.state.loading ? "" : "Submit"}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Modal
                        footer={null}
                        visible={this.state.mapVisible}
                        onOk={this.handleMapOkay}
                        width={600}
                        okText={"View"}
                        cancelText={"Close"}
                        onCancel={this.handleMapCancel}
                    >
                        <Map
                            points={this.state.selectedPoints}
                        />
                    </Modal>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(EditFarmer);
