import { observable, action } from "mobx";
import { notification } from 'antd';

class User {
    @observable firstname = ""
    @observable lastname = ""
    @observable email = ""
    @observable token = ""
    @observable mobile = ""
    @observable role = {}
    @observable tenant = {}
    @observable activeMarker = 0
    @observable random = "Random"
    // @observable authenticated = localStorage.getItem('user-auth')

    @action
    setUser(data) {
        this.firstname = data.user.first_name
        this.lastname = data.user.last_name
        this.email = data.user.email
        this.mobile = data.user.mobile
        this.role = data.user.role
        this.tenant = data.user.tenant
        this.token = data.token
        localStorage.setItem('user-auth', JSON.stringify(data))
    }

    @action
    setTenant(tenant) {
        this.tenant = tenant
    }

    @action
    updateUser(data) {
        this.firstname = data.first_name
        this.lastname = data.last_name
        this.mobile = data.mobile
    }

    openNotificationWithIcon(type, message) {
        notification[type]({
            style: {
                marginTop: 50
            },
            message,
        });
    };

    @action
    getUserFromStorage() {
        const data = localStorage.getItem('user-auth')
        if (data !== null) {
            const parsed = JSON.parse(data)
            this.firstname = parsed.user.first_name
            this.lastname = parsed.user.last_name
            this.email = parsed.user.email
            this.mobile = parsed.user.mobile
            this.token = parsed.token
            this.role = parsed.user.role
            this.tenant = parsed.user.tenant
        }
        else {
            return
        }

    }

    @action
    logout = () => {
        this.firstname = ""
        this.lastname = ""
        this.email = ""
        this.mobile = ""
        this.token = ""
        this.role = {}
        this.tenant = {}
        // this.openNotificationWithIcon('success', "You have been logged out")

    }

    @action
    setMarkerInfo(marker) {
        this.activeMarker = marker._id
    }
    @action
    showRandomMessage(message) {
        this.randromMessage = message
        console.log("Changed")
    }
}

const userStore = new User()
export default userStore