import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Icon, Popconfirm, Breadcrumb, Input } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'

const { Title } = Typography;
const { Column } = Table

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: '#fff'
  },
  searchBar: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,

  }
});


@inject('userStore')
@observer
class SourcingAgents extends React.Component {

  state = {
    data: [],
    pagination: { pageSize: 10 },
    isLoading: false,
    pageUrl: 0
  }

  componentDidMount() {
    this.fetchAgents()
  }

  fetchAgents = async () => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/sourcing/all`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(async res => {
      if (res.data.status.code !== 100) {
        this.setState({ isLoading: false })
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.entity.total_records
      pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
      pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
      await this.setState({
        isLoading: false,
        data: res.data.entity.sourcing_agents,
        pagination
      })
    }).catch((err) => {
      this.setState({ isLoading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  handleTableChange = async (pagination) => {  // pagination here will be 2 if 2 is clicked
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    await this.setState({
      pagination: pager,
      pageUrl: pagination.current - 1
    });
    this.fetchMore()
  }

  fetchMore = async () => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/sourcing/all?current_page=${this.state.pageUrl}`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      this.setState({
        isLoading: false,
        data: res.data.entity.sourcing_agents,
      })
    }).catch((err) => {
      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  handleSearch = async (query) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/sourcing/all?query=${query}`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(async res => {
      if (res.data.status.code !== 100) {
        this.setState({ isLoading: false })
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.entity.total_records
      pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
      await this.setState({
        isLoading: false,
        data: res.data.entity.sourcing_agents,
        pagination
      })
    }).catch((err) => {

      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  confirmDelete = async (agent) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/sourcing/${agent._id}`,
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

    }).catch(err => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                Sourcing Agents
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: '#2A3F54' }} level={3}>Sourcing Agents</Title>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/sourcing-agents/add">
              <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                Add New Souring Agent
              </Button>
            </Link>
            <div>
              <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} />
            </div>
          </div>
          <div className='table-container' style={{ padding: 24, background: '#fff' }}>
            <Title style={{ color: '#2A3F54' }} level={4}>All Sourcing Agents</Title>
            <Table
              pagination={this.state.pagination}
              bordered
              onChange={this.handleTableChange}
              loading={this.state.isLoading}
              rowKey={record => record._id}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: () => { console.log(record) },       // click row
              //   };
              // }}
              dataSource={this.state.data}>
              <Column
                title="First Name"
                dataIndex="first_name"
                key="first_name"
              />
              <Column
                title="Last Name"
                dataIndex="last_name"
                key="last_name"
              />
              <Column
                title="Mobile"
                dataIndex="mobile"
                key="mobile"
              />
              <Column
                title="Account Number"
                dataIndex="account_no"
                key="account_no"
              />
              <Column
                title="Bank"
                dataIndex="bank"
                key="bank"
              />
              <Column
                title="No of Farmers"
                dataIndex="farmers"
                key="farmers"
              />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <span>
                    {/* <Tooltip title="Edit Tenant">
                      <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/tenants/${text._id}`, text)}>
                        <Icon type="edit" style={{ color: '#2A3F54' }} />
                      </Button>
                    </Tooltip> */}
                    <Popconfirm title="Are you sure delete this agent?" onConfirm={() => this.confirmDelete(text)} okText="Yes" cancelText="No">
                      <Button style={{ marginLeft: 2 }} type="default">
                        <Icon type="delete" style={{ color: '#ea1b2a' }} />
                      </Button>
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
          </div>
        </main>
      </div >
    );
  }
}



export default withStyles(styles, { withTheme: true })(SourcingAgents);
