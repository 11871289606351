import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Breadcrumb, Row, Col, Spin, Select
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    uploadDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: 250,
        height: 250,
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px dashed #000',
        cursor: 'pointer'

    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class EditTenant extends React.Component {

    static contextType = AuthContext
    state = {
        ...this.props.location.state,
        loading: false,
        selectedImage: "",
        countries: [],
        currencies: []
    }


    componentDidMount() {
        this.fetchContent()
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            description: this.state.description,
            sms_sender_id: this.state.sms_sender_id,
            custom_domain: this.state.custom_domain,
            currency: this.state.currency,
            country: this.state.country
        }
        if (!this.state.name || !this.state.description) {
            return this.props.userStore.openNotificationWithIcon('error', 'Name and Description required')
        }
        if (!this.state.country || !this.state.currency) {
            return this.props.userStore.openNotificationWithIcon('error', 'Country and Currency required')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/tenant/${this.state._id}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully updated Tenant")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleImagePick = (event) => {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onloadend = () => {
            this.setState({ selectedImage: reader.result, uploading: true }, this.handleUpload)

        };
    }

    handleUpload = async () => {
        await axios({
            url: `${config.baseUrl}/tenant/${this.state._id}/logo`,
            method: 'PUT',
            data: { image: this.state.selectedImage },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ uploading: false, logo: res.data.entity.logo })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully Uploaded Image")

            this.props.userStore.setTenant(res.data.entity)

        }).catch((err) => {
            this.setState({ uploading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    renderUploadContent = () => {
        if (!this.state.logo) {
            return (
                <div>
                    <div className="ant-upload-text">Click to Upload</div>
                </div>
            )
        }
        return <img src={this.state.logo} alt="logo" style={{ width: '100%' }} />
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/currencies`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/countries`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2) => {
            this.setState({
                isFetching: false,
                countries: res2.data.entity,
                currencies: res1.data.entity
            })
        }));
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/tenants">Tenant Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Edit Tenant
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <div className={classes.uploadDiv} onClick={() => this.fileInput.click()}>
                                {this.renderUploadContent()}
                            </div>
                            <h6>Click the square to upload logo</h6>
                            {this.state.uploading ? <Spin /> : null}
                            <input
                                style={{ display: 'none' }}
                                ref={fileInput => this.fileInput = fileInput}
                                type="file"
                                accept="image/*"
                                onChange={this.handleImagePick} />
                        </Col>
                        <Col span={16}>
                            <div className="add-form">
                                <Title style={{ color: '#2A3F54' }} level={3}>Update Tenant</Title>
                                <Form onSubmit={this.handleSubmit} className={classes.form}>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        label="Name">
                                        <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        label="Description">
                                        <TextArea value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        colon={false}
                                        style={{ margin: 0 }}
                                        label={<span><span style={{ color: 'red' }}>* </span>Country</span>}>
                                        <Select defaultValue="Select Country" value={this.state.country} onChange={(value) => this.setState({ country: value })}>
                                            {this.state.countries.map((item) => {
                                                return (
                                                    <Option key={item._id} value={item.code}>{item.country}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        colon={false}
                                        style={{ margin: 0 }}
                                        label={<span><span style={{ color: 'red' }}>* </span>Currency</span>}>
                                        <Select defaultValue="Select Currency" value={this.state.currency} onChange={(value) => this.setState({ currency: value })}>
                                            {this.state.currencies.map((item) => {
                                                return (
                                                    <Option key={item._id} value={item.code}>{item.currency}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        label="Custom Domain">
                                        <Input value={this.state.custom_domain} onChange={(e) => this.setState({ custom_domain: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ margin: 0 }}
                                        label="SMS Sender ID">
                                        <Input value={this.state.sms_sender_id} onChange={(e) => this.setState({ sms_sender_id: e.target.value })} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                            {this.state.loading ? "" : "Submit"}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>

                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(EditTenant);
