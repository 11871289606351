import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Select,
  Table,
  Typography,
  Card,
  Col,
  Row,
  DatePicker,
  Button,
  Breadcrumb,
  Alert,
} from "antd";
import { observer, inject } from "mobx-react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Drawer from "./Drawer";
import Maps from "./Maps";
import config from "../../config";

const { RangePicker } = DatePicker;
const { Column } = Table;

// const SubMenu = Menu.SubMenu;
const Option = Select.Option;
const Title = Typography.Title;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  cardContent: {
    backgroundColor: "#116E6E",
  },
  input: {
    color: "#116E6E",
  },
  card: {
    maxWidth: 400,
    marginBottom: theme.spacing.unit * 2.2,
  },
  cardTitle: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  select: {
    "&:before": {
      borderColor: "#116E6E",
    },
    "&:after": {
      borderColor: "#116E6E",
    },
  },
  menuItem: {
    color: "#464D69",
  },
});

@inject("userStore")
@observer
class MiniDrawer extends React.Component {
  state = {
    zoom: 5,
    crop: 0,
    data: {},
    isFetching: true,
    crops: [],
    activities: [],
    seasons: [],
    notify: false,
  };

  handleChange = (val) => {
    this.setState({ crop: val }, () => {
      this.filterByCrop();
    });
  };

  componentDidMount() {
    if (this.props.userStore.role.role === "Aggregator") {
      return this.fetchContent();
    } else {
      return this.fetchContentAdmin();
    }
  }

  filterByCrop = async () => {
    this.setState({ isFetching: true });
    await axios({
      url: `${config.baseUrl}/dashboard?crop=${this.state.crop}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ isFetching: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.setState({ data: res.data.entity, zoom: 5 });
      })
      .catch((err) => {
        this.setState({ isFetching: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  async fetchContent() {
    await axios
      .all([
        axios({
          url: `${config.baseUrl}/config/crop_profiles`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/dashboard`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/config/seasons`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/dashboard/map`,
          method: "POST",
          data: {
            start: moment().add(-7, "days"),
            end: moment(),
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          console.log(res4.data.entity);
          this.setState({
            isFetching: false,
            data: res2.data.entity,
            crops: res1.data.entity.profiles,
            seasons: res3.data.entity.seasons,
            activities: res4.data.entity,
          });
          this.checkSeasonStatus(res3.data.entity.seasons);
        })
      )
      .catch((err) => {
        this.setState({ isFetching: false });
      });
  }

  async fetchContentAdmin() {
    await axios
      .all([
        axios({
          url: `${config.baseUrl}/config/crop_profiles`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/admin/dashboard`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/admin/tenants`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          this.setState({
            isFetching: false,
            data: res2.data.entity,
            crops: res1.data.entity.profiles,
            tenants: res3.data.entity,
          });
        })
      )
      .catch((err) => {
        this.setState({ isFetching: false });
      });
  }

  checkSeasonStatus(data) {
    const filtered = data.filter((item) => item.status === 0);
    if (filtered.length) {
      this.setState({ notify: true });
    }
  }

  onChange = (e) => {
    const data = {
      start: e[0],
      end: e[1],
    };
    axios({
      url: `${config.baseUrl}/dashboard/map`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    }).then((res) => {
      this.setState({ activities: res.data.entity });
    });
  };

  render() {
    const { classes } = this.props;
    const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <div style={{ margin: "30px 12px", padding: 24, background: "#fff" }}>
            {this.state.notify && (
              <Alert
                closeText='Click to View'
                onClose={() => this.props.history.push("/seasons")}
                message='You have seasons not currently started, kindly click to start them'
                type='info'
                showIcon
              />
            )}
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                backgroundColor: "#f2f3f4",
                padding: 10,
              }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            {(this.props.userStore.role.role === "Aggregator" ||
              this.props.userStore.role.role === "Project Manager") && (
              <div>
                <div className='top-bar'>
                  <div>
                    <Select
                      defaultValue='Select Crop'
                      style={{ width: 120, marginBottom: 10 }}
                      onChange={(e) => this.handleChange(e)}
                    >
                      <Option value={0}>All</Option>
                      {this.state.crops.map((item) => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {item.crop_name}
                          </Option>
                        );
                      })}
                    </Select>
                    <Link to='/activities/dashboard'>
                      <Button
                        type='primary'
                        style={{ marginLeft: 10, background: "#009688" }}
                      >
                        Farm Activities
                      </Button>
                    </Link>
                  </div>

                  <Link to='/activities/view'>
                    <Button
                      type='primary'
                      className={classes.button}
                      style={{ background: "#009688" }}
                    >
                      View Agent Activities
                    </Button>
                  </Link>
                </div>

                <br />
                <Row
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={8}>
                    <Title level={4}>Activities Map</Title>
                  </Col>
                  <Col span={8} />
                  <Col span={8}>
                    <div style={{ alignSelf: "flex-end" }}>
                      <RangePicker
                        ranges={{
                          Today: [moment(), moment().add(1, "days")],
                          Yesterday: [moment().add(-1, "days"), moment()],
                          "Last 7 Days": [moment().add(-7, "days"), moment()],
                          "Last 30 Days": [moment().add(-30, "days"), moment()],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                          "Last Month": [
                            moment()
                              .subtract(1, "months")
                              .date(1),
                            moment()
                              .subtract(1, "months")
                              .endOf("month"),
                          ],
                        }}
                        onChange={(_, date) => this.onChange(date)}
                      />
                    </div>
                  </Col>
                </Row>
                <Maps data={this.state.activities} />
              </div>
            )}
          </div>
          {this.props.userStore.role.role === "Admin" && (
            <div
              style={{ margin: "16px 12px", padding: 24, background: "#fff" }}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title={
                      env === "staging"
                        ? "Total Service Providers"
                        : "Total Number of Tenants"
                    }
                  >
                    <Title style={{ fontSize: "15px", color: "#fff", fontWeight: "bold", }}>{this.state.data.tenants}</Title>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title='Total Number of Users'
                  >
                    <Title style={{ fontSize: "15px", color: "#fff", fontWeight: "bold", }}>{this.state.data.users}</Title>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title='Total Number of Farmers'
                  >
                    <Title style={{ fontSize: "15px", color: "#fff", fontWeight: "bold", }}>{this.state.data.farmers}</Title>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          {(this.props.userStore.role.role === "Aggregator" ||
            this.props.userStore.role.role === "Project Manager") && (
            <div
              style={{ margin: "16px 12px", padding: 20, background: "#fff" }}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                      
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title='Total Expected Yield (MT)'
                  >
                    <Title style={{ fontSize: "20px", color: "#fff", fontWeight: "bold", }}>
                      {parseInt(this.state.data.t_yield).toFixed(2)}
                    </Title>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title='Total Acreage (HA)'
                  >
                    <Title style={{ fontSize: "20px", color: "#fff", fontWeight: 700, }}>
                      {parseInt(this.state.data.t_acreage).toFixed(2) || 0}
                    </Title>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    // style={{ height: 150 }}
                    bodyStyle={{
                      color: "#fff",
                      fontWeight: "bold",
                      // textAlign: "center",
                      background: "#009688",
                      padding: "2px 25px",
                    }}
                    headStyle={{ background: "#009688", color: "#fff", fontSize: "12px" }}
                    title='Total Number of Farmers'
                  >
                    <Title style={{ fontSize: "20px", color: "#fff", fontWeight: "bold", }}>{this.state.data.farmers_no || 0}</Title>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          <div className='table-container' style={{ margin: "16px 12px" }}>
            {this.props.userStore.role.role === "Aggregator" && (
              <div>
                <Title level={4}>Expected Yield / Farm</Title>
                <Table
                  bordered
                  rowKey={(record) => record._id}
                  loading={this.state.isFetching}
                  dataSource={this.state.data.lots}
                >
                  <Column
                    title='Farmer Name'
                    dataIndex='farmer'
                    key='farmer'
                    render={(text, record) => (
                      <span>
                        {`${text ? text.first_name : ""} ${
                          text ? text.last_name : ""
                        }`}
                      </span>
                    )}
                  />
                  <Column title='Lot ID' dataIndex='farm_id' key='farm_id' />
                  <Column title='Size' dataIndex='size' key='size' />
                  <Column
                    title='Expected Yield'
                    dataIndex='ex_yield'
                    key='ex_yield'
                  />
                </Table>
              </div>
            )}
            {this.props.userStore.role.role === "Admin" && (
              <div>
                <Title level={4}>
                  {env === "staging" ? `Service Providers` : `Tenants`}
                </Title>
                <Table
                  bordered
                  rowKey={(record) => record._id}
                  loading={this.state.isFetching}
                  dataSource={this.state.tenants}
                >
                  <Column title='Name' dataIndex='name' key='name' />
                  <Column title='Farmers' dataIndex='farmers' key='farmers' />
                  <Column title='Agents' dataIndex='agents' key='agents' />
                  <Column
                    title='Project Manager'
                    dataIndex='Project Manager'
                    key='Project Manager'
                  />
                </Table>
              </div>
            )}
          </div>
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MiniDrawer);
