import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Form, Input, Collapse, Icon, Checkbox, Col, Button, Spin, Breadcrumb } from 'antd'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import Drawer from '../dashboard/Drawer'

const { TextArea } = Input
const { Title } = Typography
const Panel = Collapse.Panel;

// const customPanelStyle = {
//     background: '#f7f7f7',
//     borderRadius: 4,
//     marginBottom: 24,
//     border: 0,
//     overflow: 'hidden',
// };


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});


@inject('userStore')
@observer
class AddGroup extends React.Component {
    state = {
        loading: false,
        isFetching: true,
        data: {},
        role: this.props.location.state.role,
        desc: this.props.location.state.desc,
        permissions: []

    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        axios({
            url: `${config.baseUrl}/role/${this.props.location.state._id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false, data: res.data.entity })
        }).then(() => {
            this.setState({
                permissions: Object.keys(this.state.data).filter(item => item !== 'role' && item !== '_id' && this.state.data[item] === 1)
            })
        }).catch(err => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handlePermisions = (val) => {
        //if it's 1, change to zero and remove from permissions array
        if (this.state.permissions.includes(val)) {
            this.setState((prevState) => {
                return {
                    data: { ...this.state.data, [val]: 0 },
                    permissions: prevState.permissions.filter(item => item !== val)
                }
            })
        }
        else {
            //change to 1 and add to permissions array
            this.setState((prevState) => {
                return {
                    data: { ...this.state.data, [val]: 1 },
                    permissions: [...prevState.permissions, val]
                }
            })
        }
    }

    // handleSubmit = async (e) => {
    //     e.preventDefault()
    //     const data = {
    //         role: this.state.role,
    //         desc: this.state.desc
    //     }

    //     if (!this.state.role) {
    //         return this.props.userStore.openNotificationWithIcon('error', 'Please supply aName')
    //     }
    //     if (!this.state.desc) {
    //         return this.props.userStore.openNotificationWithIcon('error', 'Please supply Description')
    //     }
    //     if (this.state.permissions.length === 0) {
    //         return this.props.userStore.openNotificationWithIcon('error', 'Please select at least one group permission')
    //     }
    //     this.setState({ loading: true })
    //     await axios({
    //         url: `${config.baseUrl}/role`,
    //         method: 'POST',
    //         data,
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${this.props.userStore.token}`
    //         },
    //     }).then((res) => {
    //         if (res.data.status.code !== 100) {
    //             return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
    //         }
    //         this.handleSendPermissions(res.data.entity)
    //     }).catch((err) => {
    //         this.setState({ loading: false })
    //         this.props.userStore.openNotificationWithIcon('error', err.message)
    //     })
    // }

    handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            desc: this.state.desc,
            permissions: this.state.permissions
        }
        if (!this.state.desc) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply Description')
        }
        if (this.state.permissions.length === 0) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select at least one group permission')
        }
        this.setState({ loading: true })
        axios({
            url: `${config.baseUrl}/role/${this.props.location.state._id}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', 'Successfully edited role')
            this.props.history.goBack()
        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/groups">Role Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Edit Role
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Edit Role</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Role Name">
                                    <Input value={this.state.role} disabled />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Description">
                                    <TextArea value={this.state.desc} onChange={(e) => this.setState({ desc: e.target.value })} />
                                </Form.Item>
                                <br />
                                <Title style={{ color: '#2A3F54' }} level={4}>Group Permissions</Title>
                                <Collapse
                                    accordion
                                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                                >
                                    <Panel header="Dashboard" key="1">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.DASHBOARD_VIEW === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="DASHBOARD_VIEW">Ability to View Dashboard</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="User Management Module" key="2">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.GROUP_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="GROUP_MANAGE">Ability to Manage Groups</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.USER_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="USER_MANAGE">Ability to Manage Users</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.MANAGERS_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="MANAGERS_MANAGE">Ability to Manage Project Managers</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.FARMERS_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="FARMERS_MANAGE">Ability to Manage Farmers</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Farm Management" key="3">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.FARM_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="FARM_MANAGE">Ability to Manage Farms</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.CLUSTER_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="CLUSTER_MANAGE">Ability to Manage Clusters</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.SUB_CLUSTER_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="SUB_CLUSTER_MANAGE">Ability to Manage Sub Clusters</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.TENANT_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="TENANT_MANAGE">Ability to Manage Tenants</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="M&E" key="4">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.FARM_ACTIVITIES === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="FARM_ACTIVITIES">Ability to Manage Activities</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.FARM_ACTIVITIES_DASHBOARD === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="FARM_ACTIVITIES_DASHBOARD">Ability to View Activities Dashboard</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.FARM_ACTIVITIES_VIEW === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="FARM_ACTIVITIES_VIEW">Ability to View Activities Table</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Transactions" key="5">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.TRANSACTIONS_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="TRANSACTIONS_MANAGE">Ability to Manage Transactions</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Communication Module" key="6">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.COMMUNICATION_MANAGE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="COMMUNICATION_MANAGE">Ability to Use Communication Module</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                    <Panel header="Configuration Module" key="7">
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.CONFIG_CROP_PROFILE === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="CONFIG_CROP_PROFILE">Ability to Configure Crop Profile</Checkbox>
                                            </Col>
                                        </Form.Item>
                                        <Form.Item>
                                            <Col span={24}>
                                                <Checkbox
                                                    checked={this.state.data.CONFIG_FARM_ACTIVITIES === 1 ? true : false}
                                                    onChange={(e) => this.handlePermisions(e.target.value)}
                                                    value="CONFIG_FARM_ACTIVITIES">Ability to Configure Activities</Checkbox>
                                            </Col>
                                        </Form.Item>
                                    </Panel>
                                </Collapse>
                                <Form.Item>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>

                        </div>
                    }

                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddGroup);


// "entity": {
//         "role": null,
//         "CROPS_ADD": null,
//         "SEASON_CONFIGURE": null,
//         "CONFIG_SEASON_MANAGE": null,
//         "_id": null
//     }