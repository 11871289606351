import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin,
} from 'antd';
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const Option = Select.Option;
const { Title } = Typography;
const { TextArea } = Input;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class EditSubCluster extends React.Component {

    static contextType = AuthContext
    state = {
        agents: [],
        isFetching: true,
        clusters: [],
        loading: false,
        selectedCluster: this.props.location.state.cluster._id,
        selectedAgent: this.props.location.state.agent ? this.props.location.state.agent._id : "",
        ...this.props.location.state,
    }

    async componentDidMount() {
        this.fetchContent()
        this.fetchAgents()
    }

    fetchContent = async () => {
        await axios({
            url: `${config.baseUrl}/clusters`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ clusters: res.data.entity })

        }).catch((err) => {
            this.setState({ isFetching: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }
    fetchAgents = async () => {
        await axios({
            url: `${config.baseUrl}/cluster/agents`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ agents: res.data.entity })

        }).catch((err) => {
            this.setState({ isFetching: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            desc: this.state.description,
            cluster: this.state.selectedCluster,
            agent: this.state.selectedAgent
        }
        if (!this.state.name || !this.state.description) {
            return this.props.userStore.openNotificationWithIcon('error', 'Name and Description required')
        }
        if (!this.state.selectedAgent) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select an agent')
        }
        if (!this.state.selectedCluster) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select a cluster')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/subcluster/${this.props.location.state._id}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully Updated Sub Cluster")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div> :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Edit Sub Cluster</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Sub Cluster Name">
                                    <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    label="Agent">
                                    <Select value={this.state.selectedAgent} onChange={(value) => this.setState({ selectedAgent: value })}>
                                        {this.state.agents.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Sub Cluster Description">
                                    <TextArea value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    label="Cluster">
                                    <Select defaultValue={this.state.cluster._id} onChange={(value) => this.setState({ selectedCluster: value })}>
                                        {this.state.clusters.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(EditSubCluster);
