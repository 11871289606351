import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, Breadcrumb, Button, Icon, Tag, Row, Col, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTasks, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import config from '../../config'
import Uploader from './Uploader';
import MapViewer from './MapViewer';

const { Title } = Typography;
const { Column } = Table
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});


@inject('userStore')
@observer
class Verification extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,
        visible: false,
        mapVisible: false,
        cards: null,
        partner: "",
        partners: [],
        points: []
    }

    componentDidMount() {
        this.fetchContent()
    }

    downloadFile = (record) => {
        window.open(record.link, "_blank")
    }

    fetchContent() {
        const filterPartner = this.state.partner ? `?partner=${this.state.partner}` : '';
        this.setState({ isLoading: true })
        axios.all([
            axios({
                url: `${config.uploadBaseUrl}/tie/data${filterPartner}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.uploadBaseUrl}/tie/data/dashboard${filterPartner}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.uploadBaseUrl}/tie/partners`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res1.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res1.data.aaData,
                pagination,
                cards: res2.data.entity,
                partners: res3.data.entity
            })

            console.log(res1.data.aaData)

        }));
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })

        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    checkFilter = (pagination) => {
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }

        const filterPage = page === 0 ? '' : `&page=${page}`
        const filterStatus = this.state.status ? `&status=${this.state.status}` : '';
        const filterPartner = this.state.partner ? `&partner=${this.state.partner}` : '';
        const filterPath = filterStatus + filterPage + filterPartner;

        let url = `${config.uploadBaseUrl}/tie/data?per_page=${pageSize}${filterPath}`;
        this.filter(url)
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    handleMapCancel = () => {
        this.setState({ mapVisible: false })
    }

    renderStatus = (status) => {
        switch (status) {
            case 'YES':
                return <Tag color="green">Passed</Tag>
            case 'NO':
                return <Tag color="red">Failed</Tag>
            case 'MAYBE':
                return <Tag color="orange">Pending</Tag>
            default:
                return <Tag color="grey">{status}</Tag>
        }
    }

    renderTruthy = (status) => {
        switch (status) {
            case 'true':
                return <Tag color="green">True</Tag>
            case 'false':
                return <Tag color="red">False</Tag>
            default:
                return <Tag color="orange">{status}</Tag>
        }
    }

    showPolygon = (record) => {
        const { coord } = record
        const coordPair = coord.split(",")
        const polygon = coordPair.map(pair => {
            const obj = {
                lat: parseFloat(pair.split(":")[0]),
                lng: parseFloat(pair.split(":")[1])
            }
            return obj
        })
        this.setState({
            points: polygon,
            mapVisible: true
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Batch Verification
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                        <Title style={{ color: '#2A3F54' }} level={3}>Batch Verification</Title>
                        <Select
                            style={{ width: '25%' }}
                            defaultValue="Filter Partners"
                            onChange={(partner) => {
                                this.setState({
                                    partner,
                                }, () => {
                                    this.fetchContent();
                                });
                            }}
                        >
                            <Option value="">All</Option>
                            {this.state.partners.map(partner => (
                                <Option key={partner.partner} value={partner.partner}>{partner.partner}</Option>

                            ))}

                        </Select>
                    </div>
                    <Row gutter={24}>
                        <Col span={6}>
                            <div style={{
                                border: '3px solid #f2f3f4',
                                backgroundColor: 'white',
                                padding: 20,
                                borderRadius: 15,
                                boxShadow: "3px 3px 10px #f2f3f4"
                            }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ fontSize: 12, fontWeight: '700' }}>Total</p>
                                    <FontAwesomeIcon icon={faTasks} color="#1890ff" style={{ fontSize: 24 }} />
                                </div>
                                <h1 style={{ fontWeight: '700' }}>{this.state.cards ? this.state.cards.total : 0}</h1>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={{
                                border: '3px solid #f2f3f4',

                                backgroundColor: 'white',
                                padding: 20,
                                borderRadius: 15,
                                boxShadow: "3px 3px 10px #f2f3f4"
                            }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ fontSize: 12, fontWeight: '700' }}>Passed</p>
                                    <FontAwesomeIcon icon={faCheck} color="#1890ff" style={{ fontSize: 24, color: 'green' }} />
                                </div>
                                <h1 style={{ fontWeight: '700' }}>{this.state.cards ? this.state.cards.passed : 0}</h1>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={{
                                border: '3px solid #f2f3f4',

                                backgroundColor: 'white',
                                padding: 20,
                                borderRadius: 15,
                                boxShadow: "3px 3px 10px #f2f3f4"
                            }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ fontSize: 12, fontWeight: '700' }}>Failed </p>
                                    <FontAwesomeIcon icon={faTimesCircle} color="#1890ff" style={{ fontSize: 24, color: 'red' }} />
                                </div>
                                <h1 style={{ fontWeight: '700' }}>{this.state.cards ? this.state.cards.failed : 0}</h1>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div style={{
                                border: '3px solid #f2f3f4',

                                backgroundColor: 'white',
                                padding: 20,
                                borderRadius: 15,
                                boxShadow: "3px 3px 10px #f2f3f4"
                            }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{ fontSize: 12, fontWeight: '700' }}>Pending</p>
                                    <FontAwesomeIcon icon={faExclamationTriangle} color="#1890ff" style={{ fontSize: 24, color: 'orange' }} />
                                </div>
                                <h1 style={{ fontWeight: '700' }}>{this.state.cards ? this.state.cards.pending : 0}</h1>
                            </div>
                        </Col>
                    </Row>
                    <>
                        <Button type="primary" onClick={() => this.setState({ visible: true })} className={classes.button}>
                            Upload Sheet
                        </Button>
                    </>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>


                            <Column
                                title="Unique ID"
                                dataIndex="reference"
                                key="reference"

                            />

                            <Column
                                title="Partner"
                                dataIndex="partner"
                                key="partner"

                            />

                            <Column
                                title="Created"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LL, h:mm:A')}
                                    </span>
                                )}

                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text) => {
                                    return this.renderStatus(text)
                                }}
                            />
                            <Column
                                title="Within State"
                                dataIndex="within_state"
                                key="within_state"
                                render={(text, record) => (
                                    <span>
                                        {this.renderTruthy(text)}
                                    </span>
                                )}

                            />
                            <Column
                                title="Within LGA"
                                dataIndex="within_lga"
                                key="within_lga"
                                render={(text, record) => (
                                    <span>
                                        {this.renderTruthy(text)}
                                    </span>
                                )}

                            />
                            <Column
                                title="Within Country"
                                dataIndex="within_country"
                                key="within_country"
                                render={(text, record) => (
                                    <span>
                                        {this.renderTruthy(text)}
                                    </span>
                                )}

                            />
                            <Column
                                title="Action"
                                render={(_, record) => {
                                    return (
                                        <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push('/verification-details', record)}>
                                            <Icon type="eye" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                        </Table>
                        <MapViewer
                            points={this.state.points}
                            handleMapCancel={this.handleMapCancel}
                            mapVisible={this.state.mapVisible}
                        />
                        <Uploader
                            visible={this.state.visible}
                            token={this.props.userStore.token}
                            refetch={() => this.fetchContent()}
                            uploadUrl="tie/upload"
                            handleCancel={this.handleCancel}
                            downloadUrl=""
                        />
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Verification);
