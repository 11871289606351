import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, Breadcrumb, Button, Icon, Tag } from 'antd';
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import config from '../../config'
import MapViewer from './MapViewer';
import Drawer from '../dashboard/Drawer'


const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        background: '#203a45',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});

@inject('userStore')
@observer
class MaybeResults extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        mapVisible: false,
        points: []
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        this.setState({ isLoading: true })
        axios.all([
            axios({
                url: `${config.uploadBaseUrl}/tie/data?status=MAYBE`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {

            const pagination = { ...this.state.pagination };
            pagination.total = res1.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res1.data.aaData,
                pagination,
            })

        }));
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    checkFilter = (pagination) => {
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }

        const filterPage = page === 0 ? '' : `&page=${page}`
        const filterPath = filterPage;

        let url = `${config.uploadBaseUrl}/tie/data?status=MAYBE&per_page=${pageSize}${filterPath}`;
        this.filter(url)
    }


    handleMapCancel = () => {
        this.setState({ mapVisible: false })
    }

    showPolygon = (record) => {
        const { coord } = record
        const coordPair = coord.split(",")
        const polygon = coordPair.map(pair => {
            const obj = {
                lat: parseFloat(pair.split(":")[0]),
                lng: parseFloat(pair.split(":")[1])
            }
            return obj
        })
        this.setState({
            points: polygon,
            mapVisible: true
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Vegetation Check
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                        <Title style={{ color: '#2A3F54' }} level={3}>Vegetation Check</Title>
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>

                            <Column
                                title="Unique ID"
                                dataIndex="_id"
                                key="_id"

                            />

                            <Column
                                title="Cluster Name"
                                dataIndex="cluster_name"
                                key="cluster_name"

                            />

                            <Column
                                title="Partner"
                                dataIndex="partner"
                                key="partner"

                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text) => {
                                    return <Tag color="orange">{text}</Tag>
                                }}
                            />
                            <Column
                                title="Action"
                                render={(_, record) => {
                                    return (
                                        <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push('/maybe-details', record)}>
                                            <Icon type="eye" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                        </Table>
                        <MapViewer
                            points={this.state.points}
                            handleMapCancel={this.handleMapCancel}
                            mapVisible={this.state.mapVisible}
                        />
                    </div>
                </main>
            </div>
        );
    }
}


export default withRouter(withStyles(styles, { withTheme: true })(MaybeResults));
