import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Breadcrumb, Row, Col, Spin, Button, Form, notification, Select, Popconfirm } from 'antd';
import { pickBy, identity } from 'lodash';
import axios from 'axios';
import { useFarmer } from '../../config/utils';
// import * as Yup from 'yup';
import Drawer from '../dashboard/Drawer'
import Map from '../farm/FarmMap';
import config from '../../config'


const { Title } = Typography;
const { Option } = Select;


const user = localStorage.getItem('user-auth')
const parsedUser = JSON.parse(user)

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
})




function ManualInput(props) {
    const { classes } = props;
    const [busy, setBusy] = useState(false);

    const [statusSelectVisible, setStatusSelectVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("")
    const farmer_id = props.location.pathname.split("/")[2];

    const { farmer, isLoading } = useFarmer(`${config.baseUrl}/farmer/${farmer_id}`, parsedUser.token);


    const closeToWaterBody = (status) => {
        if (status === "1") return "Yes"
        else return "No"
    };

    const changeStatus = async () => {
        const data = pickBy({ review_status: selectedStatus }, identity)
        setBusy(true)
        await axios({
            url: `${config.baseUrl}/farmer/${farmer._id}/review`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${parsedUser.token}`
            },
        }).then(res => {
            setBusy(false)
            setStatusSelectVisible(false)
            openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            setBusy(false)
            openNotificationWithIcon('error', err.message)
        })
    }


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            style: {
                marginTop: 50
            },
            message,
        });
    };


    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            Reviewed Coordinates
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {farmer_id}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div>
                    <Title style={{ color: '#2A3F54' }} level={3}>Reviewed Coordinates</Title>
                </div>
                <div>
                    {isLoading ? <div style={{ textAlign: 'center' }}><Spin /></div> :

                        <Row>
                            <Col span={10}>
                                <div className="table-container">
                                    <h6 style={{ fontWeight: 800, color: '#959595' }}>CLUSTER INFORMATION</h6>
                                    <br />
                                    <h6 style={{ color: '#6c6c6c' }}>Cluster Name: <span style={{ color: '#000' }}>{farmer && `${farmer.first_name} ${farmer.last_name}`}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Association: <span style={{ color: '#000' }}>{farmer && farmer.client_name}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Expected Farm Size (Ha): <span style={{ color: '#000' }}>{farmer && farmer.farm_cluster.cluster_size}</span></h6>
                                </div>
                                <br />
                                <div className="table-container">
                                    <h6 style={{ fontWeight: 800, color: '#959595' }}>AGENT INFORMATION</h6>
                                    <br />
                                    <h6 style={{ color: '#6c6c6c' }}>Agent Name: <span style={{ color: '#000' }}>{farmer && farmer.mapped_by && `${farmer.mapped_by.first_name} ${farmer.mapped_by.last_name}`}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Agent Email: <span style={{ color: '#000' }}>{farmer && farmer.mapped_by && `${farmer.mapped_by.email}`}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Agent Phone Number: <span style={{ color: '#000' }}>{farmer && farmer.mobile}</span></h6>
                                </div>
                                <br />
                                <div className="table-container">
                                    <h6 style={{ fontWeight: 800, color: '#959595' }}>CAPTURED INFORMATION</h6>
                                    <br />
                                    <h6 style={{ color: '#6c6c6c' }}>Size of Land (Ha): <span style={{ color: '#000' }}>{farmer && parseFloat(farmer.farm_size).toFixed(2)}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Close To Water Body: <span style={{ color: '#000' }}>{farmer && closeToWaterBody(farmer.farm_cluster.close_to_water_body)}</span></h6>
                                    <h6 style={{ color: '#6c6c6c' }}>Water Bodies: <span style={{ color: '#000' }}>{farmer && farmer.farm_cluster.water_body_type}</span></h6>
                                </div>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={12}>

                                {(farmer && farmer.farm.points && farmer.farm.points.length) ?
                                    <Map
                                        points={farmer.farm.points}

                                    />
                                    : null
                                }
                                <br />
                                {/* <h6 style={{ color: '#6c6c6c' }}>Status: <span style={{ color: '#000' }}>{farmer.review_status}</span></h6> */}
                                <Button disabled={busy} type="primary" onClick={() => setStatusSelectVisible(true)}>
                                    Change Review Status
                                </Button>
                                <br />
                                {statusSelectVisible &&
                                    <>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label={<span>Select Status</span>}>
                                            <Select defaultValue="Select Status" onChange={(value) => setSelectedStatus(value)}>
                                                <Option value="APPROVED">APPROVED</Option>
                                                <Option value="REJECTED">REJECTED</Option>
                                                <Option value="PENDIND">PENDIND</Option>
                                            </Select>
                                        </Form.Item>
                                        <br />
                                        <Popconfirm
                                            title={`Are you sure you want to change  this status to ${selectedStatus}?`}
                                            onConfirm={changeStatus}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button loading={busy} disabled={!selectedStatus || busy} type="primary" onClick={() => setStatusSelectVisible(true)}>
                                                Submit
                                        </Button>
                                        </Popconfirm>

                                    </>

                                }
                            </Col>
                        </Row>
                    }

                    {/* <Modal
                        title="Reason for rejection"
                        visible={visible}
                        onOk={() => reject("REJECTED")}
                        confirmLoading={busy}
                        okText={"Submit"}
                        onCancel={() => setVisible(false)}
                    >
                        <Form.Item
                            style={{ margin: 0 }}
                            label="Reason for rejection">

                        </Form.Item>
                    </Modal> */}
                </div>
            </main>
        </div >
    )
}
export default withStyles(styles, { withTheme: true })(ManualInput);