import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import {
    Form, InputNumber, Typography, Button, Spin, Breadcrumb, Radio, DatePicker, Upload, message, Icon, Select
} from 'antd';
import config from '../../config'

const { Title } = Typography;
const { Option } = Select;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class LandAgreement extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        value: null
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/role`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/tenants`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2) => {
            this.setState({
                isFetching: false,
                rolesData: res1.data.entity,
                tenantData: res2.data.entity.tenants
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            password: this.state.password,
        }
        const mailTest = /\S+@\S+\.\S+/
        if (mailTest.test(this.state.email) === false) {
            return this.props.userStore.openNotificationWithIcon('error', 'Invalid Email Address')
        }
        if (!this.state.first_name || !this.state.last_name) {
            return this.props.userStore.openNotificationWithIcon('error', 'Firstname and Lastname required')
        }
        if (!this.state.password) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a password')
        }
        if (!this.state.role) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select user grouo')
        }
        if (this.state.roleText === "Aggregator" && !this.state.tenant_id) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a tenant')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added User")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;

        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            {/* <Breadcrumb.Item>
                                <Link to="/users">User Management</Link>
                            </Breadcrumb.Item> */}
                            <Breadcrumb.Item>
                                Land Agreement Upload
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54', marginBottom: 20 }} level={3}>Land Agreement Upload</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Select an agreement <span style={{ color: 'red' }}> *</span></h6>
                                    <Upload {...props}>
                                        <Button size="small" >
                                            <Icon type="upload" /> Click to Upload
                                    </Button>
                                    </Upload>
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Type of Agreement <span style={{ color: 'red' }}> *</span></h6>
                                    <Select
                                        style={{ width: '60%' }}
                                        defaultValue="Type of Agreement"
                                        onChange={(status) => {
                                            this.setState({
                                                status
                                            });
                                        }}
                                    >
                                        <Option value="Self Owned">Self Owned</Option>
                                        <Option value="Leased">Leased</Option>
                                        <Option value="Community Agreement">Community Agreement</Option>
                                        <Option value="Agreement with Farmers">Agreement with Farmers</Option>
                                        <Option value="3rd Party Owned">3rd Party Owned</Option>
                                    </Select>
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Is the agreement signed? <span style={{ color: 'red' }}> *</span></h6>
                                    <Radio.Group onChange={(e) => this.setState({ value: e.target.value })} value={this.state.value}>
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={2}>No</Radio>

                                    </Radio.Group>
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Exact size of land in agreement (In Hectares) <span style={{ color: 'red' }}> *</span></h6>
                                    <InputNumber type="number" style={{ width: '60%' }} onChange={(e) => this.setState({ last_name: e })} />
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Duration of Agreements (In Months) <span style={{ color: 'red' }}> *</span></h6>
                                    <InputNumber type="number" style={{ width: '60%' }} onChange={(e) => this.setState({ last_name: e })} />
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <h6 style={{ marginBottom: 10 }}>Date Agreement was Executed <span style={{ color: 'red' }}> *</span></h6>
                                    <DatePicker style={{ width: '60%' }} onChange={(date) => this.setState({ date })} />
                                </div>
                                <br />
                                <div style={{ marginBottom: 10 }}>
                                    <Button type="primary" style={{background: '#009688'}}>Upload</Button>
                                </div>
                            </Form>

                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(LandAgreement);
