import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Breadcrumb
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { TextArea } = Input;
const { Title } = Typography;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class AddServiceProvider extends React.Component {

    static contextType = AuthContext
    state = {
        name: "",
        description: "",
        loading: false
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            description: this.state.description
        }
        if (!this.state.name || !this.state.description) {
            return this.props.userStore.openNotificationWithIcon('error', 'Name and Description required')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/tenant`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added Tenant")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/service-providers">Service Provider Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Service Provider
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Add New Service Provider</Title>
                        <Form onSubmit={this.handleSubmit} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Name">
                                <Input onChange={(e) => this.setState({ name: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Description">
                                <TextArea onChange={(e) => this.setState({ description: e.target.value })} />
                            </Form.Item>
                            <Form.Item>
                                <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddServiceProvider);
