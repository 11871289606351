import React from 'react';
import { Radio, Breadcrumb } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import Dvalco2 from './Dvalco2';
import DistributionDashboard from './DistributionDashboard';
import Drawer from './Drawer'


const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#F3F7FA',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        backgroundColor: '#F3F7FA'
    },
    card: {
        maxWidth: 400,
        marginBottom: theme.spacing.unit * 2.2,
    },

    cardTitle: {
        fontWeight: 700,
    }
});

class ProgrammeIndex extends React.Component {

    state = {
        version: "v1"
    }

    onChange = (e) => {
        this.setState({ version: e.target.value })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div style={{ margin: '30px', padding: "24px 0", background: '#F3F7FA' }}>

                        <div style={{ marginTop: 10, marginBottom: 5, backgroundColor: '#f2f3f4', padding: 10 }}>
                            <Breadcrumb>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>Programme Overview</Breadcrumb.Item>
                                <Breadcrumb.Item>{this.state.version}</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                        <br />
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h5>Programme Dashboard</h5>
                            <Radio.Group defaultValue="v1" onChange={this.onChange} buttonStyle="solid">
                                <Radio.Button value="v1">V1</Radio.Button>
                                <Radio.Button value="v2">V2</Radio.Button>
                            </Radio.Group>
                        </div>

                        <br />
                        <br />
                        {this.state.version === "v1" && <Dvalco2 />}
                        {this.state.version === "v2" && <DistributionDashboard />}

                    </div>
                </main>
            </div>
        )
    }

}

export default withStyles(styles, { withTheme: true })(ProgrammeIndex);