import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Row, Col, Table, Form, Select, Badge, Card, Skeleton, Modal, Tag, Divider, Breadcrumb, Carousel } from 'antd';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table
const { Option } = Select


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    },

});

@inject('userStore')
@observer
class Activities extends React.Component {

    state = {
        isFetching: true,
        clusters: [],
        crop_profiles: [],
        seasons: [],
        sub_clusters: [],
        activities: [],
        tasks: [],
        activity: {},
        fetchingActivity: true,
        visible: false,
        imageVisible: false
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/clusters`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/config/crop_profiles`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/config/seasons`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/subclusters`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farm/activities/dashboard`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/config/tasks`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })
        ]).then(axios.spread((res1, res2, res3, res4, res5, res6) => {

            console.log(res5)
            this.setState({
                isFetching: false,
                clusters: res1.data.entity,
                crop_profiles: res2.data.entity.profiles || [],
                seasons: res3.data.entity.seasons,
                sub_clusters: res4.data.entity,
                activities: res5.data.entity,
                tasks: res6.data.entity.tasks
            })
        }));
    }

    fetchActivity = async (farmer, task) => {
        const data = {
            farmer,
            task
        }
        this.setState({ fetchingActivity: true, visible: true })
        await axios({
            url: `${config.baseUrl}/farm/activity/fetch`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ activity: res.data.entity, fetchingActivity: false })

        }).catch((err) => {
            this.setState({ fetchingActivity: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    renderTableCell(text, record, item) {
        if (text == null) {
            return (
                <span style={{ backgroundColor: '#fff' }}>
                </span>
            )
        }
        else if (text === 'done') {
            return (
                <div>
                    <div
                        onClick={() => {
                            this.fetchActivity(record.farmer.farmer_id, item.task)
                        }}
                        style={{ cursor: 'pointer', backgroundColor: '#5cb85c', color: '#fff', textAlign: 'center', width: 80, padding: 10, borderRadius: 4 }}>
                        {text}
                    </div>
                    <br />
                    {/* <span>View more</span> */}
                </div>

            )
        }
        else if (text === 'skipped') {
            return (
                <div>
                    <div
                        onClick={() => this.fetchActivity(record.farmer.farmer_id, item.task)}
                        style={{ cursor: 'pointer', backgroundColor: '#1890ff', color: '#fff', textAlign: 'center', width: 80, padding: 10, borderRadius: 4 }}>{text}</div>
                    <br />
                    {/* <span>View more</span> */}
                </div>

            )
        }
        else if (text === 'delayed') {
            return (
                <div>
                    <div
                        onClick={() => this.fetchActivity(record.farmer.farmer_id, item.task)}
                        style={{ cursor: 'pointer', backgroundColor: '#d9534f', color: '#fff', textAlign: 'center', width: 80, padding: 10, borderRadius: 4 }}>{text}</div>
                    <br />
                    {/* <span>View more</span> */}
                </div>

            )
        }
        else if (text === 'pending') {
            return (
                <div>
                    <div
                        onClick={() => this.fetchActivity(record.farmer.farmer_id, item.task)}
                        style={{ cursor: 'pointer', backgroundColor: 'grey', color: '#fff', textAlign: 'center', width: 80, padding: 10, borderRadius: 4 }}>{text}</div>
                    <br />
                    {/* <span>View more</span> */}
                </div>

            )
        }
        else if (text === 'ongoing') {
            return (
                <div>
                    <div
                        onClick={() => this.fetchActivity(record.farmer.farmer_id, item.task)}
                        style={{ cursor: 'pointer', backgroundColor: '#f0ad4e', color: '#fff', textAlign: 'center', width: 80, padding: 10, borderRadius: 4 }}>{text}</div>
                    <br />
                    {/* <span>View more</span> */}
                </div>

            )
        }
        else {
            return (
                <span style={{ backgroundColor: '#fff' }}>
                </span>
            )
        }
    }

    handleOk = () => {
        this.showModal()
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderStatus = () => {
        const { status } = this.state.activity
        if (status === "skipped") {
            return (
                <Tag color="blue">Skipped</Tag>
            )
        }
        if (status === "ongoing") {
            return (
                <Tag color="orange">Ongoing</Tag>
            )
        }
        if (status === "pending") {
            return (
                <Tag color="grey">Pending</Tag>
            )
        }
        else if (status === "done") {
            return (
                <Tag color="green">Done</Tag>
            )
        }
        else if (status === "delayed") {
            return (
                <Tag color="red">Delayed</Tag>
            )
        }
        else {
            return (
                <Tag color="white"></Tag>
            )
        }
    }

    showModal = () => {
        Modal.info({
            icon: (<p style={{ display: 'none' }}></p>),
            content: (
                <div style={{ textAlign: 'center' }}>
                    <Carousel>
                        {this.state.activity.images && this.state.activity.images.map(image => (
                            <img key={image._id} alt="activity" src={image.image} />
                        ))}
                    </Carousel>
                </div>

            ),
            okText: "Close"
        })
    };


    render() {
        const { classes } = this.props;

        console.log(this.state.activities)
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/dashboard">Dashboard</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Farm Activities Dashboard
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Farm Activities Dashboard</Title>
                    </div>
                    <div>
                        <p style={{ fontWeight: 'bold', color: '#2A3F54' }}>Activity Dashboard Filter</p>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                        >
                                            <Select style={{ width: '80%' }} defaultValue="Select Crop Profile" onChange={(value) => this.setState({ crop_profile: value })}>
                                                {this.state.crop_profiles.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.crop_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                        >
                                            <Select style={{ width: '80%' }} defaultValue="Select Cluster" onChange={(value) => this.setState({ cluster: value })}>
                                                {this.state.clusters.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                        >
                                            <Select style={{ width: '80%' }} defaultValue="Select Crop Season" onChange={(value) => this.setState({ cluster: value })}>
                                                {this.state.clusters.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                        >
                                            <Select style={{ width: '80%' }} defaultValue="Select Sub Cluster" onChange={(value) => this.setState({ cluster: value })}>
                                                {this.state.clusters.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <Badge status="default" text="Activity yet to be carried out" />
                                    <br />
                                    <Badge status="success" text="Activity done on/before due date" />
                                    <br />
                                    {/* <Badge status="error" text="Activity done after due date" />
                                    <br /> */}
                                    <Badge status="warning" text="Activity ongoing" />
                                    <br />
                                    <Badge status="processing" text="Activity skipped" />

                                </Card>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Button type="primary" className={classes.button} style={{ background: "#009688" }}>
                                    Filter Activities
                                </Button>
                            </Col>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to="/activities/view">
                                    <Button type="primary" className={classes.button} style={{ background: "#009688" }}>
                                        View Agent Activities
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    {/* <Title style={{ color: '#2A3F54', textAlign: 'center' }} level={3}>Under Construction</Title> */}
                        {/* <div className="table-container" style={{ width: '100vw', overflow: 'scroll' }}> */}
                        <div className="table-container">
                            <Table
                                bordered
                                rowKey={record => record._id}
                                loading={this.state.isFetching}
                                scroll={{ x: 1300 }}

                                dataSource={this.state.activities}>
                                <Column
                                    title="Farmer ID"
                                    dataIndex="farmer.farmer_id"
                                    key="farmer.farmer_id"
                                />
                                <Column
                                    title="Farmer Name"
                                    dataIndex="farmer"
                                    key="farmer"
                                    render={(text, record) => (
                                        <span>
                                            {`${text ? text.first_name : ''} ${text ? text.last_name : ''}`}
                                        </span>
                                    )}
                                />
                                {this.state.tasks.map((item, index) => {
                                    return (
                                        <Column
                                            title={item.task}
                                            dataIndex={index + 1}
                                            key={index}
                                            render={(text, record) => this.renderTableCell(text, record, item)}
                                        />
                                    )
                                })}
                            </Table>
                        </div>
                    </div>
                    <Modal
                        title="Activity Card"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        width={600}
                        okText={"View"}
                        cancelText={"Close"}
                        onCancel={this.handleCancel}
                    >
                        {this.state.fetchingActivity ?
                            <div>
                                <Skeleton active />
                                <Skeleton active />
                            </div> :
                            <div>
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <h6>Activity</h6>
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Category</h6>
                                                <p>{this.state.activity.category.category}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Activity Name</h6>
                                                <p>{this.state.activity.task.task}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Status</h6>
                                                {this.renderStatus()}
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Expected Date</h6>
                                                <p>{this.state.activity.task.due_date}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Actual Date</h6>
                                                <p>{this.state.activity.date}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Tracked Date</h6>
                                                <p>{this.state.activity.created}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <h6>Farm Lot</h6>
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Crop</h6>
                                                <p>{this.state.activity.crop && this.state.activity.crop.crop_name}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Size (Ha)</h6>
                                                <p>{this.state.activity.farm.size}</p>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Address</h6>
                                                <p>{this.state.activity.farm.address}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>State</h6>
                                                <p>{this.state.activity.farm.state ? this.state.activity.farm.state.name : ""}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>LGA</h6>
                                                <p>{this.state.activity.farm.community ? this.state.activity.farm.community.local_name : ""}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <h6>Farmer</h6>
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Full Name</h6>
                                                {this.state.activity.farm.farmer ? <p>{this.state.activity.farm.farmer.first_name} {this.state.activity.farm.farmer.last_name}</p> : null}
                                            </Col>
                                            <Col span={8}>
                                                <h6>Phone Number</h6>
                                                {this.state.activity.farm.farmer ? <p>{this.state.activity.farm.farmer.mobile}</p> : null}

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <h6>Agent</h6>
                                    </Col>
                                    <Col span={18}>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <h6>Full Name</h6>
                                                <p>{this.state.activity.agent.first_name} {this.state.activity.agent.last_name}</p>
                                            </Col>
                                            <Col span={8}>
                                                <h6>Phone Number</h6>
                                                <p>{this.state.activity.agent.mobile}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={24}>
                                    <Col span={6}>
                                        <h6>Comment</h6>
                                    </Col>
                                    <Col span={18}>
                                        <p>{this.state.activity.comment}</p>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Modal>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Activities);
