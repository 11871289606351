import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Button, Typography, Breadcrumb, Input, Row, Col, notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
// import * as Yup from 'yup';
import Geocode from "react-geocode";

import Drawer from '../dashboard/Drawer'
import Map from './Map';


Geocode.setApiKey("AIzaSyAE5beByDMsRgJwMDLutqutRI_ZjxnyCJs");

const { Title } = Typography;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
})

const initialValues = {
    points: [
        { lat: "", lng: "" },
        { lat: "", lng: "" },
        { lat: "", lng: "" }
    ]
    // points: [
    //     { lat: 5.5262733, lng: 7.5261817 },
    //     { lat: 5.5262216, lng: 7.5262219 },
    //     { lat: 5.5260833, lng: 7.5261409 },
    //     { lat: 5.5260294, lng: 7.5259947 }
    // ]
}



function ManualInput(props) {
    const { classes } = props;
    const [polygons, setPolygons] = useState([])

    const [geocode, setGeocode] = useState("")

    const onSubmit = (values, onSubmitProps) => {
        setGeocode("")
        const points = values.points.filter(item => item.lat && item.lng)
        if (points.length < 3) {
            return openNotificationWithIcon('error', "Please enter 3 or more coordinates")
        }
        const polygon = {
            id: uuidv4(),
            points
        }
        setPolygons([polygon])
        onSubmitProps.resetForm()
        checkAdd(values)
    }

    const checkAdd = (values) => {
        const { points } = values
        Geocode.fromLatLng(points[0].lat, points[0].lng).then(
            response => {
                // const address = response.results[0].formatted_address;
                // console.log(address);
                const addressComp = response.results[0].address_components
                const fullname = addressComp.map(e => e.long_name).join(", ");
                setGeocode(fullname)
            },
            error => {
                console.error(error);
            }
        );
    }


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            style: {
                marginTop: 50
            },
            message,
        });
    };
    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            Manual Input
                    </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div>
                    <Title style={{ color: '#2A3F54' }} level={3}>Manually Input Coordinates</Title>
                </div>
                <div>
                    <Row>
                        <Col span={10}>
                            <div>
                                <p>Add 3 or more coordinates (Latitude and Longitude) to map to a polygon</p>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                >
                                    {(formik) => (<Form>
                                        <FieldArray
                                            name="points"
                                            render={arrayHelpers => (
                                                <div>
                                                    {formik.values.points && formik.values.points.length > 0 ? (
                                                        formik.values.points.map((friend, index) => (
                                                            <div key={index} style={{ display: 'flex', marginBottom: 10 }}>
                                                                <p style={{ marginRight: 10 }}>{index + 1}</p>
                                                                <Field name={`points[${index}].lat`}>

                                                                    {(props) => {
                                                                        const { field } = props
                                                                        return (
                                                                            <Input {...field} style={{ width: '40%' }} placeholder="Latitude" type="number" />
                                                                        )
                                                                    }}
                                                                </Field>
                                                                <div style={{ width: 10 }}></div>
                                                                <Field name={`points[${index}].lng`}>
                                                                    {(props) => {
                                                                        const { field } = props
                                                                        return (
                                                                            <Input {...field} style={{ width: '40%' }} placeholder="Longitude" type="number" />
                                                                        )
                                                                    }}
                                                                </Field>
                                                                <Button style={{ marginLeft: 10 }} type="danger" onClick={() => arrayHelpers.remove(index)}>-</Button>
                                                                <Button style={{ marginLeft: 10 }} onClick={() => arrayHelpers.push({ lat: "", lng: "" })} type="primary">+</Button>
                                                            </div>
                                                        ))
                                                    ) : (
                                                            <Button onClick={() => arrayHelpers.push({ lat: "", lng: "" })} type="primary">Add Coordinates</Button>
                                                        )}
                                                    <div style={{ marginTop: 40 }}>
                                                        <Button style={{ marginRight: 10 }} onClick={formik.handleSubmit} type="primary">Submit</Button>
                                                        <Button onClick={() => {
                                                            setPolygons([])
                                                            setGeocode("")
                                                            formik.resetForm()
                                                        }}>Reset</Button>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </Form>)}
                                </Formik>
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={12}>
                            <h5>{geocode}</h5>
                            <Map data={polygons}
                                center={polygons.length
                                    ?
                                    { lat: polygons[polygons.length - 1].points[0].lat, lng: polygons[polygons.length - 1].points[0].lng }
                                    : { lat: 6.4809427, lng: 3.36553 }} />
                        </Col>
                    </Row>

                </div>
            </main>
        </div >
    )
}
export default withStyles(styles, { withTheme: true })(ManualInput);