import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Typography, Card, Col, Row, Avatar, Spin, Select, Tooltip as AntTooltip, Icon, Button, DatePicker } from 'antd';
import { observer, inject } from 'mobx-react'

import {
    PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Label, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
// import Drawer from './Drawer'
import axios from 'axios'
import config from '../../config'
import { formatNumber } from '../../config/utils'



const Title = Typography.Title;
const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;


const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#F3F7FA',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        backgroundColor: '#F3F7FA'
    },
    card: {
        maxWidth: 400,
        marginBottom: theme.spacing.unit * 2.2,
    },

    centerDiv: {
        display: 'flex',
        justifyContent: 'center'
    },

    barTitle: {
        fontWeight: 700
    },
    cardTitle: {
        fontWeight: 700,
        color: '#fff'
    }
});

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
// const RADIAN = Math.PI / 180;


@inject('userStore')
@observer
class MetaDash extends React.Component {
    state = {
        loading: true,
        stateDistribution: [],
        barChartData: [],
        genderDistribution: [],
        totalPool: 0,
        bvnVerified: 0,
        gpsMapped: 0,
        totalGender: 0,
        submitted: 0,
        images: [],
        stateLoading: true,
        cropLoading: true,
        dashLoading: true,
        assocLoading: true,
        cropDistribution: [],
        seasons: [],
        season: "",
        states: [],
        state: "",
        crops: [],
        crop: "",
        dateFilter: "",
        associationFilters: [],
        associations: [],
        association: "",
        date: [],
        refreshing: false
    };

    async componentDidMount() {
        const path = this.getPath()
        this.fetchState(path)
        this.fetchDash(path)
        this.fetchCrop(path)
        this.fetchSeasons()
        this.fetchAssociations(path)
        this.fetchFilters(path)
    }


    fetchFilters(path) {
        this.setState({ isLoading: true })
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''
        axios.all([
            axios({
                url: `https://dashboard.tradebuza.com/process/opeissue/fetchCrops/dvc`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmers/client_name?data=0${path}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3) => {
            this.setState({ crops: res1.data.entity, states: res2.data.entity.states, associationFilters: res3.data.entity })

        }));
    }

    fetchAssociationFilter(path) {
        axios.all([
            axios({
                url: `${config.baseUrl}/farmers/client_name?data=0${path}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {
            this.setState({ associationFilters: res1.data.entity })

        }));
    }


    getPath = () => {
        const filterState = this.state.state ? `&state=${this.state.state}` : ''
        const filterCrop = this.state.crop ? `&crop=${this.state.crop}` : ''
        const filterSeason = this.state.season ? `&season=${this.state.season}` : '';
        const filterAssoc = this.state.association ? `&association=${this.state.association}` : '';
        const filterDateFrom = this.state.dateFilter && this.state.date && this.state.date.length && this.state.date[0] ? `&date_from=${this.state.date[0]}` : '';
        const dateFilter = this.state.date.length && this.state.dateFilter ? `&date_filter=${this.state.dateFilter}` : ``
        const filterDateTo = this.state.dateFilter && this.state.date && this.state.date.length && this.state.date[1] ? `&date_to=${this.state.date[1]}` : '';
        const filterPath = filterState + filterCrop + filterSeason + filterDateFrom + filterDateTo + dateFilter + filterAssoc;

        return filterPath;
    }

    refreshData = () => {
        const path = this.getPath()

        this.setState({ refreshing: true })
        this.fetchState(path)
        this.fetchDash(path)
        this.fetchCrop(path)
        this.fetchSeasons()
        this.fetchAssociations(path)
        this.fetchAssociationFilter(path)

    }

    async fetchAssociations(path) {
        if (this.props.userStore.tenant.tenant_id === "TN-74" || this.props.userStore.tenant.tenant_id === "TN-78") {
            await axios({
                url: `${config.baseUrl}/util/associations/get?data=0${path}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).then(res => {
                if (res.data.status.code !== 100) {
                    this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
                    return
                }

                this.setState({ associations: res.data.entity, assocLoading: false })

            })
                .catch(err => {
                    console.log(err.response)
                    this.setState({ assocLoading: false })
                    this.props.userStore.openNotificationWithIcon('error', err.message)
                })
        }
        else {
            return
        }

    }

    async fetchSeasons() {
        await axios({
            url: `${config.baseUrl}/project_names`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ seasons: res.data.entity })
        })
            .catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })
    }

    async fetchState(path) {
        const isGuest = this.props.userStore.role.role === "Guest"
        // this.setState({ stateLoading: true })

        await axios({
            url: `${config.baseUrl}/dashboard/dv2/state?data=0${path}${isGuest ? `&season=${"2020 Wet Season"}` : ''}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res => {
            if (!res.data.entity.state_dist) {
                this.setState({ stateLoading: false, barChartData: [], stateDistribution: [] })
                return
            }
            const barChartData = res.data.entity.state_dist
                .sort((a, b) => b.total_no - a.total_no)
                .slice(0, 10)
                .map(state => {
                    const newObj = {
                        name: state.home_state ? state.home_state.name.split(" State")[0] : "",
                        Total_Pool: parseInt(state.total_no),
                        BVN_Verified: parseInt(state.bvn_verified_no),
                        GPS_Mapped: parseInt(state.farms_mapped_no)
                    }
                    return newObj
                })
            this.setState({ stateLoading: false, barChartData, stateDistribution: res.data.entity.state_dist })



        })).catch(err => {
            console.log("err", err)
            this.setState({ stateLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    async fetchDash(path) {
        const isGuest = this.props.userStore.role.role === "Guest"
        // this.setState({ dashLoading: true })

        await axios({
            url: `${config.baseUrl}/dashboard/dv2?data=0${path}${isGuest ? `&season=${"2020 Wet Season"}` : ''}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res => {
            const genderDistribution = res.data.entity.gender_dist ? res.data.entity.gender_dist
                .sort((a, b) => parseInt(b.total_no) - parseInt(a.total_no))
                .map(item => {
                    const newObj = {
                        name: item.gender,
                        value: parseInt(item.total_no)
                    }
                    return newObj
                }) : []

            const images = res.data.entity.images || []
            this.setState({
                dashLoading: false,
                genderDistribution,
                bvnVerified: res.data.entity.bvn_verified,
                totalPool: res.data.entity.total_pool,
                gpsMapped: res.data.entity.gps_mapped,
                submitted: res.data.entity.submitted,
                // submitted: this.props.userStore.tenant.tenant_id === "TN-74" ? 191179 : res.data.entity.submitted,
                images,
                refreshing: false
            })
        })).catch(err => {
            this.setState({ dashLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    async fetchCrop(path) {
        const isGuest = this.props.userStore.role.role === "Guest"
        // this.setState({ cropLoading: true })
        await axios({
            url: `${config.baseUrl}/dashboard/dv2/crop?data=0${path}${isGuest ? `&season=${"2020 Wet Season"}` : ''}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res => {
            const filteredCrop = res.data.entity.crop_dist.filter(item => item.crop !== null)
                .sort((a, b) => a.crop.localeCompare(b.crop))
            this.setState({ cropDistribution: filteredCrop, cropLoading: false })
        })).catch(err => {
            this.setState({ cropLoading: false, refreshing: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    calculateTotal = () => {
        let sum = 0;
        this.state.genderDistribution.map(item => sum += item.value)
        return sum;
    }



    checkFilter = () => {
        const path = this.getPath()
        // const season = this.state.season
        this.setState({ refreshing: true })
        this.fetchState(path)
        this.fetchDash(path)
        this.fetchCrop(path)
        this.fetchAssociations(path)
        this.fetchAssociationFilter(path)
    }

    calcKYC = (pool) => {
        const poolNum = parseInt(pool)
        const percentage = (89.4 / 100) * poolNum
        return formatNumber(Math.ceil(percentage))
    }

    render() {
        const { classes } = this.props;
        const { role } = this.props.userStore.role
        const CustomTooltip = ({ active, payload }) => {
            if (active) {
                let name = payload[0].name
                let value = payload[0].value
                let total = this.calculateTotal()
                let percentage = ((value / total) * 100).toFixed(2)
                return (
                    <div style={{ backgroundColor: 'white', padding: '2px 10px', border: '1px solid #f2f3f4' }}>
                        <p>{`Gender: ${name}`}</p>
                        <p className="intro">Count: {formatNumber(value)}</p>
                        <p className="desc">Percentage: {percentage}%</p>
                    </div>
                );
            }

            return null;
        };



        const BarToolTip = ({ active, payload }) => {
            if (!payload) {
                return null;
            }
            if (active) {
                let state = payload[0].payload.name
                let total_pool = formatNumber(parseInt(payload[0].payload.Total_Pool))
                let bvn_verified = formatNumber(parseInt(payload[0].payload.BVN_Verified))
                let gps_mapped = payload[0].payload.GPS_Mapped
                return (
                    <div style={{ backgroundColor: 'white', padding: '2px 10px', border: '1px solid #f2f3f4' }}>
                        <p>{`State: ${state}`}</p>
                        <p style={{ color: "#14B6A7" }}>Total Pool: {total_pool}</p>
                        <p style={{ color: "#1B8EFA" }}>{role === "Guest" ? `KYC Captured` : `BVN Verified`}: {bvn_verified}</p>
                        <p style={{ color: "#8884D8" }}>GPS Mapped: {gps_mapped}</p>
                    </div>
                )
            }
            return null;
        }
        return (
            <>

                <div>

                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <AntTooltip title="Refresh Data">
                            <Button type="default" onClick={() => this.refreshData()}>
                                <Icon type="undo" size={36} style={{ color: '#2A3F54' }} />
                            </Button>
                        </AntTooltip>
                    </div> */}
                    {role !== "Guest" &&
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Select
                                    style={{ width: '20%' }}
                                    defaultValue="Filter Season"
                                    onChange={(value) => {
                                        this.setState({
                                            season: value
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    {this.state.seasons.filter(item => item.season).map((item) => {
                                        return (
                                            <Option key={item.season} value={item.season}>{item.season}</Option>
                                        )
                                    })}
                                </Select>
                                <Select
                                    style={{ width: '20%' }}
                                    defaultValue="Filter State"
                                    onChange={(value) => {
                                        this.setState({
                                            state: value
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    {this.state.states.map(state => (
                                        <Option key={state.state_id} value={state.state_id}>{state.name}</Option>

                                    ))}
                                </Select>
                                <Select
                                    style={{ width: '20%' }}
                                    defaultValue="Filter Crop"
                                    onChange={(value) => {
                                        this.setState({
                                            crop: value
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    {this.state.crops.map(crop => (
                                        <Option key={crop._id} value={crop._id}>{crop.crop_name}</Option>
                                    ))}
                                </Select>
                                <Select
                                    style={{ width: '15%' }}
                                    defaultValue="Filter Association"
                                    onChange={(value) => {
                                        this.setState({
                                            association: value
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    {this.state.associationFilters.filter(assoc => assoc.client_name).map((item, i) => (
                                        <Option key={i} value={item.client_name}>{item.client_name}</Option>
                                    ))}

                                </Select>

                                <Select
                                    style={{ width: '20%' }}
                                    defaultValue="Date Filter Category"

                                    onChange={(value) => {
                                        this.setState({
                                            dateFilter: value
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    <Option value="created">Date Created</Option>
                                    <Option value="captured_date">KYC Captured Date</Option>
                                    <Option value="mapped_date">Farm Mapped Date</Option>
                                    <Option value="bvn_submitted_date">BVN Submitted Date</Option>
                                </Select>
                                <AntTooltip title="Refresh Data">
                                    <Button type="default" onClick={() => this.refreshData()}>
                                        <Icon type="undo" size={36} style={{ color: '#2A3F54' }} />
                                    </Button>
                                </AntTooltip>
                            </div>
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <RangePicker
                                    style={{ width: '20%' }}
                                    disabled={!this.state.dateFilter}
                                    defaultValue={[]}
                                    ranges={{
                                        Today: [moment(), moment()],
                                        'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                        'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                        'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                        'All Time': [],
                                    }}
                                    onChange={(_, date) => {
                                        this.setState({
                                            date
                                        }, () => {

                                            this.checkFilter(date)
                                        });
                                    }}
                                />

                            </div>

                        </>


                    }
                    {this.state.refreshing &&
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin />
                        </div>
                    }
                    <br />
                    {this.state.dashLoading ?
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Spin />
                        </div> :
                        <div style={{ margin: '20px auto' }}>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Card style={{ backgroundColor: '#009688', borderRadius: 5 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>Total Pool</h6>
                                        </div>
                                        <div>
                                            <Title style={{ color: '#fff', fontWeight: 700 }}>{formatNumber(this.state.totalPool)}</Title>
                                        </div>
                                    </Card>

                                </Col>
                                <Col span={6}>
                                    <Card style={{ backgroundColor: '#009688', borderRadius: 5 }}>
                                        <div>
                                            {role === "Guest" ? <h6 className={classes.cardTitle}>KYC Captured</h6> : <h6 className={classes.cardTitle}>BVN Verified</h6>}
                                        </div>
                                        <div>
                                            {role === "Guest"
                                                ?
                                                <Title style={{ color: '#fff', fontWeight: 700 }}>{this.calcKYC(this.state.totalPool)}</Title>
                                                :
                                                <Title style={{ color: '#fff', fontWeight: 700 }}>{formatNumber(this.state.bvnVerified)}</Title>}
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card style={{ backgroundColor: '#009688', borderRadius: 5 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>Total Mapped Farms</h6>
                                        </div>
                                        <div>
                                            <Title style={{ color: '#fff', fontWeight: 700 }}>{formatNumber(this.state.gpsMapped)}</Title>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card style={{ backgroundColor: '#009688', borderRadius: 5 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>Submitted</h6>
                                        </div>
                                        <div>
                                            <Title style={{ color: '#fff', fontWeight: 700 }}>{formatNumber(this.state.submitted)}</Title>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <div style={{ margin: '50px auto' }}>
                                <h5 className={classes.barTitle}>Top 10 States</h5>
                                <br />
                                {this.state.stateLoading ?
                                    <div className={classes.centerDiv}>
                                        <Spin />
                                    </div> :
                                    <ResponsiveContainer width="98%" height={300}>
                                        <BarChart
                                            barCategoryGap={12}
                                            data={this.state.barChartData}
                                        >

                                            <XAxis dataKey="name" axisLine={{ stroke: '#CCC' }} stroke="#6C757D" />
                                            <YAxis domain={[0, 'dataMax']} axisLine={{ stroke: '#CCC' }} stroke="#6C757D" />
                                            <Tooltip content={<BarToolTip />} />
                                            <Legend wrapperStyle={{ top: -40 }} />
                                            <Bar dataKey="Total_Pool" fill="#14B6A7" name="Total Pool" />
                                            <Bar dataKey="BVN_Verified" fill="#1B8EFA" name="BVN Verified" />
                                            <Bar dataKey="GPS_Mapped" fill="#8884D8" name="GPS Mapped" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                }
                            </div>
                            <div style={{ margin: '50px 0' }}>
                                <h5>State Distribution</h5>
                                <br />
                                <div className="table-container">
                                    <Table
                                        loading={this.state.stateLoading}
                                        bordered
                                        rowKey={record => record._id}
                                        dataSource={this.state.stateDistribution}
                                    >
                                        <Column
                                            title="State"
                                            dataIndex="home_state"
                                            key="home_state"
                                            render={(text, record) => (
                                                <span>
                                                    {text ? `${text.name}` : `-`}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="Total Pool"
                                            dataIndex="total_no"
                                            key="total_no"
                                            render={(text, record) => (
                                                <span>
                                                    {formatNumber(parseInt(text))}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title={role === "Guest" ? `KYC Captured` : `BVN Verified`}
                                            dataIndex="bvn_verified_no"
                                            key="bvn_verified_no"
                                            render={(text, record) => (
                                                <span>
                                                    {formatNumber(parseInt(text))}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            title="GPS Mapped"
                                            dataIndex="farms_mapped_no"
                                            key="farms_mapped_no"
                                            render={(text, record) => (
                                                <span>
                                                    {formatNumber(parseInt(text))}
                                                </span>
                                            )}
                                        />
                                    </Table>
                                </div>
                            </div>
                            <div>

                                {this.state.dashLoading ?
                                    <div>
                                        <Spin />
                                    </div> :
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <h5>Crop Distribution</h5>
                                            <br />
                                            <div className="table-container">


                                                <Table
                                                    pagination={{ pageSize: 5 }}
                                                    loading={this.state.cropLoading}
                                                    bordered
                                                    rowKey={record => record.id}
                                                    dataSource={this.state.cropDistribution}
                                                >
                                                    <Column
                                                        title="Crop"
                                                        dataIndex="crop"
                                                        key="crop"
                                                        render={(text, record) => (
                                                            <span>
                                                                {text ? `${text}` : `-`}
                                                            </span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Total Pool"
                                                        dataIndex="total_no"
                                                        key="total_no"
                                                        render={(text, record) => (
                                                            <span>
                                                                {formatNumber(parseInt(text))}
                                                            </span>
                                                        )}
                                                    />
                                                    <Column

                                                        title={role === "Guest" ? `KYC Captured` : `BVN Verified`}
                                                        dataIndex="bvn_verified_no"
                                                        key="bvn_verified_no"
                                                        render={(text, record) => (
                                                            <span>
                                                                {formatNumber(parseInt(text))}
                                                            </span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="GPS Mapped"
                                                        dataIndex="farms_mapped_no"
                                                        key="farms_mapped_no"
                                                        render={(text, record) => (
                                                            <span>
                                                                {formatNumber(parseInt(text))}
                                                            </span>
                                                        )}
                                                    />
                                                </Table>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <h5>Gender Distribution</h5>
                                            <br />
                                            <PieChart width={400} height={300}>
                                                <Pie
                                                    data={this.state.genderDistribution}
                                                    cx={150}
                                                    cy={150}
                                                    innerRadius={80}
                                                    outerRadius={120}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    <Label value={`Total: ${formatNumber(this.calculateTotal())}`} position="center" />


                                                    {
                                                        this.state.genderDistribution.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                    }
                                                </Pie>
                                                <Tooltip content={<CustomTooltip />} />
                                                <Legend layout="vertical" verticalAlign="middle" align="right" />
                                            </PieChart>
                                        </Col>
                                    </Row>
                                }
                            </div>
                            {role !== "Guest" &&
                                <div style={{ margin: '50px 0' }}>
                                    <h5>Photos of the Day</h5>
                                    <br />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {this.state.images.map(image => {
                                            return (
                                                <Avatar key={image} shape="square" size={200} icon="user" src={image} />
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                            {role !== "Guest" &&
                                <div style={{ margin: '30px 0' }}>
                                    <h5>Association Distribution</h5>
                                    <br />
                                    <div className="table-container">
                                        <Table
                                            loading={this.state.assocLoading}
                                            bordered
                                            rowKey={record => `${record.home_state}${record.client_name}`}
                                            dataSource={this.state.associations}
                                        >
                                            <Column
                                                title="State"
                                                dataIndex="home_state"
                                                key="home_state"
                                            />
                                            <Column
                                                title="Association"
                                                dataIndex="client_name"
                                                key="client_name"
                                            />
                                            <Column
                                                title="Total Pool"
                                                dataIndex="total_pool"
                                                key="total_pool"
                                                render={(text, record) => (
                                                    <span>
                                                        {formatNumber(parseInt(text))}
                                                    </span>
                                                )}
                                            />
                                            <Column
                                                title="GPS Mapped"
                                                dataIndex="gps_mapped"
                                                key="gps_mapped"
                                                render={(text, record) => (
                                                    <span>
                                                        {formatNumber(parseInt(text))}
                                                    </span>
                                                )}
                                            />
                                            <Column
                                                title="Input Supplied"
                                                dataIndex="input_supplied"
                                                key="input_supplied"
                                                render={(text, record) => (
                                                    <span>
                                                        {text && formatNumber(parseInt(text))}
                                                    </span>
                                                )}
                                            />
                                            <Column
                                                title="% Completed"
                                                // dataIndex="showed_up"
                                                key=""
                                                render={(text, record) => (
                                                    <span>
                                                        {
                                                            record.total_pool ? (parseInt(record.gps_mapped) / (parseInt(record.total_pool)) * 100).toFixed(1) : null
                                                        }
                                                    </span>
                                                )}
                                            />
                                        </Table>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        );
    }
}


export default withStyles(styles, { withTheme: true })(MetaDash);
