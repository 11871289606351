import React from 'react'
import { observer, inject } from 'mobx-react'
// import Dvalco2 from './Dvalco2'
import Dashboard from './Dashboard'
import MappingInsights from './MappingInsights';
import MaybeResults from '../input/MaybeResults';
import ReviewFarms from '../review/ReviewFarms';
import ProgrammeIndex from './ProgrammeIndex'


@inject('userStore')
@observer
class DashboardIndex extends React.Component {
    render() {
        const { role } = this.props.userStore.role
        if (role === "Dvalco Admin" || role === "Service Provider" || role === "Guest") {
            return <ProgrammeIndex />
        }
        else if (role === "Admin") {
            // return <MapDashboard />
            return <MappingInsights />
        }
        else if (role === "Remote Sensing Engineers") {
            return <MaybeResults />
        }
        else if (role === "Remote Sensing Engineer") {
            return <ReviewFarms />
        }
        else {
            return <Dashboard />
        }
    }
}

export default DashboardIndex