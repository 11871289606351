import React from 'react'
import PropTypes from 'prop-types';

import { Layout, Form, Icon, Button, Input, Typography } from 'antd';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import config from '../../config/index'
import CustomHeader from './Header'

const { Title } = Typography;

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    button: {
        // background: '#2A3F54',
        width: '100%',
        color: '#fff',
        // '&:hover': {
        //     // background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     // background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class Forgot extends React.Component {

    state = {
        email: '',
        loading: false,
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const mailTest = /\S+@\S+\.\S+/
        if (mailTest.test(this.state.email) === false) {
            return this.props.userStore.openNotificationWithIcon('error', 'Invalid Email Address')
        }
        if (!this.state.email) {
            return this.props.userStore.openNotificationWithIcon('error', 'Email Address required')
        }
        const data = {
            email: this.state.email,
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/account/reset`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json"
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props
        return (
            <Layout className="main-auth">
                <CustomHeader />
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <Title style={{ color: '#2A3F54', textAlign: 'center' }} level={4}>Forgot Password</Title>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item>
                                <Input onChange={(e) => this.setState({ email: e.target.value })} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item>
                                <Link to="/">
                                    <p className="login-form-forgot" href="/">Remembered your password? try logging in again</p>
                                </Link>
                                <Button loading={this.state.loading} type="primary" htmlType="submit" className={classes.button}>
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Paper>
                </main>
                {/* <Content style={{ padding: '0 50px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>Content</div>
                </Content> */}
            </Layout>
        )
    }
}

Forgot.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Forgot);