import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Icon, Tooltip, Modal, Form, Select, Breadcrumb, Input, Tag, Spin } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import Map from './FarmMap'

const { Title, Text } = Typography;
const { Column } = Table
const { Option } = Select


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    }
});


@inject('userStore')
@observer
class FarmLots extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        pageUrl: 0,
        // selectedFarmLots: [],
        sub_clusters: [],
        sub_cluster: null,
        visible: false,
        mapVisible: false,
        fetchingFarmPoints: false,
        farmTitle: "",
        message: "",
        farmPoints: [],
        states: [],
        lgas: [],
        clients: [],
        selectedFarmLotIds: [],
        selectedPoints: [],
        activeFilters: { state: "", lga: "", mapped: "", perPage: "", client_name: "", sub_cluster: "" },
        stateAndLGA: [],
        lgaFilter: [],
        clientFilter: [],
        fetching: false
    }

    componentDidMount() {
        this.fetchContent()
    }

    async fetchContent() {
        const { tenant } = this.props.userStore


        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''

        axios.all([
            await axios({
                url: `${config.baseUrl}/farms`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/farmers/client_name/all`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            await axios({
                url: `${config.baseUrl}/subclusters`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3, res4) => {

            let clients = []
            clients = res3 && res3.data.entity.map(client => {
                const newObj = {
                    text: client.client_name,
                    value: client.client_name
                }
                return newObj
            })
            const states = res2.data.entity.states.map(state => {
                const newObj = {
                    text: state.name,
                    value: state.state_id
                }
                return newObj
            })
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res1.data.iTotalRecords)
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                data: res1.data.aaData,
                sub_clusters: res4.data.aaData || [],
                states,
                clients,
                stateAndLGA: res2.data.entity.states,
                clientFilter: res3.data.entity,
                pagination,
                isLoading: false,
            })
        }));
    }

    showModal = () => {
        if (this.state.selectedFarmLotIds.length === 0) {
            return this.props.userStore.openNotificationWithIcon('info', "Please select one or more rows")
        }
        this.setState({
            visible: true,
        });
    };

    getFarmPoints = async (farm) => {
        this.setState({
            mapVisible: true,
            message: farm.points ? "" : "No Farm Points",
            fetchingFarmPoints: true,
            farmTitle: farm.farm_id,
            selectedPoints: farm.points
        })
    }



    handleMapOkay = () => {
        this.setState({
            mapVisible: false,
        });
    };

    handleMapCancel = () => {
        this.setState({
            mapVisible: false,
        });
    };

    handleOk = () => {
        if (!this.state.sub_cluster) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select sub cluster')
        }
        this.bulkAssign()
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res.data.iTotalRecords)
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    setLGAs = async (id) => {
        if (!id) {
            this.setState({ lgaFilter: [] })
            return
        }
        const state = this.state.stateAndLGA.find(item => item.state_id === id)
        this.setState({ lgaFilter: state.lgas })
    }


    checkFilter = (pagination) => {
        //set page if pagination
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }
        //construct URL
        let mapped = this.state.activeFilters.mapped
        let state = this.state.activeFilters.state
        let lga = this.state.activeFilters.lga
        let client_name = this.state.activeFilters.client_name
        let url =
            `${config.baseUrl}/farms?${page === 0 ? '' : `page=${page}`}&per_page=${pageSize}${state && `&state=${state}`}${lga && `&lga=${lga}`}${client_name && `&client_name=${client_name}`}${mapped && `&mapped=${mapped}`}`;
        this.filter(url)
    }


    async bulkAssign() {
        const farmer_data = this.state.selectedFarmLotIds.join()
        const data = {
            farmer_data,
            subcluster: this.state.sub_cluster
        }
        if (!this.state.sub_cluster) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select sub cluster')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/farm/assign/bulk`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            console.log(res)
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully Assigned Farms")
            this.fetchContent()

        }).catch((err) => {
            console.log(err.response)
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSearch = async (query) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/farms?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res.data.iTotalRecords)
            pagination.current = parseInt(res.data.current_page + 1)//sets pagination page to 1
            await this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    onSearch = async (query) => {
        this.setState({ fetching: true })
        await axios({
            url: `${config.baseUrl}/subclusters?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            console.log(res)
            this.setState({
                sub_clusters: res.data.aaData,
                fetching: false
            })
        }).catch(err => {
            this.setState({ fetching: false })
        })
    }

    render() {
        const { classes } = this.props;
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV
        const rowSelection = {
            onChange: async (selectedRowKeys, selectedRows) => {
                await this.setState({ selectedFarmLotIds: selectedRowKeys })
            }
        }
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Manage Farms
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Farm Units</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {this.props.userStore.role.role === "Project Manager" || this.props.userStore.role.role === "Admin" ? null :
                            <div>
                                <Link to="/farms/add">
                                    <Button type="primary" style={{ background: '#009688' }} className={classes.button}>
                                        Add New Unit
                                    </Button>
                                </Link>
                                <Button onClick={() => this.showModal()} type="primary" className={classes.button} style={{ background: '#009688' }}>
                                    Bulk Assign
                                </Button>
                            </div>
                        }
                        <div>
                            <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className='table-container' style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>All Farms</Title>
                        {this.state.selectedFarmLotIds.length ?
                            <div style={{ padding: 5 }}>
                                <Text>Records Selected: {this.state.selectedFarmLotIds.length}</Text>
                            </div>

                            : null}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20, background: '#009688', padding: '20px' }}>
                            <Select
                                style={{ width: '20%' }}
                                defaultValue="Filter Mapped Farms"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, mapped: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Farm Mapped</Option>
                                <Option value="0">Farm Not Mapped</Option>
                            </Select>
                            <Select
                                style={{ width: '20%' }}
                                defaultValue="State/Region"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, state: value, lga: "" }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                    this.setLGAs(value)
                                }}
                            >
                                <Option value="">All</Option>

                                {this.state.stateAndLGA.map(item => (
                                    <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                ))}

                            </Select>
                            <Select
                                style={{ width: '20%' }}
                                defaultValue="LGA/District"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, lga: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                {this.state.lgaFilter.map(item => (
                                    <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                ))}
                            </Select>
                            <Select
                                style={{ width: '20%' }}
                                defaultValue="Filter Client Name"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, client_name: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}>
                                <Option value="">All</Option>
                                {this.state.clientFilter.filter(item => item.client_name).map((item, i) => {
                                    return (
                                        <Option key={i} value={item.client_name}>{item.client_name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <Table
                            bordered
                            rowSelection={rowSelection}
                            rowKey={record => record.farm_id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>

                            {env !== "staging" &&
                                <Column
                                    title="Unit ID"
                                    dataIndex="farm_id"
                                    key="farm_id"
                                />
                            }
                            <Column
                                title="Farmer"
                                dataIndex="farmer"
                                key="farmer"
                                render={(text, record) => (
                                    <span>
                                        {text ? `${text.first_name} ${text.last_name}` : ``}
                                    </span>
                                )}
                            />
                            <Column
                                title="Crop"
                                dataIndex="crop.crop_name"
                                key="crop.crop_name"
                            />
                            <Column
                                title="State/Region"
                                dataIndex="state.name"
                                key="state.name"
                            />
                            <Column
                                title="LGA/District"
                                dataIndex="community.local_name"
                                key="community.local_name"
                            />
                            {env === "staging" &&
                                <Column
                                    title="Address"
                                    dataIndex="address"
                                    key="address"
                                />
                            }

                            {env !== "staging" &&
                                <Column
                                    title="Mapped"
                                    dataIndex="points"
                                    key="points"
                                    render={(text, record) => (
                                        <span>
                                            {text ? <Tag color="green">Mapped</Tag> : <Tag color="red">Not Mapped</Tag>}
                                        </span>
                                    )}
                                />
                            }

                            {this.props.userStore.role.role !== "Aggregator" &&
                                <Column
                                    title={env === "staging" ? "Association" : "Client Name"}
                                    dataIndex="farmer.client_name"
                                    key="farmer.client_name"
                                    render={(text, record) => (
                                        <Tooltip title={text}>
                                            <span>
                                                {text ? text.substring(0, 10) + '...' : ''}
                                            </span>
                                        </Tooltip>

                                    )}
                                />
                            }

                            {env !== "staging" &&
                                <Column
                                    title="Sub Cluster"
                                    dataIndex="sub_cluster.name"
                                    key="sub_cluster.name"
                                />
                            }
                            <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        {env === "staging" ?
                                            <>
                                                <Tooltip title="View Farm">
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/${text._id}`, text._id)}>
                                                        <Icon type="eye" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="View Farmer">
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farmers/${text.farmer.farmer_id}`, text.farmer.farmer_id)}>
                                                        <Icon type="heat-map" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                            :
                                            <>
                                                <Tooltip title="View Details">
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/${text._id}`, text._id)}>
                                                        <Icon type="eye" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="View Map">
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.getFarmPoints(record)}>
                                                        <Icon type="heat-map" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Assign Farm">
                                                    <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/${text.farm_id}/assign`, text)}>
                                                        <Icon type="setting" style={{ color: '#2A3F54' }} />
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        }
                                    </span>
                                )}
                            />
                        </Table>
                        <Modal
                            title="Bulk Assign Sub Clusters"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            confirmLoading={this.state.loading}
                            okText={"Submit"}
                            onCancel={this.handleCancel}
                        >
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Sub Cluster">

                                    <Select
                                        showSearch
                                        onSearch={this.onSearch}
                                        filterOption={false}
                                        notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                        defaultValue="Select Sub Cluster"
                                        onChange={(value) => this.setState({ sub_cluster: value })}>
                                        {this.state.sub_clusters.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Form>
                        </Modal>
                        <Modal
                            footer={null}
                            title={this.state.farmTitle}
                            visible={this.state.mapVisible}
                            onOk={this.handleMapOkay}
                            width={600}
                            okText={"View"}
                            cancelText={"Close"}
                            onCancel={this.handleMapCancel}
                        >
                            {this.state.selectedPoints && this.state.selectedPoints.length ?
                                <Map
                                    points={this.state.selectedPoints}
                                // lng={this.state.farmPoints[0].lng}
                                // lat={this.state.farmPoints[0].lat}
                                />
                                :
                                <p>{this.state.message}</p>}
                        </Modal>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(FarmLots);
