import React from 'react'
import PropTypes from 'prop-types';
import { Layout, Form, Icon, Button, Input, Typography, Spin } from 'antd';
import { inject, observer } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios'
import config from '../../config/index'
import CustomHeader from './Header'
import { AuthContext } from '../../context/AuthContext';

const { Title } = Typography;

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    button: {
        // background: '#2A3F54',
        width: '100%',
        color: '#fff',
        // '&:hover': {
        //     // background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     // background: '#1d2c3a',
        // },
    }
});

const logo =
    process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" ?
        "https://test.tradebuza.com/build/images/hazie.png"
        :
        " https://test.tradebuza.com/build/images/t_logo.png"

@inject('userStore')
@observer
class Login extends React.Component {

    static contextType = AuthContext

    state = {
        fetching: true,
        email: '',
        password: '',
        loading: false,
        logo
    }

    componentDidMount() {
        this.checkHost()
    }

    checkHost = () => {
        if (window.location.hostname.includes("tradebuza.com")) {
            this.setState({ fetching: false })
        } else {
            this.fetchDomain()
        }
    }

    fetchDomain = async () => {
        await axios({
            url: `${config.baseUrl}/tenant/domain/${window.location.hostname}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        }).then((res) => {
            if (res.data.status.code !== 100 || !res.data.entity.logo) {
                this.setState({ fetching: false })
            }
            else {
                this.props.userStore.setTenant(res.data.entity)
                this.setState({ logo: res.data.entity.logo, fetching: false })
            }

        }).catch(err => {
            this.setState({ fetching: false })
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const mailTest = /\S+@\S+\.\S+/
        if (mailTest.test(this.state.email) === false) {
            return this.props.userStore.openNotificationWithIcon('error', 'Invalid Email Address')
        }
        if (!this.state.email || !this.state.password) {
            return this.props.userStore.openNotificationWithIcon('error', 'Email Address / Password required')
        }
        const data = {
            email: this.state.email,
            password: this.state.password
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/account/login`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json"
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.context.setUser(res.data.entity, this.props.history)
            this.props.userStore.openNotificationWithIcon('success', "Login Successful")
            // this.props.history.push('/dashboard')
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props
        const { tenant } = this.props.userStore
        if (this.state.fetching) {
            return (
                <div className={classes.paper}>
                    <Spin />
                </div>
            )
        }
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV
        return (
            <Layout className="main-auth">
                <Helmet>
                    <title>
                        {tenant && tenant.name ? tenant.name : env === "staging" ? "Hazie" : "Tradebuza"}
                    </title>
                </Helmet>
                <CustomHeader logo={this.state.logo} />
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <Title style={{ color: '#2A3F54', textAlign: 'center' }} level={4}>Log in</Title>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item>
                                <Input onChange={(e) => this.setState({ email: e.target.value })} prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item>
                                <Input onChange={(e) => this.setState({ password: e.target.value })} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                            </Form.Item>
                            <Form.Item>
                                <Link to="/forgot">
                                    <p className="login-form-forgot">Forgot Password?</p>
                                </Link>
                                <Button loading={this.state.loading} type="primary" htmlType="submit" className={classes.button}>
                                    {this.state.loading ? "" : "Log in"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Paper>
                </main>
                {/* <Content style={{ padding: '0 50px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>Content</div>
                </Content> */}
            </Layout>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);