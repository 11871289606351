import React from 'react'

import { Layout } from 'antd';

const { Header } = Layout;

const CustomHeader = (props) => {
    return (
        <Header className="header">
            <div>
                <img src={props.logo} alt="session-logo" className="img-fluid" width="165" height="52.5" />
            </div>
        </Header>
    )
}

export default CustomHeader