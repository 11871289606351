import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Breadcrumb, Row, Col
} from 'antd';
import config from '../../config'

const Option = Select.Option;
const { Title } = Typography;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
    }

});

@inject('userStore')
@observer
class EditUser extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        rolesData: [],
        tenantData: [],
        bvn: '',
        ...this.props.location.state,
        roleId: this.props.location.state.role,
        selectedStateId: this.props.location.state.state ? this.props.location.state.state.state_id : null,
        selectedTenant: null,
        states: [],
        lgas: [],
        seasons: [],
        associations: []
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''

        axios.all([
            axios({
                url: `${config.baseUrl}/role`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/tenants`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                const code = err.response.data.status.code
                if (code === 110) {
                    return
                }
                else {
                    this.props.userStore.openNotificationWithIcon('error', err.message)
                }
            }),
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/project_names`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch((err) => {
                return this.props.userStore.openNotificationWithIcon('error', err.message)
            })
        ]).then(axios.spread((res1, res2, res3, res4) => {
            this.setState({
                isFetching: false,
                rolesData: res1.data.entity,
                tenantData: res2 ? res2.data.entity.tenants : [],
                states: res3.data.entity.states,
                seasons: res4.data.entity
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        let data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            role: this.state.roleId,
            tenant: this.state.selectedTenant,
            bvn: this.state.bvn,

        }
        if (this.state.role && this.state.role.role === "Agent") {
            data = { ...data, state: this.state.selectedStateId, association: this.state.association, season: this.state.season }
        }
        if (!this.state.first_name || !this.state.last_name) {
            return this.props.userStore.openNotificationWithIcon('error', 'Firstname and Lastname required')
        }
        if (!this.state.mobile) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a mobile number')
        }
        if ((this.state.role && this.state.role.role === "Agent") && !this.state.association) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply an association')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/account/${this.props.location.state._id}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully edited User")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })

    }

    getAssociations = async (state) => {
        axios({
            url: `${config.baseUrl}/farmers/client_name?state=${state}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ associations: res.data.entity })
        }).catch(err => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        const disableButton = this.props.userStore.role.role === "Project Manager"

        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/users">User Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Edit User
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Edit User</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="First Name">
                                    <Input value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Last Name">
                                    <Input value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Phone Number">
                                    <Input value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Email">
                                    <Input value={this.state.email} type="email" disabled />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="User Group">
                                    <Select
                                        value={this.state.roleId ? this.state.roleId.role : ""}
                                        onChange={(value) => this.setState({ roleId: this.state.rolesData.find(item => item._id === value) })}>
                                        <Option disabled value="">Select Role</Option>
                                        {this.state.rolesData.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.role}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>

                                {(this.state.role && this.state.role.role === "Agent") &&
                                    <>
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Home State">
                                                    <Select
                                                        value={this.state.selectedStateId ? this.state.selectedStateId : ""}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                selectedStateId: value,
                                                                association: ""
                                                            }, () => {
                                                                this.getAssociations(value)
                                                            });
                                                        }}>
                                                        <Option disabled value="">Select State</Option>
                                                        {this.state.states.map((item) => {
                                                            return (
                                                                <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Season">
                                                    <Select
                                                        value={this.state.season ? this.state.season : ""}
                                                        onChange={(value) => this.setState({ season: value })}>
                                                        <Option disabled value="">Select Season</Option>
                                                        {this.state.seasons.map((item) => {
                                                            return (
                                                                <Option key={item.season} value={item.season}>{item.season}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Association">
                                                    <Select
                                                        value={this.state.association ? this.state.association : ""}
                                                        onChange={(value) => this.setState({ association: value })}>
                                                        <Option disabled value="">Select Association</Option>
                                                        {this.state.associations.map((item, i) => {
                                                            return (
                                                                <Option key={i} value={item.client_name}>{item.client_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </>

                                }
                                {this.props.userStore.role.role !== "Admin" ? null :
                                    <Form.Item
                                        colon={false}
                                        label="Select Tenant">
                                        <Select defaultValue={this.state.tenant ? this.state.tenant._id : "Select Tenant"} onChange={(value) => this.setState({ selectedTenant: value })}>
                                            {this.state.tenantData.filter(item => item.role !== "Admin").map((item) => {
                                                return (
                                                    <Option key={item._id} value={item._id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                }
                                <Title style={{ color: '#2A3F54' }} level={4}>Other Information</Title>
                                <Form.Item
                                    value={this.state.bvn}
                                    style={{ margin: 0 }}
                                    label="BVN">
                                    <Input onChange={(e) => this.setState({ bvn: e.target.value })} />
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button disabled={disableButton} className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(EditUser);
