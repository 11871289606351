import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Breadcrumb
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const Option = Select.Option;
const { Title } = Typography;
const { TextArea } = Input;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class NewCluster extends React.Component {

    static contextType = AuthContext
    state = {
        name: "",
        managers: [],
        manager: null,
        isFetching: true,
        description: "",
        loading: false
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent = async () => {
        await axios({
            url: `${config.baseUrl}/cluster/managers`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ managers: res.data.entity })

        }).catch((err) => {
            this.setState({ isFetching: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            desc: this.state.description,
            manager: this.state.manager
        }
        if (!this.state.name || !this.state.description) {
            return this.props.userStore.openNotificationWithIcon('error', 'Name and Description required')
        }
        if (!this.state.manager) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select a manager')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/cluster`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully Created Cluster")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/farms/clusters">Manage Clusters</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Cluster
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div> :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Add New Cluster</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Cluster Name">
                                    <Input onChange={(e) => this.setState({ name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    label="Project Manager">
                                    <Select defaultValue="Select Manager" onChange={(value) => this.setState({ manager: value })}>
                                        {this.state.managers.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: 0 }}
                                    label="Cluster Description">
                                    <TextArea onChange={(e) => this.setState({ description: e.target.value })} />
                                </Form.Item>
                                <Form.Item>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(NewCluster);
