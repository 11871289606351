import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Breadcrumb, Input, Modal, Select, Form, Icon, Popconfirm } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import moment from 'moment'
import config from '../../config'
const { Title } = Typography;
const { Column } = Table
const { Option } = Select;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
});


@inject('userStore')
@observer
class InputManagement extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,
        pageUrl: 0,
        visible: false,
        associations: [],
        association: '',
        expected_qty: '',
        input_type: ''
    }

    componentDidMount() {
        this.fetchContent()
    }


    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/input_types`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/util/associations/get`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res1.data.entity.total_records
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.entity.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res1.data.entity.tenants,
                pagination,
                associations: res2.data.entity
            })

        }));
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    handleOk = () => {
        this.handleSubmit()
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.entity.total_records
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.entity.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.entity.tenants,
                pagination
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSearch = async (query) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/farmers?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.current = parseInt(res.data.current_page + 1)//sets pagination page to 1
            await this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    checkFilter = (pagination) => {
        //set page if pagination
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }
        //construct URL
        // let filter = this.state.activeFilters.filter
        // let state = this.state.activeFilters.state
        // let lga = this.state.activeFilters.lga
        // let season = this.state.activeFilters.project_name
        // let mapped = this.state.activeFilters.mapped
        // const isGuest = this.props.userStore.role.role === "Guest"
        let url =
            `${config.baseUrl}/input_types?${page === 0 ? '' : `page=${page}`}&per_page=${pageSize}`;

        this.filter(url)
    }

    handleSubmit = async () => {
        const { association, input_type, expected_qty } = this.state;
        const data = {
            association,
            input_type,
            expected_qty
        }
        this.setState({ busy: true })
        await axios({
            url: `${config.baseUrl}/input_type`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ busy: false, visible: false })
            if (res.data.status.code === 100) {
                this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
            }
            this.fetchContent()
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    confirmDelete = async (record) => {
        this.setState({ busy: true })
        await axios({
            url: `${config.baseUrl}/input_type/${record._id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ busy: false })
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
            this.fetchContent()

        }).catch(err => {
            this.setState({ busy: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Input Tracking
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Input</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Button type="primary" onClick={() => this.setState({ visible: true })} className={classes.button}>
                                Add Input Type
                            </Button>
                        </div>
                        <div>
                            {/* <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} /> */}
                        </div>
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                            {role !== "Guest" &&
                                <Select
                                    style={{ width: '15%' }}
                                    defaultValue="Filter Status"
                                    onChange={(value) => {
                                        this.setState({
                                            activeFilters: { ...this.state.activeFilters, filter: value }
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}
                                >
                                    <Option value="">All</Option>
                                    <Option value="1">Farm Added</Option>
                                    <Option value="2">No Farm</Option>
                                </Select>
                            }
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="Filter State"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, state: value, lga: "" }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                    this.setLGAs(value)
                                }}
                            >
                                <Option value="">All</Option>

                                {this.state.stateAndLGA.map(item => (
                                    <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                ))}

                            </Select>
                            <Select
                                style={{ width: '15%' }}
                                defaultValue="LGA"
                                onChange={(value) => {
                                    this.setState({
                                        activeFilters: { ...this.state.activeFilters, lga: value }
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                {this.state.lgaFilter.map(item => (
                                    <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                ))}
                            </Select>
                            {role === "Guest" &&
                                <Select
                                    style={{ width: '15%' }}
                                    defaultValue="Mapping Status"
                                    onChange={(value) => {
                                        this.setState({
                                            activeFilters: { ...this.state.activeFilters, mapped: value }
                                        }, () => {
                                            this.checkFilter();
                                        });
                                    }}>
                                    <Option value="">All</Option>
                                    <Option value="1">Mapped</Option>
                                    <Option value="0">Not Mapped</Option>
                                </Select>
                            }
                            {role !== "Guest" &&
                                <>
                                    <Select
                                        style={{ width: '15%' }}
                                        defaultValue="Filter Season"
                                        onChange={(value) => {
                                            this.setState({
                                                activeFilters: { ...this.state.activeFilters, project_name: value }
                                            }, () => {
                                                this.checkFilter();
                                            });
                                        }}>
                                        <Option value="">All</Option>
                                        {this.state.project_names.filter(item => item.season).map((item) => {
                                            return (
                                                <Option key={item.season} value={item.season}>{item.season}</Option>
                                            )
                                        })}
                                    </Select>
                                </>
                            }
                        </div> */}
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>

                            <Column
                                title="Input Type"
                                dataIndex="input_type"
                                key="input_type"
                            />
                            <Column
                                title="Association"
                                dataIndex="association"
                                key="association"
                            />
                            <Column
                                title="Expected Quantity"
                                dataIndex="expected_qty"
                                key="expected_qty"
                            />
                            <Column
                                title="Date Created"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LL, h:mm:A')}
                                    </span>
                                )}
                            />
                            <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Popconfirm title="Are you sure you want to delete this input type?" onConfirm={() => this.confirmDelete(record)} okText="Yes" cancelText="No">
                                            <Button loading={this.state.busy} style={{ marginLeft: 2 }} type="default">
                                                <Icon type="delete" style={{ color: '#ea1b2a' }} />
                                            </Button>
                                        </Popconfirm>
                                    </span>
                                )}
                            />
                        </Table>
                        <Modal
                            title={"Add Input Type"}
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    Cancel
                                    </Button>,
                                <Button type="primary" loading={this.state.busy} key="ok" onClick={this.handleOk}>
                                    Submit
                                </Button>,
                            ]}
                        >
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Name">
                                <Input placeholder="Name" onChange={(e) => this.setState({ input_type: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Association">
                                <Select
                                    style={{ width: '100%' }}
                                    defaultValue="Select Association"
                                    onChange={(value) => {
                                        this.setState({
                                            association: value.split("+")[0]
                                        })
                                    }}>
                                    {this.state.associations.map((item, i) => {
                                        return (
                                            <Option
                                                key={i}
                                                value={`${item.client_name}+${item.state_id}`}
                                            >
                                                {item.client_name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Expected Quantity">
                                <Input type="number" placeholder="Expected Quantity" onChange={(e) => this.setState({ expected_qty: e.target.value })} />
                            </Form.Item>
                        </Modal>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(InputManagement);
