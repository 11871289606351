import React, { useRef, useEffect } from 'react';
import { Tag } from 'antd'
import axios from 'axios';
import useSWR from 'swr';
import config from './index'


export const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value)
}

export const formatNumber = (value) => {
    return new Intl.NumberFormat().format(value)
}


export const renderPaymentStatus = (status) => {
    if (status === 0) {
        return (
            <Tag color="orange">Pending</Tag>
        )
    }
    else if (status === 1) {
        return (
            <Tag color="green">Approved</Tag>
        )
    }
    else {
        return (
            <Tag color="red">Declined</Tag>
        )
    }
}

export const renderProcessedPayments = (status) => {
    if (status === 0) {
        return (
            <Tag color="orange">Not Proccessed</Tag>
        )
    }
    else if (status === 1) {
        return (
            <Tag color="green">Processed</Tag>
        )
    }
    else {
        return (
            <Tag color="green">Processed</Tag>
        )
    }
}

export const renderCredit = (type) => {
    if (type === "credit") {
        return (
            <Tag color="green">Credit</Tag>
        )
    }
    else {
        return (
            <Tag color="red">Debit</Tag>
        )
    }
}

export const setExportUrl = (filters, fetchLGAs) => {
    let stateFilter = filters['home_state.name'];
    let lgaFilter = filters['home_lga.local_name'];
    let farmFilter = filters['farm_lot'];

    let stateOnly = stateFilter && stateFilter.length;
    let lgaOnly = lgaFilter && lgaFilter.length;
    let filterOnly = farmFilter && farmFilter.length;

    let url = `${config.baseUrl}/farmers/export`;
    if (!stateOnly && !lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers/export`;
        return url;
    };

    //State Only
    if (stateOnly && !lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers/export?state=${stateFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and LGA
    if (stateOnly && lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers/export?state=${stateFilter[0]}&lga=${lgaFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and Filter
    if (stateOnly && !lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers/export?state=${stateFilter[0]}&farm_lot=${farmFilter[0]}`;
        fetchLGAs(stateFilter[0]);
    };

    //LGA Only
    if (!stateOnly && lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers/export?lga=${lgaFilter[0]}`;
        return url;
    };
    //LGA and FIlter
    if (!stateOnly && lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers/export?lga=${lgaFilter[0]}&farm_lot=${farmFilter[0]}`;
        return url;
    };
    //Filter Only
    if (!stateOnly && !lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers/export?farm_lot=${farmFilter[0]}`;
        return url;
    };
    //All
    if (stateOnly && lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers/export?state=${stateFilter[0]}&lga=${lgaFilter[0]}&farm_lot=${farmFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    }
    return url;
}

export const setFarmerFilterUrl = (pageUrl, pagination, filters, fetchLGAs) => {
    let pageSize = pagination.pageSize;
    let stateFilter = filters['home_state.name'];
    let lgaFilter = filters['home_lga.local_name'];
    let farmFilter = filters['farm_lot'];
    let projectFilter = filters['project_name']

    let stateOnly = stateFilter && stateFilter.length;
    let lgaOnly = lgaFilter && lgaFilter.length;
    let filterOnly = farmFilter && farmFilter.length;
    let projectOnly = projectFilter !== ""


    let url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}`;

    if (!stateOnly && !lgaOnly && !filterOnly && !projectOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}`;
        return url;
    };

    if (projectOnly && !lgaOnly && !filterOnly && !stateOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&season=${projectFilter}`;
        return url;
    }

    //State Only
    if (stateOnly && !lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and LGA
    if (stateOnly && lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&lga=${lgaFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and Filter
    if (stateOnly && !lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&farm_lot=${farmFilter[0]}`;
        fetchLGAs(stateFilter[0]);
    };

    //LGA Only
    if (!stateOnly && lgaOnly && !filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&lga=${lgaFilter[0]}`;
        return url;
    };
    //LGA and FIlter
    if (!stateOnly && lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&lga=${lgaFilter[0]}&farm_lot=${farmFilter[0]}`;
        return url;
    };
    //Filter Only
    if (!stateOnly && !lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&farm_lot=${farmFilter[0]}`;
        return url;
    };
    //All
    if (stateOnly && lgaOnly && filterOnly) {
        url = `${config.baseUrl}/farmers?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&lga=${lgaFilter[0]}&farm_lot=${farmFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    }
    return url;
}

export const setFarmLotFilterUrl = (pageUrl, pagination, filters, fetchLGAs) => {
    let pageSize = pagination.pageSize;
    let stateFilter = filters['state.name'];
    let lgaFilter = filters['community.local_name'];
    let clientFilter = filters['farmer.client_name'];
    let mappedFilter = filters['points']

    let stateOnly = stateFilter && stateFilter.length;
    let lgaOnly = lgaFilter && lgaFilter.length;
    let clientOnly = clientFilter && clientFilter.length;
    let mappedOnly = mappedFilter && mappedFilter.length

    let url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}`;

    //nothing
    if (!stateOnly && !lgaOnly && !clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}`;
        return url;
    };

    //State Only
    if (stateOnly && !lgaOnly && !clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and LGA
    if (stateOnly && lgaOnly && !clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&lga=${lgaFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    };
    //State and Client Filter

    if (stateOnly && !lgaOnly && clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&client_name=${clientFilter[0]}`;
        fetchLGAs(stateFilter[0]);
    };
    //State and Mapped
    if (stateOnly && !lgaOnly && !clientOnly && mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&mapped=${mappedFilter[0]}`;
        fetchLGAs(stateFilter[0]);
    };

    //LGA Only
    if (!stateOnly && lgaOnly && !clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&lga=${lgaFilter[0]}`;
        return url;
    };
    //LGA and Filter
    if (!stateOnly && lgaOnly && clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&lga=${lgaFilter[0]}&client_name=${clientFilter[0]}`;
        return url;
    };
    //LGA and Mapped
    if (!stateOnly && lgaOnly && !clientOnly && mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&lga=${lgaFilter[0]}&mapped=${mappedFilter[0]}`;
        return url;
    };
    //Filter Only 
    if (!stateOnly && !lgaOnly && clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&client_name=${clientFilter[0]}`;
        return url;
    };
    //Mapped Only 
    if (!stateOnly && !lgaOnly && !clientOnly && mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&mapped=${mappedFilter[0]}`;
        return url;
    };

    //All
    if (stateOnly && lgaOnly && clientOnly && !mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&lga=${lgaFilter[0]}&client_name=${clientFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    }
    //All
    if (stateOnly && lgaOnly && clientOnly && mappedOnly) {
        url = `${config.baseUrl}/farms?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&state=${stateFilter[0]}&lga=${lgaFilter[0]}&client_name=${clientFilter[0]}&mapped=${mappedFilter[0]}`;
        fetchLGAs(stateFilter[0]);
        return url;
    }
    return url;
}

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
};

const fetcher = async (url, token) => {
    return await axios.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(res => res.data)
}



export const useStates = (path) => {
    const { data, error } = useSWR(path, fetcher)

    return {
        states: data ? data.entity.states : [],
        isLoadingStates: !error && !data,
        isError: error,
    }
}

export const useCrops = (path) => {
    const { data, error } = useSWR(path, fetcher)
    return {
        crops: data ? data.entity : [],
        isLoadingCrops: !error && !data,
        isError: error,
    }
}

export const useSeasons = (path, token) => {
    const { data, error } = useSWR([`${path}`, token], fetcher)
    return {
        seasons: data ? data.entity : [],
        isLoadingCrops: !error && !data,
        isError: error,
    }
}

export const useAssociations = (path, token) => {
    const { data, error } = useSWR([`${path}`, token], fetcher)
    return {
        associations: data ? data.entity : [],
        isLoadingCrops: !error && !data,
        isError: error,
    }
}

export const usePolygons = (path, token) => {
    const { data, error } = useSWR([`${path}`, token], fetcher)
    return {
        data: data ? data.entity : {},
        isLoading: !error && !data,
        isError: error,
    }
}

export const useFarmer = (path, token) => {
    const { data, error } = useSWR([`${path}`, token], fetcher)
    return {
        farmer: data ? data.entity : {},
        isLoading: !error && !data,
        isError: error,
    }
}