import React from 'react';

import { Modal } from 'antd';
import Map from '../farm/FarmMap';

function MapViewer({ points, mapVisible, handleMapCancel }) {
    return (
        <Modal
            footer={null}
            title="Polygon"
            visible={mapVisible}
            width={600}
            cancelText={"Close"}
            onCancel={handleMapCancel}
        >
            {points && points.length &&
                <Map
                    points={points}

                />
            }
        </Modal>
    )
}

export default MapViewer;