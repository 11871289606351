import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Table, Icon, Tooltip, Tag, Breadcrumb } from 'antd';
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import config from '../../config'

const { Title } = Typography;
const { Column } = Table;

// const columns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Description',
//     dataIndex: 'description',
//     key: 'description',
//   },
//   {
//     title: 'Tenant ID',
//     dataIndex: 'tenant_id',
//     key: 'tenant_id',
//   },
// ];


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        // '&:hover': {
        //   background: '#1d2c3a',
        // },
        // '&:focus': {
        //   outline: 0,
        //   background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class MessageHistory extends React.Component {


    state = {
        isLoading: true,
        data: []
    }

    componentDidMount() {
        this.getData()

    }

    getData = async () => {
        await axios({
            url: `${config.baseUrl}/sms/history`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isLoading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({ data: res.data.entity.reverse() })

        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Communication
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Sent Messages
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Sent Messages</Title>
                    </div>
                    <div>
                        <Link to="/communication/new_sms">
                            <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                                Send New Message
                            </Button>
                        </Link>
                    </div>
                    <div className='table-container' style={{ padding: 24, background: '#fff' }}> 
                        <Title style={{ color: '#2A3F54' }} level={4}>All Messages</Title>
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            rowKey={record => record._id}
                            // onRow={(record, rowIndex) => {
                            //   return {
                            //     onClick: () => { console.log(record) },       // click row
                            //   };
                            // }}
                            dataSource={this.state.data}>
                            <Column
                                title="Batch ID"
                                dataIndex="_id"
                                key="_id"

                            />
                            <Column
                                title="Date Sent"
                                dataIndex="created"
                                key="created"
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text) => (
                                    <span>
                                        {text === 0

                                            ?
                                            <Tag color="red">Not Sent</Tag>
                                            :
                                            <Tag color="green">Sent</Tag>
                                        }
                                    </span>
                                )}
                            />
                            <Column
                                title="Total SMS sent"
                                dataIndex="total"
                                key="total"
                            />
                            <Column
                                title="Successful"
                                dataIndex="delivered"
                                key="delivered"
                            />

                            <Column
                                title="Failed"
                                dataIndex="undelivered"
                                key="undelivered"
                            />
                            <Column
                                title="Cost"
                                dataIndex="cost"
                                key="cost"
                                render={(text) => (
                                    <span>
                                        {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title="Action"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Tooltip title="Download">
                                            <Button style={{ marginLeft: 2 }} type="default">
                                                <Icon type="download" style={{ color: 'green' }} />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(MessageHistory);



