import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Form, Input, Collapse, Icon, Checkbox, Col, Button, Breadcrumb } from 'antd'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import Drawer from '../dashboard/Drawer'

const { TextArea } = Input
const { Title } = Typography
const Panel = Collapse.Panel;

// const customPanelStyle = {
//     background: '#f7f7f7',
//     borderRadius: 4,
//     marginBottom: 24,
//     border: 0,
//     overflow: 'hidden',
// };


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },

    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});


@inject('userStore')
@observer
class AddGroup extends React.Component {
    state = {
        disabled: false,
        role: '',
        desc: '',
        permissions: []
    }



    handlePermisions = (val) => {
        if (this.state.permissions.includes(val)) {
            this.setState((prevState) => {
                return {
                    permissions: prevState.permissions.filter(item => item !== val)
                }
            })
        }
        else {
            this.setState((prevState) => {
                return {
                    permissions: [...prevState.permissions, val]
                }
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            role: this.state.role,
            desc: this.state.desc
        }

        if (!this.state.role) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply aName')
        }
        if (!this.state.desc) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply Description')
        }
        if (this.state.permissions.length === 0) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select at least one group permission')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/role`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.handleSendPermissions(res.data.entity)
        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSendPermissions = (role) => {
        const data = {
            permissions: this.state.permissions
        }
        axios({
            url: `${config.baseUrl}/role/${role.id}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', 'Successfully created role')
            this.props.history.goBack()
        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }




    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/groups">Role Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Role
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Add New Role</Title>
                        <Form onSubmit={this.handleSubmit} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>Role Name</span>}>
                                <Input onChange={(e) => this.setState({ role: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Description">
                                <TextArea onChange={(e) => this.setState({ desc: e.target.value })} />
                            </Form.Item>
                            <br />
                            <Title style={{ color: '#2A3F54' }} level={4}>Role Permissions</Title>
                            <Collapse
                                accordion
                                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                            >
                                <Panel header="Dashboard" key="1">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="DASHBOARD_VIEW">Ability to View Dashboard</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="User Management Module" key="2">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="GROUP_MANAGE">Ability to Manage Groups</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="USER_MANAGE">Ability to Manage Users</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="MANAGERS_MANAGE">Ability to Manage Project Managers</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="FARMERS_MANAGE">Ability to Manage Farmers</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="Farm Management" key="3">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="FARM_MANAGE">Ability to Manage Farms</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="CLUSTER_MANAGE">Ability to Manage Clusters</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="SUB_CLUSTER_MANAGE">Ability to Manage Sub Clusters</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="TENANT_MANAGE">Ability to Manage Tenants</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="M&E" key="4">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="FARM_ACTIVITIES">Ability to Manage Activities</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="FARM_ACTIVITIES_DASHBOARD">Ability to View Activities Dashboard</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="FARM_ACTIVITIES_VIEW">Ability to View Activities Table</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="Transactions" key="5">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="TRANSACTIONS_MANAGE">Ability to Manage Transactions</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="Communication Module" key="6">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="COMMUNICATION_MANAGE">Ability to Use Communication Module</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                                <Panel header="Configuration Module" key="7">
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="CONFIG_CROP_PROFILE">Ability to Configure Crop Profile</Checkbox></Col>
                                    </Form.Item>
                                    <Form.Item>
                                        <Col span={24}><Checkbox onChange={(e) => this.handlePermisions(e.target.value)} value="CONFIG_FARM_ACTIVITIES">Ability to Configure Activities</Checkbox></Col>
                                    </Form.Item>
                                </Panel>
                            </Collapse>
                            <Form.Item>
                                <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddGroup);


// "entity": {
//         "role": null,
//         "CROPS_ADD": null,
//         "SEASON_CONFIGURE": null,
//         "CONFIG_SEASON_MANAGE": null,
//         "_id": null
//     }