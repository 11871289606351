import React from 'react';
import { Upload, Icon, message, Modal, Button } from 'antd';
import config from '../../config'


const { Dragger } = Upload;

function Uploader({ uploadUrl, token, refetch, downloadUrl, visible, handleCancel }) {
    const uploadProps = {
        name: 'file',
        multiple: false,
        action: `${config.uploadBaseUrl}/${uploadUrl}`,
        headers: { "Authorization": `Bearer ${token}` },
        accept: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        onChange(info) {
            const { status } = info.file;
            if (status === 'uploading') {

            }
            else if (status === 'done') {
                console.log(info)
                handleCancel()
                message.success(`${info.file.name} file uploaded successfully.`);
                refetch()
            } else if (status === 'error') {
                console.log(info)
                handleCancel()
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }

    const downloadFile = (url) => {
        console.log(url)
    }

    return (
        <Modal
            title="Upload Excel File (.xls or .xlsx)"
            visible={visible}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="download" onClick={() => downloadFile(downloadUrl)}>
                    Download Template
                </Button>,
            ]}
        >
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>

            <br />
        </Modal>
    )

}

export default Uploader;