import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Polygon,
    Marker
} from "react-google-maps";
import config from "../../config";

const reversed = (points) => {
    const formattedPoints = points.map(point => {
        return { lat: parseFloat(point.lat), lng: parseFloat(point.lng) }
    })
    return formattedPoints;
}


const CustomSkinMap = withScriptjs(
    withGoogleMap(props => (
        <GoogleMap
            defaultZoom={20}
            mapTypeId="satellite"
            center={{ lat: parseFloat(props.points[0].lat), lng: parseFloat(props.points[0].lng) }}
        >

            {props.points.map((point, i) => (
                <Marker
                    key={i}
                    position={{ lat: parseFloat(point.lat), lng: parseFloat(point.lng) }} />
            ))}
            <Polygon
                path={reversed(props.points)}
                //key={1}
                options={{
                    fillColor: "#009688",
                    fillOpacity: 0.4,
                    strokeColor: "#000",
                    strokeOpacity: 1,
                    strokeWeight: 1
                }} />
        </GoogleMap>
    ))
);

function Maps({ ...props }) {
    return (
        <CustomSkinMap
            points={props.points}
            // lat={props.lat}
            // lng={props.lng}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.mapKey}`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: props.height ? props.height : `60vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
        />
    );
}

export default Maps;