import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Table, Tooltip, Icon, Breadcrumb } from 'antd';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});

@inject('userStore')
@observer
class ClusterDetails extends React.Component {

    state = {
        isLoading: true,
        data: []
    }

    componentDidMount() {
        this.getData()

    }

    getData = async () => {
        await axios({
            url: `${config.baseUrl}/cluster/subclusters/123${this.props.location.state._id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {

            this.setState({ isLoading: false })
            if (res.data.aaData) {
                this.setState({ data: res.data.aaData })
            }
            else {
                if (res.data.status.code && res.data.status.code !== 100) {
                    return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
                }
                else {
                    this.setState({ data: res.data.entity })
                }

            }


        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/farms/clusters">Manage Clusters</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Sub Clusters
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>{this.props.location.state.cluster_id} Sub Clusters</Title>
                    </div>
                    {/* <div>
                        <Link to="/farms/sub-clusters/add">
                            <Button type="primary" className={classes.button}>
                                Add New Sub Cluster
                            </Button>
                        </Link>
                    </div> */}
                    <div>
                        {/* <Title style={{ color: '#2A3F54' }} level={4}>All Clusters</Title> */}
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            rowKey={record => record._id}
                            dataSource={this.state.data}>
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                            />
                            <Column
                                title="Description"
                                dataIndex="description"
                                key="description"
                            />
                            <Column
                                title="ID"
                                dataIndex="sub_cluster_id"
                                key="sub_cluster_id"
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                            />
                            <Column
                                title="Agent"
                                dataIndex="agent"
                                key="agent"
                                render={(text, record) => (
                                    <span>
                                        {text.first_name} {text.last_name}
                                    </span>
                                )}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(text) => (
                                    <span>
                                        <Tooltip title="View Sub Cluster Details">
                                            <Button type="default" onClick={() => this.props.history.push(`/farms/sub-clusters/${text._id}`, text)} style={{ marginRight: 2 }}>
                                                <Icon type="eye" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(ClusterDetails);
