import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb, Row, Col, Spin, Select, DatePicker } from 'antd';
import moment from 'moment';
import Drawer from './Drawer'
import { useStates, useCrops, usePolygons, useSeasons, useAssociations, formatNumber } from '../../config/utils';
import MapView from './PolygonMapView';
import config from '../../config'

const { Option } = Select;
const { RangePicker } = DatePicker;

const user = localStorage.getItem('user-auth')
const parsedUser = JSON.parse(user)

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

function Polygons(props) {
    // const [loading, setLoading] = useState(true)
    const [state, changeState] = useState(null)
    const [crop, setCrop] = useState(null)
    const [season, setSeason] = useState(null)
    const [association, setAssociation] = useState(null)
    const [date, setDate] = useState([])
    const { states, } = useStates(`${config.baseUrl}/states`)
    const { crops, } = useCrops(`${config.opeIssue}/fetchCrops/dvc`)
    const { seasons, } = useSeasons(`${config.baseUrl}/project_names`, parsedUser.token)
    const { associations } = useAssociations(state ? `${config.baseUrl}/farmers/client_name?state=${state}` : "https://test.tradebuza.com/api/v2/farmers/client_name/all", parsedUser.token)

    const { classes } = props;

    const filterState = state ? `&state=${state}` : ''
    const filterCrop = crop ? `&crop=${crop}` : ''
    const filterSeason = season ? `&season=${season}` : '';
    const filterAssoc = association ? `&association=${association}` : '';
    const filterDateFrom = date && date.length && date[0] ? `&date_from=${date[0]}` : '';
    const filterDateTo = date && date.length && date[1] ? `&date_to=${date[1]}` : '';
    const filterPath = filterState + filterCrop + filterSeason + filterAssoc + filterDateFrom + filterDateTo;

    const { data, isLoading: loading } = usePolygons(`${config.opeIssue}?data=0${filterPath}`, parsedUser.token)

    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>GPS Captured Map</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Row>
                            <Col span={6}>
                                <div style={{ margin: '24px 0' }}>
                                    <h5>Filter Season</h5>
                                    <Select defaultValue="Filter Season" style={{ width: '80%' }} onChange={(v) => setSeason(v)}>
                                        <Option value={""}>All</Option>
                                        {seasons && seasons.map(item => (
                                            <Option key={item.season} value={item.season}>{item.season}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div>
                                    <h5>Filter State</h5>

                                    <Select defaultValue="Filter State" style={{ width: '80%' }} onChange={(v) => changeState(v)}>
                                        <Option value={""}>All</Option>
                                        {states && states.map(state => (
                                            <Option key={state.state_id} value={state.state_id}>{state.name}</Option>

                                        ))}
                                    </Select>
                                </div>
                                <div style={{ margin: '24px 0' }}>
                                    <h5>Filter Crop</h5>

                                    <Select defaultValue="Filter Crop" style={{ width: '80%' }} onChange={(v) => setCrop(v)}>
                                        <Option value={""}>All</Option>
                                        {crops && crops.map(crop => (
                                            <Option key={crop._id} value={crop._id}>{crop.crop_name}</Option>
                                        ))}
                                    </Select>
                                </div>

                                <div style={{ margin: '24px 0' }}>
                                    <h5>Filter Associations</h5>
                                    <Select defaultValue="Filter Associations" style={{ width: '80%' }} onChange={(v) => setAssociation(v)}>
                                        <Option value={""}>All</Option>
                                        {associations && associations.filter(item => item.client_name).map((item, i) => (
                                            <Option key={i} value={item.client_name}>{item.client_name}</Option>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{ margin: '24px 0' }}>
                                    <h5>Filter Date</h5>
                                    <RangePicker
                                        style={{ width: '80%' }}
                                        defaultValue={[]}
                                        ranges={{
                                            Today: [moment(), moment()],
                                            'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                            'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                            'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                            'All Time': [],
                                        }}
                                        onChange={(_, date) => {
                                            setDate(date)
                                        }} />
                                </div>
                            </Col>
                            <Col span={18}>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>
                                        Total Results: {data && data.count ? formatNumber(data.count) : 0}
                                    </h3>
                                    {loading ? <Spin /> :
                                        <MapView data={data} />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(Polygons);
