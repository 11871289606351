import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Table,
  Icon,
  Tooltip,
  Tag,
  Breadcrumb,
  Input,
  Modal,
  Select,
  Checkbox,
  InputNumber,
  Form,
} from "antd";
import { observer, inject } from "mobx-react";
import Drawer from "../dashboard/Drawer";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import config from "../../config";
const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    marginRight: 3,
    color: "#fff",
  },
  searchBar: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  projectMenu: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f2f3f4",
    },
  },
});

@inject("userStore")
@observer
class AgentFarmerUnits extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 10 },
    isLoading: true,
    pageUrl: 0,
    selectedFile: null,
    visible: false,
    loadingUpload: false,
    states: [],
    lgas: [],
    downloadFile: false,
    project_names: [],
    activeFilters: {
      state: "",
      lga: "",
      filter: "",
      perPage: "",
      project_name: "",
      mapped: "",
    },
    stateAndLGA: [],
    lgaFilter: [],
    selectedFarmers: [],
    job: null,
    jobs: [],
    jobModalVisible: false,
    bulkEditVisible: false,
    busy: false,
    startThread: false,
    numOfThreads: 1,
  };

  componentDidMount() {
    // this.fetchContent();
  }

  fetchContent() {
    const { tenant } = this.props.userStore;
    const isGuest = this.props.userStore.role.role === "Guest";
    const filterState = tenant
      ? `?country=${encodeURIComponent(tenant.country)}`
      : "";

    axios
      .all([
        axios({
          url: `${config.baseUrl}/farmers${
            isGuest ? `?season=${"2020 Wet Season"}` : ""
          }`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/states${filterState}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isLoading: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/project_names`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          console.log(err.response);
          this.setState({ isLoading: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/jobs`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isLoading: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
      ])
      .then(
        axios.spread((res1, res2, res3, res4) => {
          const states = res2.data.entity.states.map((state) => {
            const newObj = {
              text: state.name,
              value: state.state_id,
            };
            return newObj;
          });
          const pagination = { ...this.state.pagination };
          pagination.total = res1.data.iTotalRecords;
          pagination.pageSizeOptions = ["10", "20", "50", "100"];
          pagination.showSizeChanger = true;
          pagination.current = parseInt(res1.data.current_page + 1); //sets pagination page to 1
          pagination.showTotal = (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`;
          this.setState({
            isLoading: false,
            data: res1.data ? res1.data.aaData : [],
            states,
            stateAndLGA: res2.data.entity.states,
            pagination,
            project_names: res3 ? res3.data.entity : [],
            jobs: res4.data.entity,
          });
        })
      );
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleOk = () => {
    this.uploadFile();
  };

  handleEditCancel = () => {
    this.setState({ bulkEditVisible: false });
  };

  handleEditOk = () => {
    this.uploadBulkEdit();
  };

  onPickFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  uploadFile = async () => {
    if (!this.state.selectedFile) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select a file to upload"
      );
    }
    this.setState({ loadingUpload: true });
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    await axios({
      url: `${config.baseUrl}/farmers`,
      method: "POST",
      data,
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({ loadingUpload: false, visible: false });
        return this.props.userStore.openNotificationWithIcon(
          "info",
          res.data.status.desc
        );
      })
      .catch((err) => {
        console.log(err);
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  uploadBulkEdit = async () => {
    if (!this.state.selectedFile) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select a file to upload"
      );
    }
    this.setState({ loadingUpload: true });
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    await axios({
      url: `${config.uploadBaseUrl}/farmers/update`,
      method: "POST",
      data,
      headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ loadingUpload: false, visible: false });
        return this.props.userStore.openNotificationWithIcon(
          "info",
          res.data.status.desc
        );
      })
      .catch((err) => {
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  filter = async (url) => {
    this.setState({ isLoading: true });
    await axios({
      url: url,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({ isLoading: false });
          return this.props.userStore.openNotificationWithIcon(
            "error",
            "Error"
          ); //Fix this
        }
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.iTotalRecords;
        pagination.pageSizeOptions = ["10", "20", "50", "100"];
        pagination.showSizeChanger = true;
        pagination.current = parseInt(res.data.current_page) + 1; //sets pagination page to 1
        pagination.showTotal = (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} items`;
        this.setState({
          isLoading: false,
          data: res.data.aaData,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  handleSearch = async (query) => {
    this.setState({ isLoading: true });
    await axios({
      url: `${config.baseUrl}/farmers?query=${query}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then(async (res) => {
        if (res.status !== 200) {
          this.setState({ isLoading: false });
          return this.props.userStore.openNotificationWithIcon(
            "error",
            "Error"
          ); //Fix this
        }
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.iTotalRecords;
        pagination.current = parseInt(res.data.current_page + 1); //sets pagination page to 1
        await this.setState({
          isLoading: false,
          data: res.data.aaData,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  downloadFile = () => {
    axios({
      url:
        "https://test.tradebuza.com/files/download/Farmer%20Upload%20Template.xlsx",
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "FARMER UPLOAD TEMPLATE.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  exportData = async (url) => {
    this.setState({ downloading: true });
    await axios({
      url: `${url}`,
      method: "GET",
      // responseType: 'blob',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ downloading: false });
        this.props.userStore.openNotificationWithIcon(
          "success",
          res.data.status.desc
        );
        this.props.history.push("/downloads");
      })
      .catch((err) => {
        this.setState({ downloading: false });
      });
  };

  setLGAs = async (id) => {
    if (!id) {
      this.setState({ lgaFilter: [] });
      return;
    }
    const state = this.state.stateAndLGA.find((item) => item.state_id === id);
    this.setState({ lgaFilter: state.lgas });
  };

  addNewJob = () => {
    if (this.state.selectedFarmers.length === 0) {
      return this.props.userStore.openNotificationWithIcon(
        "info",
        "Please select one or more rows"
      );
    }
    this.setState({
      jobModalVisible: true,
    });
  };

  handleSubmitJob = async () => {
    if (!this.state.job) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select a job"
      );
    }
    const data = {
      job: this.state.job,
      farmer_data: `${this.state.selectedFarmers.join()}`,
    };
    this.setState({ busy: true });
    await axios({
      url: `${config.baseUrl}/job/pool`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        // this.setState({ busy: false, jobModalVisible: false })
        // this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
        if (this.state.startThread) {
          this.startThread();
        } else {
          this.setState({ busy: false, jobModalVisible: false });
          this.props.userStore.openNotificationWithIcon(
            "success",
            res.data.status.desc
          );
        }
      })
      .catch((err) => {
        this.setState({ busy: false });
        this.props.userStore.openNotificationWithIcon("error", err.message);
      });
  };

  startThread = async () => {
    const data = {
      job: this.state.job,
      threads: this.state.numOfThreads,
    };
    await axios({
      url: `${config.baseUrl}/job/thread`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ busy: false, jobModalVisible: false });
        this.props.userStore.openNotificationWithIcon(
          "success",
          res.data.status.desc
        );
      })
      .catch((err) => {
        this.setState({ busy: false });
        this.props.userStore.openNotificationWithIcon("error", err.message);
      });
  };

  checkFilter = (pagination) => {
    //set page if pagination
    let page;
    let pageSize;
    if (pagination) {
      page = pagination.current - 1;
      pageSize = pagination.pageSize;
      this.setState({ pagination: { ...this.state.pagination, pageSize } });
    } else {
      page = 0;
      pageSize = 10;
    }
    //construct URL
    let filter = this.state.activeFilters.filter;
    let state = this.state.activeFilters.state;
    let lga = this.state.activeFilters.lga;
    let season = this.state.activeFilters.project_name;
    let mapped = this.state.activeFilters.mapped;
    const isGuest = this.props.userStore.role.role === "Guest";
    let url = `${config.baseUrl}/farmers?data=0${
      page === 0 ? "" : `&page=${page}`
    }&per_page=${pageSize}${state && `&state=${state}`}${lga &&
      `&lga=${lga}`}${filter && `&farm_lot=${filter}`}${season &&
      `&season=${season}`}${mapped && `&mapped=${mapped}`}${
      isGuest ? `&season=${"2020 Wet Season"}` : ""
    }`;

    this.filter(url);
  };

  setExportUrl = () => {
    let filter = this.state.activeFilters.filter;
    let state = this.state.activeFilters.state;
    let lga = this.state.activeFilters.lga;
    let season = this.state.activeFilters.project_name;
    let mapped = this.state.activeFilters.mapped;

    let url = `${config.baseUrl}/farmers/export?${state &&
      `&state=${state}`}${lga && `&lga=${lga}`}${filter &&
      `&farm_lot=${filter}`}${season && `&season=${season}`}${mapped &&
      `&mapped=${mapped}`}`;
    this.exportData(url);
  };

  render() {
    const { classes } = this.props;
    const { role } = this.props.userStore.role;
    const rowSelection = {
      onChange: async (selectedRowKeys, selectedRows) => {
        await this.setState({ selectedFarmers: selectedRowKeys });
      },
    };
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#f2f3f4",
              padding: 10,
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item>Farmer Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: "#2A3F54" }} level={3}>
              Farm Units
            </Title>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* {(role === "Project Manager" || role === "Guest" || role === "Admin") ? null :
                            <div>
                                <Link to="/farmers/add">
                                    <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                                        Add New Farmer
                                    </Button>
                                </Link>
                                <Button type="primary" onClick={() => this.setState({ visible: true })} className={classes.button} style={{background: '#009688'}}>
                                    Upload Farmers
                                </Button>
                                <Button type="primary" onClick={() => this.setState({ bulkEditVisible: true })} className={classes.button} style={{background: '#009688'}}>
                                    Bulk Edit Farmer
                                </Button>
                            </div>
                        } */}
            {/* {role === "Admin" &&
                            <Button onClick={this.addNewJob} type="primary" className={classes.button}>
                                Add New Job
                            </Button>
                        } */}
            <div>
              <Input
                className={classes.searchBar}
                placeholder='Search'
                onChange={(e) => this.handleSearch(e.target.value)}
              />
            </div>
            <div>
              <Link to='/add-farmer'>
                <Button
                  type='primary'
                  className={classes.button}
                  style={{ background: "#009688" }}
                >
                  Add New Farmer
                </Button>
              </Link>
            </div>
          </div>
          <div
            className='table-container'
            style={{ padding: 24, background: "#fff" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
                background: "#009688",
                padding: "20px",
              }}
            >
              {role !== "Guest" && (
                <Select
                  style={{ width: "20%" }}
                  defaultValue='Filter Status'
                  onChange={(value) => {
                    this.setState(
                      {
                        activeFilters: {
                          ...this.state.activeFilters,
                          filter: value,
                        },
                      },
                      () => {
                        this.checkFilter();
                      }
                    );
                  }}
                >
                  <Option value=''>All</Option>
                  <Option value='1'>Farm Added</Option>
                  <Option value='2'>No Farm</Option>
                </Select>
              )}
              <Select
                style={{ width: "20%" }}
                defaultValue='State/Region'
                onChange={(value) => {
                  this.setState(
                    {
                      activeFilters: {
                        ...this.state.activeFilters,
                        state: value,
                        lga: "",
                      },
                    },
                    () => {
                      this.checkFilter();
                    }
                  );
                  this.setLGAs(value);
                }}
              >
                <Option value=''>All</Option>

                {this.state.stateAndLGA.map((item) => (
                  <Option key={item.state_id} value={item.state_id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
              <Select
                style={{ width: "20%" }}
                defaultValue='LGA/District'
                onChange={(value) => {
                  this.setState(
                    {
                      activeFilters: {
                        ...this.state.activeFilters,
                        lga: value,
                      },
                    },
                    () => {
                      this.checkFilter();
                    }
                  );
                }}
              >
                <Option value=''>All</Option>
                {this.state.lgaFilter.map((item) => (
                  <Option key={item.local_id} value={item.local_id}>
                    {item.local_name}
                  </Option>
                ))}
              </Select>
              {/* {role === "Guest" && (
                <Select
                  style={{ width: "20%" }}
                  defaultValue='Mapping Status'
                  onChange={(value) => {
                    this.setState(
                      {
                        activeFilters: {
                          ...this.state.activeFilters,
                          mapped: value,
                        },
                      },
                      () => {
                        this.checkFilter();
                      }
                    );
                  }}
                >
                  <Option value=''>All</Option>
                  <Option value='1'>Mapped</Option>
                  <Option value='0'>Not Mapped</Option>
                </Select>
              )} */}
              {/* {role !== "Guest" && (
                <>
                  <Select
                    style={{ width: "20%" }}
                    defaultValue='Filter Season'
                    onChange={(value) => {
                      this.setState(
                        {
                          activeFilters: {
                            ...this.state.activeFilters,
                            project_name: value,
                          },
                        },
                        () => {
                          this.checkFilter();
                        }
                      );
                    }}
                  >
                    <Option value=''>All</Option>
                    {this.state.project_names
                      .filter((item) => item.season)
                      .map((item) => {
                        return (
                          <Option key={item.season} value={item.season}>
                            {item.season}
                          </Option>
                        );
                      })}
                  </Select>
                  <Button
                    onClick={() => this.setExportUrl()}
                    loading={this.state.downloading}
                    type='primary'
                    style={{ background: "#fff", color: "#009688" }}
                  >
                    Export Data
                  </Button>
                </>
              )} */}
            </div>
            {/* <Table
              bordered
              rowKey={(record) => record._id}
              loading={this.state.isLoading}
              rowSelection={role === "Admin" ? rowSelection : null}
              onChange={this.checkFilter}
              pagination={this.state.pagination}
              dataSource={this.state.data}
            >
              <Column
                title='Surname'
                dataIndex='last_name'
                key='last_name'
                render={(text) => <span>{text.toUpperCase()}</span>}
              />
              <Column
                title='Other Names'
                dataIndex='first_name'
                key='first_name'
                render={(text, record) => (
                  <span>
                    {`${record.middle_name.toUpperCase()} ${text.toUpperCase()}`}
                  </span>
                )}
              />

              <Column title='Mobile' dataIndex='mobile' key='mobile' />
              <Column
                title='Status'
                dataIndex='mapped'
                key='mapped'
                filterMultiple={false}
                render={(text, record) => (
                  <span>
                    {text === 1 ? (
                      <Tag color='green'>Mapped</Tag>
                    ) : (
                      <Tag color='orange'>Not Mapped</Tag>
                    )}
                  </span>
                )}
              />
              <Column
                title='State/Region'
                dataIndex='home_state.name'
                key='home_state.name'
              />
              <Column
                title='LGA/District'
                dataIndex='home_lga.local_name'
                key='home_lga.local_name'
              />
              <Column
                title='Date Created'
                dataIndex='created'
                key='created'
                render={(text, record) => (
                  <span>{moment(text).format("LL, h:mm:A")}</span>
                )}
              />
              <Column
                title='Actions'
                key='actions'
                render={(text, record) => (
                  <span>
                    <Tooltip title='View Details'>
                      <Button
                        style={{ marginRight: 2 }}
                        type='default'
                        onClick={() =>
                          this.props.history.push(
                            `/farmers/${text.farmer_id}`,
                            text.farmer_id
                          )
                        }
                      >
                        <Icon type='eye' style={{ color: "#2A3F54" }} />
                      </Button>
                    </Tooltip>
                  </span>
                )}
              />
            </Table> */}
            <Modal
              title='Upload Excel File (.xls or .xlsx)'
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
                <Button key='back' onClick={this.handleCancel}>
                  Cancel
                </Button>,
                <Button
                  loading={this.state.loadingUpload}
                  key='ok'
                  onClick={this.handleOk}
                >
                  Upload
                </Button>,
                <Button key='download' onClick={this.downloadFile}>
                  Download Template
                </Button>,
              ]}
            >
              <input
                accept='.xls,.xlsx'
                type='file'
                onChange={this.onPickFile}
              />
              <br />
            </Modal>
            <Modal
              title={`Add New Job (${
                this.state.selectedFarmers.length
              } Farmers)`}
              visible={this.state.jobModalVisible}
              onOk={this.handleSubmitJob}
              onCancel={() => this.setState({ jobModalVisible: false })}
              footer={[
                <Button
                  key='back'
                  onClick={() => this.setState({ jobModalVisible: false })}
                >
                  Cancel
                </Button>,
                <Button
                  loading={this.state.busy}
                  key='ok'
                  onClick={this.handleSubmitJob}
                >
                  Submit
                </Button>,
              ]}
            >
              <Select
                style={{ width: "100%" }}
                defaultValue='Select Job'
                onChange={(value) => {
                  this.setState({
                    job: value,
                  });
                }}
              >
                {this.state.jobs.map((item) => (
                  <Option key={item._id} value={item._id}>
                    {item.job_name}
                  </Option>
                ))}
              </Select>
              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Checkbox
                  onChange={(e) =>
                    this.setState({ startThread: e.target.checked })
                  }
                >
                  Start Now
                </Checkbox>
              </div>
              {this.state.startThread && (
                <Form.Item style={{ margin: 0 }} label='Number of Threads'>
                  <InputNumber
                    min={1}
                    max={5}
                    value={this.state.numOfThreads}
                    onChange={(value) => this.setState({ numOfThreads: value })}
                  />
                </Form.Item>
              )}
              <br />
            </Modal>
            <Modal
              title='Upload Excel File (.xls or .xlsx)'
              visible={this.state.bulkEditVisible}
              onOk={this.handleEditOk}
              onCancel={this.handleEditCancel}
              footer={[
                <Button key='back' onClick={this.handleEditCancel}>
                  Cancel
                </Button>,
                <Button
                  loading={this.state.loadingUpload}
                  key='ok'
                  onClick={this.handleEditOk}
                >
                  Upload
                </Button>,
              ]}
            >
              <input
                accept='.xls,.xlsx'
                type='file'
                onChange={this.onPickFile}
              />
              <br />
            </Modal>
          </div>
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AgentFarmerUnits);
