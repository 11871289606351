import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from "react-google-maps";
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { observer, inject } from 'mobx-react'



const CustomSkinMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={5}
      mapTypeId="satellite"
      defaultCenter={{ lat: 6.4809427, lng: 3.36553 }}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
      >
        {props.data && props.data.map(item => (
          <div key={item._id}>

            {/* {item.points && item.points.map((marker, i) => {
              return (
                <Marker
                  // onClick={() => {
                  //   props.setActiveMarker(item._id)
                  // }}
                  key={i}
                  position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }}
                >
                </Marker>
              )
            })} */}
            <Marker
              onClick={() => {
                props.setActiveMarker(item._id)
              }}
              position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
            >
            </Marker>
            {props.activeMarker === item._id &&
              <InfoWindow
                onCloseClick={() => props.setActiveMarker(0)}
                position={{
                  lat: parseFloat(item.lat),
                  lng: parseFloat(item.lng)
                }}>
                <div className="">
                  <p style={{ color: '#000000' }}>Agent Name: {item.agent.first_name} {item.agent.last_name}</p>
                  {/* <p>Association: {item.farmer.client_name}</p> */}
                </div>
              </InfoWindow>
            }

            {/* <Polygon
              onClick={() => {
                props.setActiveMarker(item._id)
              }}
              path={item.points && reversed(item.points)}
              options={{
                fillColor: "#009688",
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
              }}>

            </Polygon> */}
          </div>

        ))}

      </MarkerClusterer>
    </GoogleMap>
  ))
);

@inject('userStore')
@observer
class Maps extends React.Component {

  state = {
    activeMarker: 0
  }

  setActiveMarker = (markerid) => {
    this.setState({ activeMarker: markerid })
  }
  render() {

    return (
      <CustomSkinMap
        activeMarker={this.state.activeMarker}
        data={this.props.data}
        setActiveMarker={this.setActiveMarker}
        userStore={this.props.userStore}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCMunMYXO3coSLFXYfmHxm6OlfPpio-XdQ"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `70vh` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    )
  }
}

export default Maps
