import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Typography, Button, InputNumber, Spin
} from 'antd';
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'
import { formatCurrency } from '../../config/utils';

const { Title } = Typography;


const styles = theme => ({
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class Settings extends React.Component {

    static contextType = AuthContext
    state = {
        loading: false,
        price: 0,
        current_price: 0,
        loadingPrice: true
    }

    componentDidMount() {
        this.fetchRate()
    }

    fetchRate = async () => {
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({
                loadingPrice: false,
                current_price: parseInt(res.data.entity.me.tenant.collection_rate)
            })
        }).catch((err) => {
            this.setState({ loadingPrice: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    setNewRate = async (e) => {
        e.preventDefault()
        if (!this.state.price) {
            return this.props.userStore.openNotificationWithIcon('error', 'Price is Required')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/collections/settings`,
            method: 'PUT',
            data: {
                collection_rate: this.state.price
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully updated rate")
            this.fetchRate()
        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }



    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.isFetching ?
                    <div className={classes.spin}>
                        <Spin />
                    </div>
                    :
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Collection Settings</Title>
                        <Form onSubmit={this.setNewRate} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="New Price per KG">
                                <InputNumber min={1} onChange={(val) => this.setState({ price: val })} />
                            </Form.Item>
                            <br />
                            <Title level={4}>Current Rate: {formatCurrency(this.state.current_price)} per Kg</Title>
                            <Form.Item>
                                <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                }
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Settings);
