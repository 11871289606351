import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck, faTasks, faMapMarkedAlt, faCheck } from '@fortawesome/free-solid-svg-icons'





export default class Hazie extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: 'white', padding: 20 }}>
                <div style={{
                    border: '3px solid #f2f3f4',
                    width: 300,
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 15,
                    boxShadow: "3px 3px 10px #f2f3f4"
                }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, fontWeight: '700' }}>Whitelisted Farms</p>
                        <FontAwesomeIcon icon={faTasks} color="#1890ff" style={{ fontSize: 24 }} />
                    </div>
                    <h1 style={{ fontWeight: '700' }}>3,000</h1>
                </div>
                <div style={{
                    border: '3px solid #f2f3f4',
                    width: 300,
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 15,
                    boxShadow: "3px 3px 10px #f2f3f4"
                }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, fontWeight: '700' }}>Total KYC Captured</p>
                        <FontAwesomeIcon icon={faUserCheck} color="#1890ff" style={{ fontSize: 24 }} />
                    </div>
                    <h1 style={{ fontWeight: '700' }}>3,000,000</h1>
                </div>
                <div style={{
                    border: '3px solid #f2f3f4',
                    width: 300,
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 15,
                    boxShadow: "3px 3px 10px #f2f3f4"
                }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, fontWeight: '700' }}>Total Mapped Farms</p>
                        <FontAwesomeIcon icon={faMapMarkedAlt} color="#1890ff" style={{ fontSize: 24 }} />
                    </div>
                    <h1 style={{ fontWeight: '700' }}>3,000</h1>
                </div>

                <div style={{
                    border: '3px solid #f2f3f4',
                    width: 300,
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 15,
                    boxShadow: "3px 3px 10px #f2f3f4"
                }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ fontSize: 12, fontWeight: '700' }}>Verified Farms</p>
                        <FontAwesomeIcon icon={faCheck} color="#1890ff" style={{ fontSize: 24 }} />
                    </div>
                    <h1 style={{ fontWeight: '700' }}>3,000</h1>
                </div>

            </div>
        )
    }
}