import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Breadcrumb
} from 'antd';
import config from '../../config'

const Option = Select.Option;
const { Title } = Typography;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class AddUser extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        first_name: '',
        last_name: '',
        email: '',
        role: null,
        tenant_id: "",
        password: '',
        mobile: '',
        rolesData: [],
        tenantData: [],
        bvn: '',
        roleText: ''
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/role`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/tenants`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                const code = err.response.data.status.code
                if (code === 110) {
                    return
                }
                else {
                    this.props.userStore.openNotificationWithIcon('error', err.message)
                }
            }),
        ]).then(axios.spread((res1, res2) => {
            this.setState({
                isFetching: false,
                rolesData: res1.data.entity,
                tenantData: res2 ? res2.data.entity.tenants : []
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            mobile: this.state.mobile,
            role: this.state.role,
            tenant_id: this.state.tenant_id,
            password: this.state.password,
            bvn: this.state.bvn
        }
        const mailTest = /\S+@\S+\.\S+/
        if (!this.state.email) {
            return this.props.userStore.openNotificationWithIcon('error', 'Email address is required')
        }
        if (mailTest.test(this.state.email) === false) {
            return this.props.userStore.openNotificationWithIcon('error', 'Invalid Email Address')
        }
        if (!this.state.first_name || !this.state.last_name) {
            return this.props.userStore.openNotificationWithIcon('error', 'Firstname and Lastname required')
        }
        if (!this.state.password) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a password')
        }
        if (!this.state.role) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select user group')
        }
        if (this.state.roleText === "Aggregator" && !this.state.tenant_id) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a tenant')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added User")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleChangeRole = (value) => {
        const roleText = this.state.rolesData.filter(item => item._id === value)[0].role
        this.setState({
            roleText,
            role: value
        })
    }

    onSearch = async (query) => {
        await axios({
            url: `${config.baseUrl}/tenants?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {

            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                tenantData: res.data.entity.tenants
            })
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/users">User Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New User
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Add New User</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>First Name</span>}>
                                    <Input onChange={(e) => this.setState({ first_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>Last Name</span>}>
                                    <Input onChange={(e) => this.setState({ last_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="Phone Number">
                                    <Input type="number" onChange={(e) => this.setState({ mobile: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>Email</span>}>
                                    <Input type="email" onChange={(e) => this.setState({ email: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>Password</span>}
                                >
                                    <Input type="password" onChange={(e) => this.setState({ password: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>User Group</span>}>
                                    <Select defaultValue="Select Group" onChange={(value) => this.setState({ role: value })}>
                                        {this.state.rolesData.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>{item.role}</Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                                {this.props.userStore.role.role === "Admin" &&
                                    <Form.Item
                                        colon={false}
                                        label="Select Tenant">
                                        <Select
                                            autoClearSearchValue={false}
                                            showSearch
                                            onSearch={this.onSearch}
                                            defaultValue="Select Tenant"
                                            onChange={(value) => this.setState({ tenant_id: value })}>
                                            {this.state.tenantData.filter(item => item.role !== "Admin").map((item) => {
                                                return (
                                                    <Option key={item._id} value={item._id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                }

                                <Title style={{ color: '#2A3F54' }} level={4}>Other Information</Title>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="BVN">
                                    <Input onChange={(e) => this.setState({ bvn: e.target.value })} />
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{ background: '#009688' }}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddUser);
