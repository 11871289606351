import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { Typography, Breadcrumb, Tabs } from 'antd';
import { Link } from 'react-router-dom'
import CollectionSettings from './CollectionSettings';


const { Title } = Typography;
const { TabPane } = Tabs;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

class Settings extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/payments/transactions">Payment</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Settings
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <Title level={3}>Settings</Title>
                    <Tabs type="card">
                        <TabPane tab="Collection Settings" key="1">
                            <CollectionSettings />
                        </TabPane>
                    </Tabs>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Settings);
