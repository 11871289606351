import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'

import Drawer from '../dashboard/Drawer'

import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Row, Col, Breadcrumb, DatePicker
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'

const Option = Select.Option;
const TextArea = Input.TextArea;
const { Title } = Typography;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class AddUser extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        first_name: '',
        last_name: '',
        middle_name: '',
        gender: 'NO GENDER',
        mobile: '',
        bvn: '',
        bank: '',
        home_address: '',
        lead_farmer: 0,
        dob: '',
        account_no: '',
        states: [],
        locals: [],
        banks: [],
        selectedState: "",
        selectedLGA: "",
        cluster_point: "",
        cluster_size: 0,
        contact_person: "",
        contact_person_email: ""
    }

    componentDidMount() {
        this.fetchContent()
    }


    fetchContent() {
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''
        axios.all([
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/banks`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })
        ]).then(axios.spread((res1, res2) => {
            this.setState({
                isFetching: false,
                states: res1.data.entity.states,
                banks: res2.data.entity
            })
        }));
    }

    setLGA(state_id) {
        axios({
            url: `${config.baseUrl}/lgas/${state_id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isFetching: false, })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                locals: res.data.entity.cities
            })
        }).catch(err => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            middle_name: this.state.middle_name,
            mobile: this.state.mobile,
            bvn: this.state.bvn,
            gender: this.state.gender,
            lead_farmer: this.state.lead_farmer,
            bank: this.state.bank,
            account_no: this.state.account_no,
            home_address: this.state.home_address,
            home_state: this.state.selectedState,
            home_lga: this.state.selectedLGA,
            dob: this.state.dob,
            cluster_size: this.state.cluster_size,
            cluster_point: this.state.cluster_point,
            contact_person_email: this.state.contact_person_email,
            contact_person: this.state.contact_person,

        }
        if (!this.state.first_name || !this.state.last_name) {
            return this.props.userStore.openNotificationWithIcon('error', 'Firstname and Lastname required')
        }
        if (!this.state.mobile) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a mobile number')
        }


        // if (!this.state.bank || !this.state.account_no) {
        //     return this.props.userStore.openNotificationWithIcon('error', 'Please select both bank and account number')
        // }
        if (!this.state.home_address) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a home address')
        }
        // if (!this.state.selectedState) {
        //     return this.props.userStore.openNotificationWithIcon('error', 'Please supply a home state')
        // }
        // if (!this.state.selectedLGA) {
        //     return this.props.userStore.openNotificationWithIcon('error', 'Please supply a home LGA')
        // }
        // this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/farmer`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added Farmer")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farmers">Farmer Management</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Farmer
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Add New Farmer</Title>
                            <Form onSubmit={this.handleSubmit} className={classes.form}>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>First Name</span>}>
                                    <Input onChange={(e) => this.setState({ first_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="Middle Name">
                                    <Input onChange={(e) => this.setState({ middle_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>Last Name</span>}>
                                    <Input onChange={(e) => this.setState({ last_name: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="Phone Number">
                                    <Input onChange={(e) => this.setState({ mobile: e.target.value })} />
                                </Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Date of Birth">
                                            <DatePicker onChange={(_, dob) => this.setState({ dob })} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Gender">
                                            <Select defaultValue="Select Gender" onChange={(value) => this.setState({ gender: value })}>
                                                <Option value={"MALE"}>Male</Option>
                                                <Option value={"FEMALE"}>Female</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {this.props.userStore.role.role !== "Aggregator" &&

                                    <>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label={<span>BVN</span>}>
                                            <Input type="text" onChange={(e) => this.setState({ bvn: e.target.value })} />
                                        </Form.Item>

                                        <br />
                                        <Title style={{ color: '#2A3F54' }} level={4}>Cluster Information</Title>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Cluster Point">
                                            <Input onChange={(e) => this.setState({ cluster_point: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Contact Person">
                                            <Input onChange={(e) => this.setState({ contact_person: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Contact Person Email">
                                            <Input onChange={(e) => this.setState({ contact_person_email: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Cluster Size (Ha)">
                                            <Input type="number" onChange={(e) => this.setState({ cluster_size: e.target.value })} />
                                        </Form.Item>
                                        <br />
                                    </>
                                }

                                <Title style={{ color: '#2A3F54' }} level={4}>Other Information</Title>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="Loan Amount">
                                    <Input onChange={(e) => this.setState({ loan: e.target.value })} />
                                </Form.Item>
                                <Form.Item
                                    colon={false}
                                    style={{ margin: 0 }}
                                    label="Home Address">
                                    <TextArea onChange={(e) => this.setState({ home_address: e.target.value })} />
                                </Form.Item>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="Home State">
                                            <Select defaultValue="Select State" onChange={(value) => {
                                                this.setState({ selectedState: value })
                                                this.setLGA(value)
                                            }}>
                                                {this.state.states.map((item) => {
                                                    return (
                                                        <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            colon={false}
                                            style={{ margin: 0 }}
                                            label="LGA">
                                            <Select defaultValue="Select LGA" onChange={(value) => this.setState({ selectedLGA: value })}>
                                                {this.state.locals.map((item) => {
                                                    return (
                                                        <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{ background: '#009688' }}>
                                        {this.state.loading ? "" : "Submit"}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddUser);
