import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Icon, Breadcrumb, Skeleton, Card, Row, Col, Modal, InputNumber, Form } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { formatCurrency, renderPaymentStatus } from '../../config/utils'
import PaystackButton from 'react-paystack';
import axios from 'axios'
import moment from 'moment'
import config from '../../config'

const { Title } = Typography;
const { Column } = Table

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,

    }
});


@inject('userStore')
@observer
class Payments extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        balance: 0,
        isLoading: true,
        loadingBalance: true,
        pageUrl: 0,
        modalVisible: false,
        amount: 0,
        info: "",
        p_key: "",
        ref: "",
        email: "",
        payDisabled: true
    }

    componentDidMount() {
        this.fetchBalance()
        this.fetchData()
    }

    fetchBalance = async () => {
        this.setState({ loadingBalance: true })
        await axios({
            url: `${config.baseUrl}/account`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ loadingBalance: false })
            this.setState({
                balance: parseInt(res.data.entity.me.tenant.wallet_balance)
            })
        }).catch((err) => {
            this.setState({ loadingBalance: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    fetchData = async () => {
        await axios({
            url: `${config.baseUrl}/transactions`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.entity.total_records
            pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
            await this.setState({
                isLoading: false,
                data: res.data.entity,
                pagination
            })
            console.log(res.data)
        }).catch((err) => {
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleTableChange = async (pagination) => {  // pagination here will be 2 if 2 is clicked
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        await this.setState({
            pagination: pager,
            pageUrl: pagination.current - 1
        });
        this.fetchMore()
    }

    fetchMore = async () => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/transactions?current_page=${this.state.pageUrl}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                isLoading: false,
                data: res.data.entity,
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSearch = async (query) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/sourcing/all?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.entity.total_records
            pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
            await this.setState({
                isLoading: false,
                data: res.data.entity.sourcing_agents,
                pagination
            })
        }).catch((err) => {

            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    confirmDelete = async (agent) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/sourcing/${agent._id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

        }).catch(err => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    initPayment = async () => {
        const data = {
            amount: this.state.amount
        }
        await axios({
            url: `${config.baseUrl}/payments/topup`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.setState({
                payDisabled: false,
                amount: res.data.entity.amount,
                email: res.data.entity.email,
                p_key: res.data.entity.p_key,
                ref: res.data.entity.ref,
                info: res.data.entity.info

            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleOk = async (e) => {
        if (!this.state.amount) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please enter an amount')
        }
        await this.initPayment().then(() => {
            this.payRef.payWithPaystack()
        })
        this.setState({ modalVisible: false })

    };

    handleCancel = e => {
        this.setState({
            modalVisible: false,
            amount: 0
        });
    };

    callback = (paystackResponse) => {
        this.verify(paystackResponse)
    }

    close = () => {
        this.setState({ amount: 0 })
    }

    verify = async (paystackResponse) => {
        const data = {
            code: paystackResponse.reference
        }
        await axios({
            url: `${config.baseUrl}/payments/topup/verify`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.fetchBalance()
            this.fetchData()
            return this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleChangeAmount = (amount) => {
        this.setState({ amount })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Payments
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Payments</Title>
                    </div>
                    <Row>
                        <Col span={12}>
                            <Card
                                style={{ height: 180, width: 400 }}
                                bodyStyle={{ color: '#116E6E', fontWeight: 'bold' }}
                                headStyle={{ backgroundColor: '#009688', color: '#fff' }}
                                title="Wallet">
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Title style={{ color: '#2A3F54' }} level={4}>Available Balance</Title>
                                        {this.state.loadingBalance ? <Skeleton active paragraph={false} /> :
                                            <Title style={{ color: '#2A3F54' }} level={4}>
                                                {formatCurrency(this.state.balance)}
                                            </Title>
                                        }

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={12}>

                        </Col>
                    </Row>
                    <br />
                    <Button onClick={() => this.setState({ modalVisible: true })} type="primary" style={{ background: "#009688", marginTop: 10 }}>Top Up</Button>
                    <br />
                    <br />
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>All Payments</Title>
                        <Table
                            // footer={(currentPageData) => {
                            //   console.log(currentPageData)
                            //   return (
                            //     <Pagination
                            //       total={85}
                            //       showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            //       pageSize={20}
                            //       defaultCurrent={1}
                            //     />
                            //   )
                            // }}
                            bordered
                            onChange={this.handleTableChange}
                            loading={this.state.isLoading}
                            rowKey={record => record._id}
                            dataSource={this.state.data}>
                            <Column
                                title="Name"
                                dataIndex="tenant.name"
                                key="tenant.name"
                            />
                            <Column
                                title="Type"
                                dataIndex="type"
                                key="type"
                            />
                            <Column
                                title="Amount"
                                dataIndex="amount"
                                key="amount"
                                render={(text, record) => (
                                    <span>
                                        {formatCurrency(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title="Reference"
                                dataIndex="ref"
                                key="ref"

                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(text, record) => (
                                    <span>
                                        {renderPaymentStatus(text)}
                                    </span>
                                )}
                            />
                            <Column
                                title="Date"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LL')}
                                    </span>
                                )}
                            />
                            <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Button style={{ marginLeft: 2 }} type="default">
                                            <Icon type="eye" />
                                        </Button>
                                    </span>
                                )}
                            />
                        </Table>
                        <Modal
                            title="Recharge SMS"
                            visible={this.state.modalVisible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="back" onClick={this.handleCancel}>
                                    Cancel
                                </Button>,
                                <Button key="ok" onClick={this.handleOk}>
                                    Ok
                                </Button>,
                                <PaystackButton
                                    key="pay"
                                    text="Pay"
                                    className="payButton"
                                    callback={this.callback}
                                    close={this.close}
                                    disabled={this.state.payDisabled}
                                    embed={false}
                                    ref={(payRef) => this.payRef = payRef}
                                    reference={this.state.ref}
                                    email={this.state.email}
                                    amount={this.state.amount}
                                    paystackkey={this.state.p_key}
                                    tag="button"
                                />
                            ]}
                        >
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Amount">
                                <InputNumber value={this.state.amount} autoFocus onChange={(e) => this.handleChangeAmount(e)} style={{ width: '100%' }} />
                            </Form.Item>
                            <br />
                        </Modal>
                    </div>
                </main>
            </div >
        );
    }
}



export default withStyles(styles, { withTheme: true })(Payments);
