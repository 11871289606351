import React from 'react';
import { inject, observer } from 'mobx-react'
import { Button, Typography, Table, Breadcrumb, Popconfirm, Icon } from 'antd';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Drawer from '../dashboard/Drawer';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    },

});


@inject('userStore')
@observer
class Currencies extends React.Component {
    state = {
        data: [],
        isLoading: false,
    }

    componentDidMount() {
        this.fetchCurrencies()
    }

    fetchCurrencies = async () => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/currencies`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }

            await this.setState({
                isLoading: false,
                data: res.data.entity,
            })
        }).catch((err) => {
            console.log(err)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    confirmDelete = async (id) => {
        console.log(id)
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/currency/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isLoading: false })
            this.fetchCurrencies()
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

        }).catch(err => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Country Management
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Currencies</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link to="/currencies/add">
                            <Button type="primary" className={classes.button}>
                                Add New Currency
                            </Button>
                        </Link>
                        {/* <div>
                            <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} />
                        </div> */}
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>All Currencies</Title>
                        <Table
                            // pagination={this.state.pagination}
                            bordered
                            // onChange={this.handleTableChange}
                            loading={this.state.isLoading}
                            rowKey={record => record.tenant_id}
                            dataSource={this.state.data}>
                            <Column
                                title="Name"
                                dataIndex="currency"
                                key="currency"
                            />
                            <Column
                                title="Code"
                                dataIndex="code"
                                key="code"
                            />
                            <Column
                                title="Actions"
                                key="_id"
                                render={(text, record) => (
                                    <span>
                                        <Popconfirm title="Are you sure delete this tenant?" onConfirm={() => this.confirmDelete(record._id)} okText="Yes" cancelText="No">
                                            <Button style={{ marginLeft: 2 }} type="default">
                                                <Icon type="delete" style={{ color: '#ea1b2a' }} />
                                            </Button>
                                        </Popconfirm>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Currencies);
