import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Table,
  Icon,
  Popconfirm,
  Breadcrumb,
  Modal,
  List,
  Empty,
  Form,
  Input,
} from "antd";
import { observer, inject } from "mobx-react";
import Drawer from "../dashboard/Drawer";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { formatCurrency } from "../../config/utils";
import moment from "moment";

const { Title } = Typography;
const { Column } = Table;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: "#fff",
  },
  searchBar: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

@inject("userStore")
@observer
class Collections extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 10 },
    isLoading: false,
    disbursing: false,
    pageUrl: 0,
    modalVisible: false,
    otpModal: false,
    selectedCollections: [],
    otp: "",
    password: "",
  };

  componentDidMount() {
    this.fetchCollections();
  }

  fetchCollections = async () => {
    this.setState({ isLoading: true });
    await axios({
      url: `${config.baseUrl}/collections?current_page=0`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then(async (res) => {
        if (res.data.status.code !== 100) {
          this.setState({ isLoading: false });
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.entity.total_records;
        pagination.current = parseInt(res.data.entity.current_page + 1); //sets pagination page to 1
        await this.setState({
          isLoading: false,
          data: res.data.entity.collections,
          pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  handleTableChange = async (pagination) => {
    // pagination here will be 2 if 2 is clicked
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    await this.setState({
      pagination: pager,
      pageUrl: pagination.current - 1,
    });
    this.fetchMore();
  };

  fetchMore = async () => {
    this.setState({ isLoading: true });
    await axios({
      url: `${config.baseUrl}/collections?current_page=${this.state.pageUrl}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.setState({
          isLoading: false,
          data: res.data.entity.collections,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  handleSearch = async (query) => {
    this.setState({ isLoading: true });
    await axios({
      url: `${config.baseUrl}/collections?query=${query}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then(async (res) => {
        if (res.data.status.code !== 100) {
          this.setState({ isLoading: false });
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.entity.total_records;
        pagination.current = parseInt(res.data.entity.current_page + 1); //sets pagination page to 1
        await this.setState({
          isLoading: false,
          data: res.data.entity.collections,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  confirmDelete = async (tenant) => {
    // this.setState({ isLoading: true })
    // await axios({
    //     url: `${config.baseUrl}/account/${tenant._id}`,
    //     method: 'DELETE',
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Authorization": `Bearer ${this.props.userStore.token}`
    //     },
    // }).then(res => {
    //     this.setState({ isLoading: false })
    //     this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
    // }).catch(err => {
    //     this.setState({ isLoading: false })
    //     this.props.userStore.openNotificationWithIcon('error', err.message)
    // })
  };

  initializeDisbursemant = async () => {
    this.setState({ disbursing: true });
    const collectionIds = this.state.selectedCollections.map(
      (item) => item._id
    );
    let data = {
      collections: `${collectionIds}`,
    };
    await axios({
      url: `${config.baseUrl}/payments/disburse`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({
          disbursing: false,
          modalVisible: false,
          otpModal: true,
        });
        console.log(res);
      })
      .catch((err) => {
        this.setState({ disbursing: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  verifyDisbursement = async () => {
    if (!this.state.otp || !this.state.password) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please enter OTP and Password"
      );
    }
    this.setState({ disbursing: true });
    const collectionIds = this.state.selectedCollections.map(
      (item) => item._id
    );
    let data = {
      collections: `${collectionIds}`,
      otp: this.state.otp,
      password: this.state.password,
    };
    await axios({
      url: `${config.baseUrl}/payments/disburse_complete`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ disbursing: false, otpModal: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.props.userStore.openNotificationWithIcon(
          "success",
          "Successfully disbursed payment"
        );

        console.log(res);
      })
      .catch((err) => {
        this.setState({ disbursing: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false, otpModal: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#f2f3f4",
              padding: 10,
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item>Collection Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: "#2A3F54" }} level={3}>
              Collections
            </Title>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Link to='/collections/add'>
              <Button
                type='primary'
                className={classes.button}
                style={{ background: "#009688" }}
              >
                Add New Collection
              </Button>
            </Link>
            <div>
              {/* <Button type="primary" onClick={() => this.setState({ modalVisible: true })} className={classes.button}>
                                Process Pending Payments
                            </Button> */}
              {/* <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} /> */}
            </div>
          </div>
          <div className='table-container' style={{ padding: 24, background: "#fff" }}>
            <Title style={{ color: "#2A3F54" }} level={4}>
              All Collections
            </Title>
            <Table
              // footer={(currentPageData) => {
              //   console.log(currentPageData)
              //   return (
              //     <Pagination
              //       total={85}
              //       showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              //       pageSize={20}
              //       defaultCurrent={1}
              //     />
              //   )
              // }}
              bordered
              onChange={this.handleTableChange}
              loading={this.state.isLoading}
              rowKey={(record) => record._id}
              // rowSelection={rowSelection}
              dataSource={this.state.data}
            >
              <Column
                title='Name'
                dataIndex='name'
                key='name'
                render={(text, record) => (
                  <span>
                    {record.type === "sp"
                      ? `${record.sourcing_partner.first_name} ${
                          record.sourcing_partner.last_name
                        }`
                      : `${record.farmer.first_name} ${
                          record.farmer.last_name
                        }`}
                  </span>
                )}
              />

              <Column
                title='Type'
                dataIndex='type'
                key='type'
                render={(text, record) => (
                  <span>{text === "sp" ? "Sourcing Agent" : "Farmer"}</span>
                )}
              />
              <Column
                title='Crop'
                dataIndex='crop'
                key='crop'
                render={(text, record) => <span>{`${text.crop_name}`}</span>}
              />
              <Column title='Weight' dataIndex='weight' key='weight' />
              <Column
                title='Date'
                dataIndex='created'
                key='created'
                render={(text, record) => (
                  <span>{moment(text).format("LL")}</span>
                )}
              />
              <Column
                title='Actions'
                key='actions'
                render={(text, record) => (
                  <span>
                    {/* <Tooltip title="Edit Tenant">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/tenants/${text._id}`, text)}>
                                                <Icon type="edit" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip> */}
                    <Popconfirm
                      title='Are you sure delete this agent?'
                      onConfirm={() => this.confirmDelete(text)}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button disabled style={{ marginLeft: 2 }} type='default'>
                        <Icon type='delete' style={{ color: "#ea1b2a" }} />
                      </Button>
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
            <div className='modal-container'>
              <Modal
                title='Pending Payments'
                visible={this.state.modalVisible}
                onCancel={this.handleCancel}
                footer={[
                  <Button key='back' onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    disabled={
                      this.state.selectedCollections.length ? false : true
                    }
                    loading={this.state.disbursing}
                    onClick={this.initializeDisbursemant}
                    type='primary'
                    key='ok'
                  >
                    Process Payments
                  </Button>,
                ]}
              >
                <List.Item actions={[<h6>Amount</h6>]}>
                  <List.Item.Meta title={<span>Name</span>} />
                </List.Item>
                {!this.state.selectedCollections.length && <Empty />}
                {this.state.selectedCollections
                  .filter((item) => item.payment_status === 0)
                  .map((collection) => {
                    return (
                      <List.Item
                        key={collection._id}
                        actions={[<h6>{formatCurrency(collection.amount)}</h6>]}
                      >
                        <List.Item.Meta
                          title={
                            <span>
                              {collection.type === "sp"
                                ? `${collection.sourcing_partner.first_name} ${
                                    collection.sourcing_partner.last_name
                                  }`
                                : `${collection.farmer.first_name} ${
                                    collection.farmer.last_name
                                  }`}
                            </span>
                          }
                        />
                      </List.Item>
                    );
                  })}
              </Modal>
            </div>
            <div className='modal-container'>
              <Modal
                title='Verify OTP sent to your mobile phone'
                visible={this.state.otpModal}
                onCancel={this.handleCancel}
                footer={[
                  <Button key='back' onClick={this.handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    loading={this.state.disbursing}
                    onClick={this.verifyDisbursement}
                    type='primary'
                    key='ok'
                  >
                    Verify
                  </Button>,
                ]}
              >
                <div style={{ textAlign: "center" }}>
                  <Form.Item style={{ margin: 0 }} label='OTP'>
                    <Input
                      onChange={(e) => this.setState({ otp: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }} label='Password'>
                    <Input
                      type='password'
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </Form.Item>
                </div>
              </Modal>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Collections);
