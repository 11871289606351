import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, Breadcrumb, Select, Button, Icon, Modal } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import config from '../../config'



const { Title } = Typography;
const { Column } = Table
const { Option } = Select

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});


@inject('userStore')
@observer
class Review extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,
        visible: false,
        status: "",
        seasons: [],
        season: ""
    }

    componentDidMount() {
        this.fetchContent()
    }

    downloadFile = (record) => {
        window.open(record.link, "_blank")
    }


    handleOk = () => {
        this.uploadFile()
    }

    onPickFile = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    uploadFile = async () => {
        if (!this.state.selectedFile) {
            return this.props.userStore.openNotificationWithIcon('error', "Please select a file to upload")
        }
        this.setState({ loadingUpload: true })
        const data = new FormData()
        data.append('file', this.state.selectedFile)
        await axios({
            url: `${config.uploadBaseUrl}/dsm`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            console.log(res)
            this.setState({ loadingUpload: false, visible: false })
            this.fetchUsers()
            return this.props.userStore.openNotificationWithIcon('info', res.data.status.desc)
        }).catch(err => {
            this.setState({ loadingUpload: false })
            console.log(err.response)
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/farmers/review`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/project_names`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })

        ]).then(axios.spread((res1, res2) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res1.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res1.data.aaData,
                seasons: res2.data.entity,
                pagination
            })
        }));
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    checkFilter = (pagination) => {
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }
        const filterPage = page === 0 ? '' : `&page=${page}`
        const filterStatus = this.state.status ? `&status=${this.state.status}` : '';
        const filterSeason = this.state.season ? `&season=${this.state.season}` : '';
        const filterPath = filterStatus + filterPage + filterSeason;
        let url = `${config.baseUrl}/farmers/review?per_page=${pageSize}${filterPath}`;
        this.filter(url)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Reviewed Coordinates
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Reviewed Coordinates</Title>
                    </div>
                    <div style={{ display: 'flex', marginTop: 30, marginBottom: 20, justifyContent: 'space-between' }}>
                        <div>
                            <Select

                                defaultValue="Filter Status"
                                onChange={(status) => {
                                    this.setState({
                                        status
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="APPROVED">Approved</Option>
                                <Option value="REJECTED">Rejected</Option>
                            </Select>
                            <Select
                                style={{ marginLeft: 10 }}
                                defaultValue="Filter Season"
                                onChange={(value) => {
                                    this.setState({
                                        season: value
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}>
                                <Option value="">All</Option>
                                {this.state.seasons.filter(item => item.season).map((item) => {
                                    return (
                                        <Option key={item.season} value={item.season}>{item.season}</Option>
                                    )
                                })}
                            </Select>
                        </div>

                        <Button type="primary" onClick={() => this.setState({ visible: true })}>
                            Upload DSM Result
                        </Button>
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Table
                            size="middle"
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>

                            <Column
                                title="Cluster"
                                dataIndex="first_name"
                                key="first_name"
                                render={(_, record) => (
                                    <span>
                                        {record.first_name} {record.last_name}
                                    </span>
                                )}
                            />

                            <Column
                                title="Client Name"
                                dataIndex="client_name"
                                key="client_name"
                            />

                            <Column
                                title="Agent Name"
                                dataIndex="mapped_by"
                                key="mapped_by"
                                render={(text) => (
                                    <span>
                                        {text ? `${text.first_name} ${text.last_name}` : ""}
                                    </span>
                                )}
                            />
                            <Column
                                title="Project Manager"
                                dataIndex="reviewed_by"
                                key="reviewed_by"
                                render={(text, record) => (
                                    <span>

                                        {text ? `${text.first_name} ${text.last_name}` : ''}
                                    </span>
                                )}
                            />
                            <Column
                                title="Status"
                                dataIndex="review_status"
                                key="review_status"
                                render={(text, record) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="DSM Result"
                                dataIndex="v_result"
                                key="v_result"
                                render={(text, record) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="DSM"
                                dataIndex="verifier"
                                key="verifier"
                                render={(text, record) => (
                                    <span>
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="Action"
                                render={(_, record) => {
                                    return (
                                        <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/review-details/FMR-${record._id}`)}>
                                            <Icon type="eye" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                        </Table>
                    </div>
                    <Modal
                        title="Upload Excel File (.xls or .xlsx)"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                            <Button loading={this.state.loadingUpload} key="ok" onClick={this.handleOk}>
                                Upload
                            </Button>,
                            <Button key="download" onClick={this.downloadFile}>
                                Download Template
                            </Button>,
                        ]}
                    >
                        <input accept=".xls,.xlsx" type="file" onChange={this.onPickFile} />
                        <br />
                    </Modal>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Review);
