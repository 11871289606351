import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Typography, Table, Breadcrumb, Button, Tooltip, Icon, Modal } from 'antd';
import config from '../../config'
import Map from './FarmMap'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});

@inject('userStore')
@observer
class Lots extends React.Component {

    state = {
        isLoading: true,
        data: [],
        pagination: { pageSize: 10 },
        pageUrl: 0,
        mapVisible: false,
        fetchingFarmPoints: false,
        farmTitle: "",
        farmPoints: [],
    }

    componentDidMount() {
        this.getData()

    }

    getData = async () => {
        await axios({
            url: `${config.baseUrl}/subclusters/lots/${this.props.location.state._id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            // if (res.data.status.code !== 100) {
            //     return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            // }
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res.data.iTotalRecords)
            pagination.current = parseInt(res.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            await this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })

        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleTableChange = async (pagination, filters) => {
        // pagination here will be 2 if 2 is clicked
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        await this.setState({
            pagination: pager,
            pageUrl: pagination.current - 1
        });
        this.fetchMore()
    }

    fetchMore = async () => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/subclusters/lots/${this.props.location.state._id}?page=${this.state.pageUrl}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({
                isLoading: false,
                data: res.data.aaData,
            })
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    getFarmPoints = async (farm) => {
        this.setState({ mapVisible: true, message: "loading map...", fetchingFarmPoints: true, farmTitle: farm.farm_id })
        await axios({
            url: `${config.baseUrl}/farm/points/${farm._id}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            console.log({res})
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            if (!res.data.entity.length) {
                this.setState({ message: "No Farm Points Detected" })
            }
            this.setState({ fetchingFarmPoints: false, farmPoints: res.data.entity })

        }).catch((err) => {
            this.setState({ loading: false, fetchingFarmPoints: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleMapOkay = () => {
        this.setState({
            mapVisible: false,
        });
    };

    handleMapCancel = () => {
        this.setState({
            mapVisible: false,
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/farms/sub-clusters">Manage Sub Clusters</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Lots
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>{this.props.location.state.cluster_id} {this.props.location.state.sub_cluster_id} Farm Lots</Title>
                    </div>
                    {/* <div>
                        <Link to="/farms/sub-clusters/add">
                            <Button type="primary" className={classes.button}>
                                Add New Sub Cluster
                            </Button>
                        </Link>
                    </div> */}
                    <div style={{ padding: 24, background: '#fff' }}>
                        {/* <Title style={{ color: '#2A3F54' }} level={4}>All Clusters</Title> */}
                        <Table
                            bordered
                            pagination={this.state.pagination}
                            loading={this.state.isLoading}
                            rowKey={record => record._id}
                            onChange={this.handleTableChange}
                            dataSource={this.state.data}>
                            <Column
                                title="S/N"
                                dataIndex="_id"
                                key="_id"
                                render={(text, record, index) => (
                                    <span>
                                        {index + 1}
                                    </span>
                                )}
                            />
                            <Column
                                title="Lot ID"
                                dataIndex="farm_id"
                                key="farm_id"
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                            />
                            <Column
                                title="Crop"
                                dataIndex="crop.crop_name"
                                key="crop.crop_name"
                            />
                            <Column
                                title="Farmer"
                                dataIndex="farmer"
                                key="farmer"
                                render={(text, record) => (
                                    <span>
                                        {`${text.first_name} ${text.last_name}`}
                                    </span>
                                )}

                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <span>
                                        <Tooltip title="View Map">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.getFarmPoints(record)} >
                                                <Icon type="heat-map" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="View Details">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/${text._id}`, text._id)}>
                                                <Icon type="eye" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                )}
                            />
                        </Table>
                        <Modal
                            footer={null}
                            title={this.state.farmTitle}
                            visible={this.state.mapVisible}
                            onOk={this.handleMapOkay}
                            width={600}
                            okText={"View"}
                            cancelText={"Close"}
                            onCancel={this.handleMapCancel}
                        >
                            {this.state.farmPoints.length ?
                                <Map
                                    points={this.state.farmPoints}
                                // lng={this.state.farmPoints[0].lng}
                                // lat={this.state.farmPoints[0].lat}
                                />
                                :
                                <p>{this.state.message}</p>}
                            {/* {this.state.farmPoints.length ?
                                <div>
                                    <h6 style={{ marginTop: 10 }}>
                                        {`Latitude: ${this.state.farmPoints[0].lat}`}
                                    </h6>
                                    <h6 style={{ marginTop: 10 }}>
                                        {`Longitude: ${this.state.farmPoints[0].lng}`}
                                    </h6>
                                </div>
                                :
                                null} */}
                        </Modal>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(Lots);
