import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Breadcrumb, Row, Col, Icon, Popconfirm } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import moment from 'moment'
import config from '../../config'
const { Title } = Typography;
const { Column } = Table

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});


@inject('userStore')
@observer
class InputManagement extends React.Component {

    state = {
        jobs: [],
        threads: [],
        pool: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,
        pageUrl: 0,
        deleteThreadBusy: false,
        addThreadBusy: false
    }

    componentDidMount() {
        this.fetchContent()
    }


    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/jobs`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/job/threads`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/jobs/pool`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3) => {

            this.setState({
                isLoading: false,
                jobs: res1.data.entity,
                threads: res2.data.entity,
                pool: res3.data.entity
            })
            // console.log(res1)
            // console.log(res3)

        }));
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    handleOk = () => {
        this.handleSubmit()
    }



    deleteThread = async (threadId) => {
        this.setState({ deleteThreadBusy: true })
        await axios({
            url: `${config.baseUrl}/job/thread/${threadId}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ deleteThreadBusy: false })
            this.fetchContent()
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            this.setState({ deleteThreadBusy: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    addThread = async (job) => {
        const data = {
            job,
        }
        this.setState({ addThreadBusy: true })
        await axios({
            url: `${config.baseUrl}/job/thread`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ addThreadBusy: false })
            this.fetchContent()
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            this.setState({ addThreadBusy: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    handleSubmit = async () => {
        const { association, input_type, expected_qty } = this.state;
        const data = {
            association,
            input_type,
            expected_qty
        }
        this.setState({ busy: true })
        await axios({
            url: `${config.baseUrl}/input_type`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ busy: false, visible: false })
            if (res.data.status.code === 100) {
                this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
            }
            this.fetchContent()
        }).catch((err) => {
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    confirmDelete = async (record) => {
        this.setState({ busy: true })
        await axios({
            url: `${config.baseUrl}/input_type/${record._id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ busy: false })
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
            this.fetchContent()

        }).catch(err => {
            this.setState({ busy: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Jobs
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Jobs</Title>
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Row gutter={24}>
                            <Col span={10}>
                                <h4>Threads</h4>
                                <Table
                                    bordered
                                    rowKey={record => record._id}
                                    loading={this.state.isLoading}
                                    onChange={this.checkFilter}
                                    pagination={this.state.pagination}
                                    dataSource={this.state.threads}>

                                    <Column
                                        title="Job"
                                        dataIndex="job.job_name"
                                        key="job.job_name"
                                    />
                                    <Column
                                        title="Done"
                                        dataIndex="done"
                                        key="done"
                                    />
                                    <Column
                                        title="Skipped"
                                        dataIndex="skipped"
                                        key="skipped"
                                    />
                                    <Column
                                        title="Status"
                                        dataIndex="status"
                                        key="status"
                                    />
                                    <Column
                                        title="Date Created"
                                        dataIndex="created"
                                        key="created"
                                        render={(text, record) => (
                                            <span>
                                                {moment(text).format('LL, h:mm:A')}
                                            </span>
                                        )}
                                    />
                                    <Column
                                        title="Actions"
                                        key="actions"
                                        render={(text, record) => (
                                            <span>
                                                <Popconfirm title="Are you sure you want to delete this thread?" onConfirm={() => this.deleteThread(record._id)} okText="Yes" cancelText="No">
                                                    <Button loading={this.state.deleteThreadBusy} style={{ marginLeft: 2 }} type="default">
                                                        <Icon type="delete" style={{ color: '#ea1b2a' }} />
                                                    </Button>
                                                </Popconfirm>
                                            </span>
                                        )}
                                    />
                                </Table>
                            </Col>
                            <Col span={4}></Col>
                            <Col span={10}>
                                <h4>Jobs</h4>
                                <Table
                                    bordered
                                    rowKey={record => record._id}
                                    loading={this.state.isLoading}
                                    // onChange={this.checkFilter}
                                    pagination={this.state.pagination}
                                    dataSource={this.state.jobs}>

                                    <Column
                                        title="Job Name"
                                        dataIndex="job_name"
                                        key="job_name"
                                    />
                                    <Column
                                        title="Actions"
                                        key="actions"
                                        render={(text, record) => (
                                            <span>
                                                <Button onClick={() => this.addThread(record._id)} type="primary" loading={this.state.addThreadBusy} style={{ marginLeft: 2 }}>
                                                    Add Thread
                                                </Button>
                                            </span>
                                        )}
                                    />
                                </Table>
                            </Col>
                        </Row>
                        <h4>Pool</h4>
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.pool}>

                            <Column
                                title="Jon"
                                dataIndex="job.job_name"
                                key="job.job_name"
                            />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                            />
                            <Column
                                title="Farmer"
                                dataIndex="farmer"
                                key="farmer"
                                render={(text, record) => (
                                    <span>
                                        {text ? text.first_name : ""}
                                    </span>
                                )}
                            />
                            <Column
                                title="Date Created"
                                dataIndex="created"
                                key="created"
                                render={(text, record) => (
                                    <span>
                                        {moment(text).format('LL, h:mm:A')}
                                    </span>
                                )}
                            />
                            {/* <Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <span>
                                        <Popconfirm title="Are you sure you want to delete this input type?" onConfirm={() => this.confirmDelete(record)} okText="Yes" cancelText="No">
                                            <Button loading={this.state.busy} style={{ marginLeft: 2 }} type="default">
                                                <Icon type="delete" style={{ color: '#ea1b2a' }} />
                                            </Button>
                                        </Popconfirm>
                                    </span>
                                )}
                            /> */}
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(InputManagement);
