import React from 'react'
import PropTypes from 'prop-types';

import { Layout, Form, Icon, Button, Input , Typography} from 'antd';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import axios from 'axios'
import config from '../../config/index'
import CustomHeader from './Header'
import { AuthContext } from '../../context/AuthContext';

const { Title } = Typography;

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
          width: 400,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
  });

class Reset extends React.Component {

    static contextType = AuthContext

    state = {
        password: '',
        password2: '',
        loading: false,
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const mailTest = /\S+@\S+\.\S+/
        if(mailTest.test(this.state.email) === false) {
            return this.context.openNotificationWithIcon('error', 'Invalid Email Address')
        }
        if(!this.state.email || !this.state.password) {
            return this.context.openNotificationWithIcon('error', 'Email Address required')
        }
        const data = {
            email: this.state.email,
        }
        this.setState({loading: true})
        await axios({
            url: `${config.baseUrl}/account/reset`, 
            method: 'POST', 
            data,
            headers: {
                "Content-Type": "application/json"
            },
        }).then((res) => {
            this.setState({disabled: false})
            if(res.data.status.code !== 100) {
                return this.context.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.context.openNotificationWithIcon('success', res.data.status.desc)
            this.props.history.push('/dashboard')
        }).catch((err) => {
            this.setState({disabled: false})
            return this.context.openNotificationWithIcon('error', err.message)
        })
    }
    
    render() {
        const { classes } = this.props
        return (
            <Layout className="main-auth">
                <CustomHeader />
                <main className={classes.main}>
                    <Paper className={classes.paper}>
                        <Title style={{color: '#2A3F54', textAlign: 'center'}} level={4}>Login</Title>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                            <Form.Item>
                                <Input onChange={(e) => this.setState({password: e.target.value})} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder="New Password" />
                            </Form.Item>
                            <Form.Item>
                                <Input onChange={(e) => this.setState({password2: e.target.value})} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder="Confirm Passwod" />
                            </Form.Item>
                            <Form.Item>
                                <Link to="/">
                                    <p className="login-form-forgot" href="/">Already a member? Log in</p>
                                </Link>
                                <Button loading={this.state.loading} type="primary" htmlType="submit" className={classes.button}>
                                    {this.state.loading? "" :"Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Paper>
                </main>
                {/* <Content style={{ padding: '0 50px' }}>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>Content</div>
                </Content> */}
            </Layout>
        )
    }
}

Reset.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(Reset);