import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table, Breadcrumb, Select, Button, Icon } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import config from '../../config'



const { Title } = Typography;
const { Column } = Table
const { Option } = Select

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    projectMenu: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f2f3f4',
        }
    }
});


@inject('userStore')
@observer
class ClusterManagement extends React.Component {

    state = {
        data: [],
        pagination: { pageSize: 10 },
        isLoading: true,
        busy: false,
        visible: false,
        status: "",
        seasons: [],
        season: "",
        associations: [],
        association: ""
    }

    componentDidMount() {
        this.fetchContent()
    }

    downloadFile = (record) => {
        window.open(record.link, "_blank")
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/farmers/review`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                console.log(err.response)
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmers/review/status`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isLoading: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res1.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res1.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res1.data.aaData,
                pagination
            })
            console.log(res1)

        }));
    }

    handleCancel = () => {
        this.setState({ visible: false })
    }

    filter = async (url) => {
        this.setState({ isLoading: true })
        await axios({
            url: url,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                isLoading: false,
                data: res.data.aaData,
                pagination
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    checkFilter = (pagination) => {
        let page;
        let pageSize;
        if (pagination) {
            page = pagination.current - 1
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            page = 0;
            pageSize = 10
        }
        const filterPage = page === 0 ? '' : `&page=${page}`
        const filterStatus = this.state.status ? `&status=${this.state.status}` : '';
        const filterAssoc = this.state.association ? `&association=${this.state.association}` : '';
        const filterPath = filterStatus + filterPage + filterAssoc;

        let url = `${config.baseUrl}/farmers/review?per_page=${pageSize}${filterPath}`;
        this.filter(url)
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                Farm Cluster Management
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Manage Farm Clusters</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20, marginTop: 30 }}>
                        <div>
                            <Select
                                style={{ marginRight: 10 }}
                                defaultValue="Filter Agreement Status"
                                onChange={(status) => {
                                    this.setState({
                                        status
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="APPROVED">Approved</Option>
                                <Option value="REJECTED">Rejected</Option>
                            </Select>
                            <Select
                                style={{ marginRight: 10 }}
                                defaultValue="Filter Association"
                                onChange={(status) => {
                                    this.setState({
                                        status
                                    }, () => {
                                        this.checkFilter();
                                    });
                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="APPROVED">Approved</Option>
                                <Option value="REJECTED">Rejected</Option>
                            </Select>
                        </div>

                    </div>
                    <div className="table-container">
                        <Table
                            bordered
                            rowKey={record => record._id}
                            loading={this.state.isLoading}
                            onChange={this.checkFilter}
                            pagination={this.state.pagination}
                            dataSource={this.state.data}>

                            <Column
                                title="Cluster Name"
                                dataIndex="first_name"
                                key="first_name"
                                render={(_, record) => (
                                    <span>
                                        {record.first_name} {record.last_name}
                                    </span>
                                )}
                            />

                            <Column
                                title="Association Name"
                                dataIndex="client_name"
                                key="client_name"
                            />

                            <Column
                                title="State"
                                dataIndex="reviewed_coord"
                                key="agent_name"
                                render={(text) => (
                                    <span>
                                        {text ? `${text.agent.first_name} ${text.agent.last_name}` : ''}
                                    </span>
                                )}
                            />

                            <Column
                                title="Agreeement Uploaded"
                                dataIndex="review_status"
                                key="review_status"
                                render={(text, record) => (
                                    <span>
                                        {/* {text && text.toLowerCase() === "approved"
                                            ?
                                            <Tag color="green">{text}</Tag>
                                            :
                                            <Tag color="red">{text}</Tag>
                                        } */}
                                        {text}
                                    </span>
                                )}
                            />
                            <Column
                                title="Action"
                                render={(_, record) => {
                                    return (
                                        <Button type="default" onClick={() => this.props.history.push(`/farms/${record._id}`, record._id)} style={{ background: '#009688' }}>
                                            <Icon type="eye" style={{ color: '#2A3F54' }} />
                                        </Button>
                                    )
                                }}
                            />
                        </Table>

                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(ClusterManagement);
