import React from 'react';
import { inject, observer } from 'mobx-react'
import { Button, Typography, Table, Breadcrumb, Popconfirm, Icon } from 'antd';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Drawer from '../dashboard/Drawer';
import config from '../../config'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    },

});


@inject('userStore')
@observer
class States extends React.Component {
    state = {
        data: [],
        isLoading: false,
    }

    componentDidMount() {
        this.fetchStates()
    }

    fetchStates = async () => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/states`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.data.status.code !== 100) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }

            this.setState({
                isLoading: false,
                data: res.data.entity.states,
            })
        }).catch((err) => {
            console.log(err)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    confirmDelete = async (id) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/state/${id}`,
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isLoading: false })
            this.fetchStates()
            this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

        }).catch(err => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                State / Region Management
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>States / Regions</Title>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 10 }}>
                            <Link to="/states/add">
                                <Button type="primary" className={classes.button}>
                                    Add New State / Region
                            </Button>
                            </Link>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <Link to={{
                                pathname: '/lgas/add',
                            }}>
                                <Button type="primary" className={classes.button}>
                                    Add LGA / District
                            </Button>
                            </Link>
                        </div>


                        {/* <div>
                            <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} />
                        </div> */}
                    </div>
                    <div style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>All States / Regions</Title>
                        <Table
                            // pagination={this.state.pagination}
                            bordered
                            // onChange={this.handleTableChange}
                            loading={this.state.isLoading}
                            rowKey={record => record.state_id}
                            dataSource={this.state.data}>
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                            />
                            <Column
                                title="Country"
                                dataIndex="country"
                                key="country"
                            />
                            <Column
                                title="LGAs / Districts"
                                key="lgas"
                                render={(text, record) => (
                                    <span>
                                        {record.lgas &&
                                            <Link to={{
                                                pathname: '/lgas',
                                                state: record
                                            }}>
                                                view {record.lgas.length} LGAs / Districts
                                        </Link>
                                        }

                                    </span>
                                )}
                            />

                            <Column
                                title="Actions"
                                key="_id"
                                render={(text, record) => (
                                    <span>
                                        <Popconfirm title="Are you sure delete this state?" onConfirm={() => this.confirmDelete(record.state_id)} okText="Yes" cancelText="No">
                                            <Button style={{ marginLeft: 2 }} type="default">
                                                <Icon type="delete" style={{ color: '#ea1b2a' }} />
                                            </Button>
                                        </Popconfirm>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(States);
