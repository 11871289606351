import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Table, Icon, Tooltip, Tag, Popconfirm, Breadcrumb } from 'antd';
import { inject, observer } from 'mobx-react'
import axios from 'axios'
import config from '../../config'
import moment from 'moment';

const { Title } = Typography;
const { Column } = Table;

// const columns = [
//   {
//     title: 'Name',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Description',
//     dataIndex: 'description',
//     key: 'description',
//   },
//   {
//     title: 'Tenant ID',
//     dataIndex: 'tenant_id',
//     key: 'tenant_id',
//   },
// ];


const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    // background: '#2A3F54',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: '#fff',
    // '&:hover': {
    //   background: '#1d2c3a',
    // },
    // '&:focus': {
    //   outline: 0,
    //   background: '#1d2c3a',
    // },
  }
});

@inject('userStore')
@observer
class FarmSeasons extends React.Component {


  state = {
    isLoading: true,
    data: []
  }

  componentDidMount() {
    this.getData()

  }

  getData = async () => {
    await axios({
      url: `${config.baseUrl}/config/seasons`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      this.setState({ data: res.data.entity.seasons })
      console.log(res)

    }).catch((err) => {
      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  endSeason = async (season) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/config/season/${season._id}`,
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
      this.getData()

    }).catch(err => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  startSeason = async (season) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/config/season/${season._id}/start`,
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)
      this.getData()

    }).catch(err => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  renderStatus = (status) => {
    if (status === 0) {
      return <Tag color="orange">Not Started</Tag>
    }
    else if (status === 1) {
      return <Tag color="green">Started</Tag>
    }
    else {
      return <Tag color="red">Ended</Tag>
    }
  }

  renderStartButton = (record) => {
    if (record.status === 0) {
      return (
        <Tooltip title="Start Season">
          <Popconfirm title="Are you sure you want to start this season?" onConfirm={() => this.startSeason(record)} okText="Yes" cancelText="No">
            <Button style={{ marginLeft: 2 }} type="default">
              <Icon type="poweroff" style={{ color: 'green' }} />
            </Button>
          </Popconfirm>
        </Tooltip>
      )
    }
    if (record.status === 1) {
      return (
        <Tooltip title="End Season">
          <Popconfirm title="Are you sure you want to end this season?" onConfirm={() => this.endSeason(record)} okText="Yes" cancelText="No">
            <Button style={{ marginLeft: 2 }} type="default">
              <Icon type="poweroff" style={{ color: 'red' }} />
            </Button>
          </Popconfirm>
        </Tooltip>
      )
    }
    else {
      return null;
    }
  }



  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                Manage Farm Seasons
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: '#2A3F54' }} level={3}>Farming Seasons</Title>
          </div>
          <div>
            <Link to="/seasons/new">
              <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                Add New Season
              </Button>
            </Link>
          </div>
          <div style={{ padding: 24, background: '#fff' }}>
            <Title style={{ color: '#2A3F54' }} level={4}>All Seasons</Title>
            <Table
              bordered
              loading={this.state.isLoading}
              rowKey={record => record._id}
              dataSource={this.state.data}>
              <Column
                title="Crop"
                dataIndex="crop"
                key="crop"
                render={(text) => (
                  <span>
                    {text ? text.crop_name : ''}
                  </span>
                )}
              />
              <Column
                title="Season"
                dataIndex="name"
                key="name"
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(text) => (
                  <span>
                    {this.renderStatus(text)}
                  </span>
                )}
              />
              <Column
                title="Started"
                dataIndex="started"
                key="started"
                render={(text) => (
                  <span>
                   {text == null ? "" : moment(text).format('LL')}
                  </span>
                )}
              />
              <Column
                title="Ended"
                dataIndex="end"
                key="end"
                render={(text) => (
                  <span>
                    {text == null ? "" : moment(text).format('LL')}
                  </span>
                )}
              />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <span>
                    {this.renderStartButton(record)}

                  </span>
                )}
              />
            </Table>
          </div>
        </main>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(FarmSeasons);



