import React, { useState } from 'react';
import { GoogleMap, LoadScript, Polygon, InfoWindow } from '@react-google-maps/api';


const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
}



function MapView(props) {
    const [lat, setLat] = useState(null)
    const [lng, setLng] = useState(null)
    const [center, setCenter] = useState({ lat: 9.0820, lng: 8.6753 })
    const [item, setItem] = useState(null)
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCMunMYXO3coSLFXYfmHxm6OlfPpio-XdQ"
        >
            <GoogleMap
                mapTypeId="satellite"
                center={center}
                mapContainerStyle={{ height: props.height ? props.height : '80vh' }}
                zoom={5}
            >

                <>
                    {lat && lng && item &&
                        <InfoWindow
                            onCloseClick={() => {
                                setLat(null)
                                setLng(null)
                                setItem(null)
                            }}
                            position={{ lat, lng }}
                        >
                            <div>
                                <h3>
                                    CLUSTER NAME
                            </h3>
                                <h1>{item.farmer.first_name}</h1>
                            </div>
                        </InfoWindow>
                    }

                    {props.data.data && props.data.data.filter(v => v.points !== null).map((item, i) => {
                        const formattedPoints = item.points.map(point => {
                            return { lat: parseFloat(point.lat), lng: parseFloat(point.lng) }
                        })
                        return (
                            <Polygon
                                key={i}
                                onClick={() => {
                                    setCenter({ lat: parseFloat(item.points[1].lat), lng: parseFloat(item.points[1].lng) })
                                    setLat(parseFloat(item.points[1].lat))
                                    setLng(parseFloat(item.points[1].lng))
                                    setItem(item)
                                }}
                                paths={formattedPoints}
                                options={options} />
                        )
                    })}
                </>
            </GoogleMap>
        </LoadScript>
    )
}

export default MapView;

