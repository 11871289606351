import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Button, Typography, Breadcrumb, Input, Row, Col, notification, Spin } from 'antd';
import axios from 'axios';
import config from '../../config'
import { useFarmer } from '../../config/utils';
import Drawer from '../dashboard/Drawer'
import Map from '../farm/FarmMap';

const { Title } = Typography;

const user = localStorage.getItem('user-auth')
const parsedUser = JSON.parse(user)

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginRight: 3,
        color: '#fff'
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
})

const initialValues = {
    points: [
        { lat: "", lng: "" },
        { lat: "", lng: "" },
        { lat: "", lng: "" }
    ],
    size: 0
    // points: [
    //     { lat: 5.5262733, lng: 7.5261817 },
    //     { lat: 5.5262216, lng: 7.5262219 },
    //     { lat: 5.5260833, lng: 7.5261409 },
    //     { lat: 5.5260294, lng: 7.5259947 }
    // ]
}



function ManualInput(props) {
    const { classes } = props;


    const [busy, setBusy] = useState(false)

    const farmer_id = props.location.pathname.split("/")[2]

    const { farmer, isLoading } = useFarmer(`${config.baseUrl}/farmer/FMR-${farmer_id}`, parsedUser.token)

    console.log(farmer)


    const onSubmit = async (values, onSubmitProps) => {
        const points = values.points.filter(item => item.lat && item.lng)
        if (points.length < 3) {
            return openNotificationWithIcon('error', "Please enter 3 or more coordinates")
        }
        if (!values.size) {
            return openNotificationWithIcon('error', "Please enter the size")
        }
        const coordinatesToSend = points.map(point => {
            return `${point.lat}:${point.lng}`
        })

        await axios({
            url: `${config.baseUrl}/farmer/${farmer_id}/review`,
            method: 'PUT',
            data: {
                review_status: 'APPROVED_FINAL',
                coordinates: coordinatesToSend.join(),
                rsa_size: values.size
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${parsedUser.token}`
            },
        }).then(res => {
            setBusy(false)
            openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            setBusy(false)
            openNotificationWithIcon('error', err.message)
        })
        onSubmitProps.resetForm()

    }


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            style: {
                marginTop: 50
            },
            message,
        });
    };

    const approveFinal = async () => {
        setBusy(true)
        await axios({
            url: `${config.baseUrl}/farmer/${farmer_id}/review`,
            method: 'PUT',
            data: {
                review_status: 'APPROVED_FINAL'
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${parsedUser.token}`
            },
        }).then(res => {
            setBusy(false)
            openNotificationWithIcon('success', res.data.status.desc)
        }).catch(err => {
            setBusy(false)
            openNotificationWithIcon('error', err.message)
        })
    }
    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            Remove Farms
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {farmer ? `${farmer.first_name} ${farmer.last_name}` : ''}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div>
                    <Title style={{ color: '#2A3F54' }} level={3}>Review Farm</Title>
                </div>
                <div>
                    <Row>
                        <Col span={10}>
                            <div>
                                <p>Add the correct coordinates in decimal degrees</p>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                >
                                    {(formik) => (
                                        <Form>
                                            <FieldArray
                                                name="points"
                                                render={arrayHelpers => (
                                                    <div>
                                                        {formik.values.points && formik.values.points.length > 0 ? (
                                                            formik.values.points.map((friend, index) => (
                                                                <div key={index} style={{ display: 'flex', marginBottom: 10 }}>
                                                                    <p style={{ marginRight: 10 }}>{index + 1}</p>
                                                                    <Field name={`points[${index}].lat`}>

                                                                        {(props) => {
                                                                            const { field } = props
                                                                            return (
                                                                                <Input {...field} style={{ width: '40%' }} placeholder="Latitude" type="number" />
                                                                            )
                                                                        }}
                                                                    </Field>
                                                                    <div style={{ width: 10 }}></div>
                                                                    <Field name={`points[${index}].lng`}>
                                                                        {(props) => {
                                                                            const { field } = props
                                                                            return (
                                                                                <Input {...field} style={{ width: '40%' }} placeholder="Longitude" type="number" />
                                                                            )
                                                                        }}
                                                                    </Field>
                                                                    <Button style={{ marginLeft: 10 }} type="danger" onClick={() => arrayHelpers.remove(index)}>-</Button>
                                                                    <Button style={{ marginLeft: 10 }} onClick={() => arrayHelpers.push({ lat: "", lng: "" })} type="primary">+</Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button onClick={() => arrayHelpers.push({ lat: "", lng: "" })} type="primary">Add Coordinates</Button>
                                                        )}

                                                    </div>
                                                )}
                                            />
                                            <p>Add Farm Size (in Hectares)</p>
                                            <Field name={"size"}>
                                                {(props) => {
                                                    const { field } = props
                                                    return (
                                                        <>
                                                            <Input {...field} style={{ width: '40%' }} placeholder="Size" type="number" />
                                                        </>

                                                    )
                                                }}
                                            </Field>
                                            <br />
                                            <div style={{ marginTop: 40 }}>
                                                <Button
                                                    disabled={formik.isSubmitting}
                                                    loading={formik.isSubmitting}
                                                    style={{ marginRight: 10 }}
                                                    onClick={formik.handleSubmit}
                                                    type="primary">Submit</Button>
                                                <Button
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        formik.resetForm()
                                                    }}>Reset</Button>
                                                <Button
                                                    disabled={busy}
                                                    loading={busy}
                                                    style={{ backgroundColor: '#6c6c6c', color: '#fff', marginRight: 10 }}
                                                    onClick={() => {
                                                        approveFinal()
                                                    }}>No Changes Required</Button>

                                            </div>
                                        </Form>)}
                                </Formik>
                            </div>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={12}>
                            {!isLoading ? <>
                                {(farmer.farm.points && farmer.farm.points.length) ?
                                    <Map
                                        points={farmer.farm.points}

                                    />
                                    : null
                                }
                                <br />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <p>Coordinates (Lat:Lng)</p>
                                        {farmer.farm.points && farmer.farm.points.map(item => (
                                            <h6 key={item._id}>{item.lat} : {item.lng}</h6>
                                        ))}
                                    </div>
                                    <div>
                                        <p>Expected Size (in Hectares)</p>
                                        <h6>{parseFloat(farmer.farm_cluster.cluster_size).toFixed(2)}</h6>
                                        <br />
                                        <p> Size of mapped area (in Hectares)</p>
                                        <h6>{parseFloat(farmer.farm_size).toFixed(2)}</h6>
                                    </div>
                                </div>

                            </> : <div style={{ display: 'flex', justifyContent: 'center', }}><Spin /></div>}


                        </Col>
                    </Row>

                </div>
            </main>
        </div >
    )
}
export default withStyles(styles, { withTheme: true })(ManualInput);