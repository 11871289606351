import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Select, Spin, Row, Col, Breadcrumb, Tag
} from 'antd';
import config from '../../config'
import Map from './FarmMap'

const Option = Select.Option;
const TextArea = Input.TextArea;
const { Title } = Typography;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%'
    }

});

@inject('userStore')
@observer
class EditFarm extends React.Component {
    state = {
        isFetching: true,
        loading: false,
        // crop: this.props.location.state.crop ? this.props.location.state.crop._id : 0,
        locals: [],
        cropData: [],
        farmers: [],
        states: [],
        selectedState: '',
        selectedLGA: ''
        // selectedState: this.props.location.state.state.state_id,
        // selectedLGA: this.props.location.state.community.local_id,
        // selectedFarmer: this.props.location.state.farmer._id
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''
        axios.all([
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/config/crop_profiles`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmers`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farm/${this.props.location.pathname.split("/")[2]}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })
        ]).then(axios.spread((res1, res2, res3, res4) => {

            this.setState({
                isFetching: false,
                states: res1.data.entity.states,
                cropData: res2.data.entity.profiles,
                farmers: res3.data.aaData,
                ...res4.data.entity,
                // selectedState: res4.data.entity.state.state_id,
                selectedState: res4.data.entity.community.state_id,
                selectedLGA: res4.data.entity.community.local_id,

            })


        }));
    }
    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            state: this.state.selectedState,
            community: this.state.selectedLGA,
            size: this.state.size,
            farmer: this.state.selectedFarmer,
            crops: this.state.crop,
            address: this.state.address
        }
        if (!this.state.selectedState || !this.state.selectedLGA) {
            return this.props.userStore.openNotificationWithIcon('error', 'State and LGA required')
        }
        if (!this.state.size) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a Size')
        }
        if (!this.state.farmer) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply a farmer')
        }
        if (!this.state.crop) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please select crop')
        }
        if (!this.state.address) {
            return this.props.userStore.openNotificationWithIcon('error', 'Please supply an address')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/farm/${this.props.location.state}`,
            method: 'PUT',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully updated Farm")
            this.props.history.goBack()

        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    setLGAs = () => {
        const state = this.state.states.find(item => item.state_id === this.state.selectedState)
        if (state) return state.lgas
        return []
    }


    renderTag = (value) => {
        switch (value) {
            case 0:
                return <Tag color="grey">N/A</Tag>
            case 1:
                return <Tag color="red">FAIL</Tag>
            case 2:
                return <Tag color="green">PASS</Tag>
            default:
                return <Tag color="grey">N/A</Tag>
        }
    }


    render() {
        const { classes } = this.props;
        const disableButton = this.props.userStore.role.role === "Project Manager"
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV

        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Edit Farm Unit
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <Row gutter={24}>
                            {env === "staging"
                                ?
                                <Col span={12}>
                                    {this.state.points ?
                                        <Map
                                            points={this.state.points}

                                        />
                                        :
                                        <h3><em>Farm has not been mapped</em></h3>
                                    }
                                    <div style={{ marginTop: 20 }}>
                                        <Row>
                                            <Col span={8}>
                                                {/* <h6>Farm Verification</h6> */}
                                                <h6>Vegetation Check</h6>
                                            </Col>
                                            <Col span={16}>
                                                {/* {this.state.points ?
                                                    <Tag color="green">Passed</Tag>
                                                    :
                                                    <Tag color="grey">Not Applicable</Tag>} */}
                                                {this.renderTag(this.state.vegetation_check)}
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            {/* <Col span={8}>
                                                <h6>Farmed Previously</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.state.points ?
                                                    <Tag color="orange">Pending</Tag>
                                                    :
                                                    <Tag color="grey">Not Applicable</Tag>}

                                            </Col> */}

                                            <Col span={8}>
                                                <h6>Within LGA</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.renderTag(this.state.within_lga)}
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            {/* <Col span={8}>
                                                <h6>Currently Farming</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.state.points ?
                                                    <Tag color="red">No</Tag>
                                                    :
                                                    <Tag color="grey">Not Applicable</Tag>}

                                            </Col> */}
                                            <Col span={8}>
                                                <h6>Within State</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.renderTag(this.state.within_state)}
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            {/* <Col span={8}>
                                                <h6>Currently Farming</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.state.points ?
                                                    <Tag color="red">No</Tag>
                                                    :
                                                    <Tag color="grey">Not Applicable</Tag>}

                                            </Col> */}
                                            <Col span={8}>
                                                <h6>Within Country</h6>
                                            </Col>
                                            <Col span={16}>
                                                {this.renderTag(this.state.within_country)}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                :
                                <Col span={6}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <h3 style={{ marginRight: 15, marginBottom: 0 }}>{this.state.farm_id}</h3>
                                        {this.props.userStore.email === "akufarmers@gmail.com" &&
                                            <Button type="primary" onClick={() => { }} style={{background: '#009688'}}>Resolve</Button>
                                        }
                                    </div>
                                    <br />
                                    <div>
                                        {this.state.points ? <Tag color="green">Mapped</Tag> : <Tag color="red">Not Mapped</Tag>}
                                    </div>
                                    <br />
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Link to={`/farmers/${this.state.farmer.farmer_id}`}><h6 style={{ marginRight: 15, marginBottom: 0, color: "#1890ff" }}>Farmer: {this.state.farmer.first_name} {this.state.farmer.last_name}</h6></Link>
                                        </div>
                                    </div>
                                </Col>
                            }

                            <Col span={12}>
                                <div className="add-form">
                                    <Title style={{ color: '#2A3F54' }} level={3}>Edit Farm Unit</Title>
                                    <Form onSubmit={this.handleSubmit} className={classes.form}>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="State">
                                                    <Select defaultValue="Select State" value={this.state.selectedState} onChange={(value) => {
                                                        this.setState({ selectedState: value })
                                                        // this.setLGA(value)
                                                    }}>
                                                        {this.state.states.map((item) => {
                                                            return (
                                                                <Option key={item.state_id} value={item.state_id}>{item.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="LGA">
                                                    <Select defaultValue="Select LGA" value={this.state.selectedLGA} onChange={(value) => this.setState({ selectedLGA: value })}>
                                                        {this.setLGAs().map((item) => {
                                                            return (
                                                                <Option key={item.local_id} value={item.local_id}>{item.local_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Address">
                                            <TextArea value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })} />
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Soil Profile (Clay) %">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Phosphorus( mg/L)">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Soil Profile (Loam) %">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Nitrogen ( mg/L)">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Soil Profile (Slit) %">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Potassium (mg/L)">
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Size (Ha)">
                                            <Input value={this.state.size} onChange={(e) => this.setState({ size: e.target.value })} />
                                        </Form.Item>
                                        <Row gutter={24}>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Crop">
                                                    <Select defaultValue="Select Crop" value={this.state.crop ? this.state.crop._id : ""} onChange={(value) => {
                                                        this.setState({ crop: value })
                                                    }}>
                                                        {this.state.cropData.map((item) => {
                                                            return (
                                                                <Option key={item._id} value={item._id}>{item.crop_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{ margin: 0 }}
                                                    label="Farmer">
                                                    <Select disabled defaultValue={this.state.farmer._id} onChange={(value) => this.setState({ selectedFarmer: value })}>
                                                        {this.state.farmer && <Option value={this.state.farmer._id}>{this.state.farmer.first_name} {this.state.farmer.last_name}</Option>}
                                                        {this.state.farmers.map((item) => {
                                                            return (
                                                                <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item style={{ margin: 0 }}>
                                            <Button disabled={disableButton} className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                                {this.state.loading ? "" : "Submit"}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    }
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(EditFarm);
