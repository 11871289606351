import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb, Row, Col, Card, Tag, Button, notification, Modal, Input, Form } from 'antd';
import axios from 'axios';
import classNames from 'classnames';
import { pickBy, identity } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Map from '../farm/FarmMap';
import config from '../../config'

const { TextArea } = Input;


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    appBar: {
        background: '#203a45',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
});

function VerificationDetails(props) {

    const [loadingAccept, setLoadingAccept] = useState(false)
    const [loadingReject, setLoadingReject] = useState(false)
    const [visible, setVisible] = useState(false)
    const [rejectionReason, setRejectionReason] = useState("")

    const { classes } = props;

    const { state } = props.location

    const renderCoords = (coords) => {
        const coordsArray = coords.split(',')
        return coordsArray.map((coord, i) => <p key={i}>{coord}</p>)
    }

    const getPoints = () => {
        const { coord } = props.location.state
        const coordPair = coord.split(",")
        const polygon = coordPair.map(pair => {
            const obj = {
                lat: parseFloat(pair.split(":")[0]),
                lng: parseFloat(pair.split(":")[1])
            }
            return obj
        })

        return polygon

    }

    const renderVerificationCheck = (data) => {
        switch (data) {
            case "YES":
                return <Tag color="green">{data}</Tag>
            case "NO":
                return <Tag color="red">{data}</Tag>
            default:
                return <Tag color="orange">{data}</Tag>
        }

    }

    const renderWithin = (data) => {
        if (data === "true") {
            return <Tag color="green">True</Tag>
        }
        else {
            return <Tag color="red">False</Tag>
        }
    }

    const action = async (action) => {
        action === "YES" ? setLoadingAccept(true) : setLoadingReject(true)
        const body = {
            status: action,
            reject_reason: rejectionReason,
        }
        const data = pickBy(body, identity)
        try {
            const res = await axios.put(`${config.baseUrl}/tie/data/${state.reference}`, data)
            if (res.data.status.code !== 200) {
                return openNotificationWithIcon('error', res.data.status.desc)
            }
            else {
                openNotificationWithIcon('success', res.data.status.desc)
            }
            action === "YES" ? setLoadingAccept(false) : setLoadingReject(false)
            setVisible(false)
        }
        catch (e) {
            action === "YES" ? setLoadingAccept(false) : setLoadingReject(false)
            openNotificationWithIcon('error', e.message)
        }

    }

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message
        });
    };


    const points = getPoints()
    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                    [classes.appBarShift]: false,
                })}
            >
                <Toolbar>

                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Verification Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>Unique ID: {state.reference}</h5>
                            <div style={{ display: 'flex' }}>
                                <Button loading={loadingAccept} onClick={() => action("YES")} type="primary">Accept</Button>
                                <Button loading={loadingReject} onClick={() => setVisible(true)} style={{ marginLeft: 10 }} type="danger">Reject</Button>
                            </div>
                        </div>

                        <Row>
                            <Col span={12}>
                                {points && points.length &&
                                    <Map
                                        height="40vh"
                                        points={points}

                                    />
                                }
                            </Col>
                            <Col span={1}>
                            </Col>
                            <Col span={11}>
                                <Card title="User Provided Info" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}> Partner</h6>
                                                <p>{state.partner}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}> Crop</h6>
                                                <p> {state.crop}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800, cursor: 'pointer' }}> Coordinates</h6>
                                                {renderCoords(state.coord)}
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>Country</h6>
                                                <p>{state.country || "N/A"}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>State</h6>
                                                <p>{state.state || "N/A"}</p>
                                            </div>
                                            <div>
                                                <h6 style={{ fontWeight: 800 }}>LGA</h6>
                                                <p>{state.lga || "N/A"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 50 }}></div>
                        <Row gutter={24}>
                            <Col span={11}>
                                <Card title="Actions" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Vegetation Check:</h6>
                                        <h6>{renderVerificationCheck(state.status)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within Country:</h6>
                                        <h6>{renderWithin(state.within_country)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within State:</h6>
                                        <h6>{renderWithin(state.within_state)}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Within LGA:</h6>
                                        <h6>{renderWithin(state.within_lga)}</h6>
                                    </div>
                                </Card>

                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Card title="Returned Info" bordered={false} headStyle={{ fontWeight: 800 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>Country:</h6>
                                        <h6>{state.returned_country}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>State:</h6>
                                        <h6>{state.returned_state}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>LGA:</h6>
                                        <h6>{state.returned_lga}</h6>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
                                        <h6 style={{ fontWeight: 800 }}>NDVI Value:</h6>
                                        <h6>{state.nvdi_value}</h6>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Modal
                        title="Reason for rejection"
                        visible={visible}
                        onOk={() => action("NO")}
                        confirmLoading={loadingReject}
                        okText={"Submit"}
                        onCancel={() => setVisible(false)}
                    >
                        <Form.Item
                            style={{ margin: 0 }}
                            label="Reason for rejection">
                            <TextArea value={rejectionReason} onChange={(e) => setRejectionReason(e.target.value)} />
                        </Form.Item>
                    </Modal>
                </div>
            </main>
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(VerificationDetails);
