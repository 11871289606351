import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import { Button, Typography, Table, Tooltip, Icon, Breadcrumb, Input } from 'antd';
// import { CSVLink } from "react-csv";
import config from '../../config'

const { Title } = Typography;
const { Column } = Table


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    searchBar: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    button: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff'
    }
});

@inject('userStore')
@observer
class SubClusters extends React.Component {

    state = {
        isLoading: true,
        data: [],
        query: "",
        pagination: { pageSize: 10 },
        pageUrl: 0
    }

    componentDidMount() {
        this.fetchSubClusters()

    }

    fetchSubClusters = async () => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/subclusters`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.iTotalRecords
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page + 1)//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                data: res.data.aaData,
                pagination,
                isLoading: false,
            })
        }).catch((err) => {
            console.log(err)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleTableChange = async (pagination) => {  // pagination here will be 2 if 2 is clicked
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        await this.setState({
            pagination: pager,
            pageUrl: pagination.current - 1
        });
        this.fetchMore(pagination)
    }

    fetchMore = async (pagination) => {
        let pageSize;
        if (pagination) {
            pageSize = pagination.pageSize
            this.setState({ pagination: { ...this.state.pagination, pageSize } })
        }
        else {
            pageSize = this.state.pagination.pageSize
        }
        this.setState({ isLoading: true })
        const pageUrl = this.state.pageUrl

        await axios({
            url: `${config.baseUrl}/subclusters?${pageUrl === 0 ? '' : `page=${pageUrl}`}&per_page=${pageSize}&query=${this.state.query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res.data.iTotalRecords)
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                data: res.data.aaData,
                pagination,
                isLoading: false,
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ isLoading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    handleSearch = async (query) => {
        this.setState({ isLoading: true })
        await axios({
            url: `${config.baseUrl}/subclusters?query=${query}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(async res => {
            if (res.status !== 200) {
                this.setState({ isLoading: false })
                return this.props.userStore.openNotificationWithIcon('error', "Error") //Fix this
            }
            const pagination = { ...this.state.pagination };
            pagination.total = parseInt(res.data.iTotalRecords)
            pagination.pageSizeOptions = ['10', '20', '50', '100'];
            pagination.showSizeChanger = true;
            pagination.current = parseInt(res.data.current_page) + 1//sets pagination page to 1
            pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
            this.setState({
                data: res.data.aaData,
                pagination,
                isLoading: false,
            })
        }).catch((err) => {
            console.log(err.response)
            this.setState({ loading: false })
            return this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        // const headers = [
        //     { label: "Sub Cluster ID", key: "sub_cluster_id" },
        //     { label: "Tenant ID", key: "tenant_id" },
        //     { label: "Name", key: "name" },
        //     { label: "Description", key: "description" },
        //     { label: "Size", key: "size" },
        //     { label: "Agent First Name", key: "agent.first_name" },
        //     { label: "Agent Last Name", key: "agent.last_name" },
        //     { label: "Cluster", key: "cluster.name" }
        // ];
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/farms">Manage Farms</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Manage Sub Clusters
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <Title style={{ color: '#2A3F54' }} level={3}>Farm Sub Clusters</Title>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link to="/farms/sub-clusters/add">
                            <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                                Add New Sub Cluster
                            </Button>
                        </Link>
                        <div>
                            <Input className={classes.searchBar} placeholder="Search" onChange={(e) => {
                                this.setState({
                                    query: e.target.value
                                }, () => {
                                    this.fetchMore();
                                });
                            }} />
                        </div>
                        {/* <CSVLink filename={"subclusters.csv"} data={this.state.data} headers={headers}>
                            Download me
                        </CSVLink> */}
                    </div>
                    <div className='table-container' style={{ padding: 24, background: '#fff' }}>
                        <Title style={{ color: '#2A3F54' }} level={4}>All Sub Clusters</Title>
                        <Table
                            bordered
                            loading={this.state.isLoading}
                            onChange={this.handleTableChange}
                            pagination={this.state.pagination}
                            rowKey={record => record._id}
                            dataSource={this.state.data}>
                            <Column
                                title="S/N"
                                dataIndex="_id"
                                key="_id"
                                render={(text, record, index) => (
                                    <span>
                                        {index + 1}
                                    </span>
                                )}
                            />
                            <Column
                                title="Name"
                                dataIndex="name"
                                key="name"
                            />
                            <Column
                                title="Description"
                                dataIndex="description"
                                key="description"
                            />
                            <Column
                                title="ID"
                                dataIndex="sub_cluster_id"
                                key="sub_cluster_id"
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                            />
                            <Column
                                title="Agent"
                                dataIndex="agent"
                                key="agent"
                                render={(text, record) => (
                                    <span>
                                        {text ? `${text.first_name} ${text.last_name}` : ""}
                                    </span>
                                )}
                            />
                            <Column
                                title="Action"
                                key="action"
                                render={(text) => (
                                    <span>
                                        <Tooltip title="View Lots">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/sub-clusters/${text._id}`, text)}>
                                                <Icon type="eye" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Edit Sub Cluster">
                                            <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/farms/sub-clusters/edit/${text._id}`, text)}>
                                                <Icon type="edit" style={{ color: '#2A3F54' }} />
                                            </Button>
                                        </Tooltip>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(SubClusters);
