import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Table, Icon, Tooltip, Popconfirm, Breadcrumb, Input, Modal } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import { Link } from 'react-router-dom'
import moment from 'moment';
import axios from 'axios'
import config from '../../config'

const { Title } = Typography;
const { Column } = Table

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    marginRight: 3,
    color: '#fff'
  },
  searchBar: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  }
});


@inject('userStore')
@observer
class Users extends React.Component {

  state = {
    data: [],
    pagination: { pageSize: 10 },
    isLoading: false,
    pageUrl: 0,
    selectedFile: null,
    visible: false,
    loadingUpload: false,
  }

  componentDidMount() {
    this.fetchUsers()
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleOk = () => {
    this.uploadFile()
  }

  onPickFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    })
  }

  uploadFile = async () => {
    if (!this.state.selectedFile) {
      return this.props.userStore.openNotificationWithIcon('error', "Please select a file to upload")
    }
    this.setState({ loadingUpload: true })
    const data = new FormData()
    data.append('file', this.state.selectedFile)
    await axios({
      url: `${config.baseUrl}/accounts`,
      method: 'POST',
      data,
      headers: {
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      console.log(res)
      this.setState({ loadingUpload: false, visible: false })
      this.fetchUsers()
      return this.props.userStore.openNotificationWithIcon('info', res.data.status.desc)
    }).catch(err => {
      this.setState({ loadingUpload: false })
      console.log(err.response)
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  fetchUsers = async () => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/users`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(async res => {
      if (res.data.status.code !== 100) {
        this.setState({ isLoading: false })
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.entity.total_records
      pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
      pagination.showTotal = (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
      await this.setState({
        isLoading: false,
        data: res.data.entity.users,
        pagination
      })
    }).catch((err) => {

      this.setState({ isLoading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  handleTableChange = async (pagination) => {  // pagination here will be 2 if 2 is clicked
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;

    await this.setState({
      pagination: pager,
      pageUrl: pagination.current - 1
    });
    this.fetchMore()
  }

  fetchMore = async () => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/users?page=${this.state.pageUrl}`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      this.setState({
        isLoading: false,
        data: res.data.entity.users,
      })
    }).catch((err) => {

      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  handleSearch = async (query) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/users?query=${query}`,
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(async res => {

      if (res.data.status.code !== 100) {
        this.setState({ isLoading: false })
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.entity.total_records
      pagination.current = parseInt(res.data.entity.current_page + 1)//sets pagination page to 1
      await this.setState({
        isLoading: false,
        data: res.data.entity.users,
        pagination
      })
    }).catch((err) => {
      this.setState({ loading: false })
      return this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  confirmDelete = async (user) => {
    this.setState({ isLoading: true })
    await axios({
      url: `${config.baseUrl}/account/${user._id}`,
      method: 'DELETE',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then(res => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('success', res.data.status.desc)

    }).catch(err => {
      this.setState({ isLoading: false })
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  downloadFile = () => {
    axios({
      url: 'https://test.tradebuza.com/files/download/userTemplate.xlsx',
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'USER UPLOAD TEMPLATE.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
            <Breadcrumb>
              <Breadcrumb.Item>
                User Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div>
            <Title style={{ color: '#2A3F54' }} level={3}>User Management</Title>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {this.props.userStore.role.role === "Project Manager" ? null :
              <div>
                <Link to="/users/add">
                  <Button type="primary" className={classes.button} style={{background: '#009688'}}>
                    Create New User
                </Button>
                </Link>
                <Button type="primary" onClick={() => this.setState({ visible: true })} className={classes.button} style={{background: '#009688'}}>
                  Upload Users
                  </Button>
              </div>

            }

            <div>
              <Input className={classes.searchBar} placeholder="Search" onChange={(e) => this.handleSearch(e.target.value)} />
            </div>
          </div>
          <div className='table-container' style={{ padding: 24, background: '#fff' }}>
            <Title style={{ color: '#2A3F54' }} level={4}>All Users</Title>
            <Table
              bordered
              rowKey={record => record._id}
              loading={this.state.isLoading}

              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              dataSource={this.state.data}>
              {/* <Column
                title="S/N"
                dataIndex="_id"
                key="_id"
                render={(text, record, index) => (
                  <span>
                    {index + 1}
                  </span>
                )}
              /> */}
              <Column
                title="First Name"
                dataIndex="first_name"
                key="first_name"
              />
              <Column
                title="Last Name"
                dataIndex="last_name"
                key="last_name"
              />
              <Column
                title="Email"
                dataIndex="email"
                key="email"
              />
              <Column
                title="Group"
                dataIndex="role.role"
                key="role.role"
              />
              <Column
                title="Association"
                dataIndex="association"
                key="association"
              />
              <Column
                title="State"
                dataIndex="state.name"
                key="state.name"
              />
              <Column
                title="Last Login"
                dataIndex="last_login"
                key="last_login"
                render={(text, record) => (
                  <span>
                    {text ? moment(text).format('DD/MM/YYYY, h:mm:A') : null}
                  </span>
                )}
              />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => (
                  <span>
                    <Tooltip title="Edit User">
                      <Button style={{ marginRight: 2 }} type="default" onClick={() => this.props.history.push(`/users/edit/${text._id}`, text)}>
                        <Icon type="edit" style={{ color: '#2A3F54' }} />
                      </Button>
                    </Tooltip>
                    <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.confirmDelete(text)} okText="Yes" cancelText="No">
                      <Button style={{ marginLeft: 2 }} type="default">
                        <Icon type="delete" style={{ color: '#ea1b2a' }} />
                      </Button>
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
            <Modal
              title="Upload Excel File (.xls or .xlsx)"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              footer={[
                <Button key="back" onClick={this.handleCancel}>
                  Cancel
                </Button>,
                <Button loading={this.state.loadingUpload} key="ok" onClick={this.handleOk}>
                  Upload
                </Button>,
                <Button key="download" onClick={this.downloadFile}>
                  Download Template
                </Button>,
              ]}
            >
              <input accept=".xls,.xlsx" type="file" onChange={this.onPickFile} />
              <br />
            </Modal>
          </div>
        </main>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(Users);
