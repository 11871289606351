import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Breadcrumb, Row, Col, Spin, Select, DatePicker, Input, Tabs } from 'antd';
import moment from 'moment';
import Drawer from './Drawer'
// import { useStates, useCrops, usePolygons, useSeasons, useAssociations, formatNumber } from '../../config/utils';
import MapView from './PolygonMapView';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;
const { TabPane } = Tabs;

// const user = localStorage.getItem('user-auth')
// const parsedUser = JSON.parse(user)


const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    passed: {
        border: '1px solid green',
        color: 'green',
        padding: '3px 5px',
        borderRadius: 4
    },
    failed: {
        border: '1px solid red',
        color: 'red',
        padding: '3px 5px',
        borderRadius: 4
    },
    maybe: {
        border: '1px solid orange',
        color: 'orange',
        padding: '3px 5px',
        borderRadius: 4
    }
});

function Polygons(props) {
    // const [state, changeState] = useState(null)
    // const [crop, setCrop] = useState(null)
    // const [season, setSeason] = useState(null)
    // const [association, setAssociation] = useState(null)
    // const [date, setDate] = useState([])
    // const { states, } = useStates("https://dashboard.tradebuza.com/api/v2/states")
    // const { crops, } = useCrops("https://dashboard.tradebuza.com/process/opeissue/fetchCrops/dvc")
    // const { seasons, } = useSeasons("https://dashboard.tradebuza.com/api/v2/project_names", parsedUser.token)
    // const { associations } = useAssociations(state ? `https://test.tradebuza.com/api/v2/farmers/client_name?state=${state}` : "https://test.tradebuza.com/api/v2/farmers/client_name/all", parsedUser.token)


    const { classes } = props;

    // const filterState = state ? `&state=${state}` : ''
    // const filterCrop = crop ? `&crop=${crop}` : ''
    // const filterSeason = season ? `&season=${season}` : '';
    // const filterAssoc = association ? `&association=${association}` : '';
    // const filterDateFrom = date && date.length && date[0] ? `&date_from=${date[0]}` : '';
    // const filterDateTo = date && date.length && date[1] ? `&date_to=${date[1]}` : '';
    // const filterPath = filterState + filterCrop + filterSeason + filterAssoc + filterDateFrom + filterDateTo;

    // const { data, isLoading: loading } = usePolygons(`https://dashboard.tradebuza.com/process/opeissue?data=0${filterPath}`, parsedUser.token)

    const renderStatus = (status) => {
        if (status === "Passed") {
            return (
                <p className={classes.passed}> {status}</p>
            )
        }
        else if (status === "Failed") {
            return (
                <p className={classes.failed}>{status}</p>
            )
        }
        else {
            return (
                <p className={classes.maybe}>{status}</p>

            )
        }
    }
    return (
        <div className={classes.root}>
            <Drawer />
            <main className={classes.content}>
                {/* <div className={classes.toolbar} /> */}
                <div style={{ margin: '30px 12px', padding: 24, background: '#fff' }}>
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>Mapping Insights</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div>
                        <div style={{ display: 'flex', marginBottom: 20, justifyContent: 'space-between' }}>
                            <Select

                                defaultValue="Partner"
                                onChange={(value) => {

                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Farm Mapped</Option>
                                <Option value="0">Farm Not Mapped</Option>
                            </Select>
                            <Select

                                defaultValue="Status"
                                onChange={(value) => {

                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Farm Mapped</Option>
                                <Option value="0">Farm Not Mapped</Option>
                            </Select>
                            <Select

                                defaultValue="State"
                                onChange={(value) => {

                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Farm Mapped</Option>
                                <Option value="0">Farm Not Mapped</Option>
                            </Select>
                            <Select

                                defaultValue="Association"
                                onChange={(value) => {

                                }}
                            >
                                <Option value="">All</Option>
                                <Option value="1">Farm Mapped</Option>
                                <Option value="0">Farm Not Mapped</Option>
                            </Select>
                            <RangePicker
                                style={{ width: '20%' }}
                                defaultValue={[]}
                                ranges={{
                                    Today: [moment(), moment()],
                                    'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                    'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                    'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                    'All Time': [],
                                }}
                                onChange={(_, date) => {

                                }} />

                            <Search placeholder="Search" style={{ width: '20%' }} />
                        </div>
                        <Row>
                            <Col span={14}>
                                <div className="table-container">
                                    {false ? <Spin /> :
                                        <MapView data={{ data: [] }} />
                                    }
                                </div>
                            </Col>

                            <Col span={10}>
                                <div className="table-container">
                                    <p style={{ fontWeight: '800' }}>Unique ID: <span style={{ fontWeight: 'normal' }}>CAN/123/456</span></p>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <p style={{ fontWeight: '800' }}>Overall Result:</p>
                                        <p style={{ color: 'red' }}>FAILED</p>
                                    </div>
                                    <br />
                                    <div style={{ border: '1px solid #ccc', padding: 10 }}>
                                        <div>
                                            <h6 style={{ fontWeight: 800 }}>ACTIONS</h6>
                                            <hr />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ fontWeight: 800 }}>Vegetation Check:</p>
                                                {renderStatus("Passed")}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ fontWeight: 800 }}>Within Country:</p>
                                                {renderStatus("Failed")}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ fontWeight: 800 }}>Within State:</p>
                                                {renderStatus("Passed")}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ fontWeight: 800 }}>Within LGA:</p>
                                                {renderStatus("Passed")}
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <p style={{ fontWeight: 800 }}>Overlay Check:</p>
                                                {renderStatus("Passed")}
                                            </div>
                                        </div>
                                        <Tabs type="card">
                                            <TabPane tab="User Details" key="1">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>State:</p>
                                                    <p>Abuja</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>LGA:</p>
                                                    <p>Abuja Municipal</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>Address:</p>
                                                    <p> Bwari, Abuja</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>Size:</p>
                                                    <p>5.37 Ha</p>
                                                </div>
                                            </TabPane>
                                            <TabPane tab="Returned Info" key="2">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>State:</p>
                                                    <p>Abuja</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>LGA:</p>
                                                    <p>Abuja Municipal</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>Crop:</p>
                                                    <p>Maize</p>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <p style={{ fontWeight: 800 }}>Association:</p>
                                                    <p>MAGPAMAN</p>
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </main >
        </div >
    )
}

export default withStyles(styles, { withTheme: true })(Polygons);
