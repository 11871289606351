import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import { Helmet } from "react-helmet";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { inject, observer } from "mobx-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ExpandLess from "@material-ui/icons/ExpandLess";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToolbox,
  faTachometerAlt,
  faUserCog,
  faTractor,
  faPeopleCarry,
  faReceipt,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Typography as Title, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";
import config from "../../config/index";

const drawerWidth = 280;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "white",
    boxShadow: "none",
    border: "1px solid #DDDDDD",
    color: "#009688",
    // background: 'linear-gradient(to right, #2A3F54,  #08667E)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 3,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 8,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    backgroundColor: "white",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2,
  },
  appBarName: {
    fontSize: 14,
    paddingLeft: theme.spacing.unit,
    color: "009688",
  },
});

@inject("userStore")
@observer
class MiniDrawer extends React.Component {
  static contextType = AuthContext;

  state = {
    open: false,
    openList0: false,
    openList: false,
    openList2: false,
    openList3: false,
    openList4: false,
    openList5: false,
    openList6: false,
    openList7: false,
    openList8: false,
    openList9: false,
    anchorEl: null,
    openBar: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false, openList: false, openList2: false });
  };

  handleOpenList0 = () => {
    this.setState((state) => ({ openList0: !state.openList0, open: true }));
  };

  handleOpenList = () => {
    this.setState((state) => ({ openList: !state.openList, open: true }));
  };
  handleOpenList2 = () => {
    this.setState((state) => ({ openList2: !state.openList2, open: true }));
  };
  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleOpenList3 = () => {
    this.setState((state) => ({ openList3: !state.openList3, open: true }));
  };
  handleOpenList4 = () => {
    this.setState((state) => ({ openList4: !state.openList4, open: true }));
  };

  handleOpenList5 = () => {
    this.setState((state) => ({ openList5: !state.openList5, open: true }));
  };
  handleOpenList6 = () => {
    this.setState((state) => ({ openList6: !state.openList6, open: true }));
  };
  handleOpenList7 = () => {
    this.setState((state) => ({ openList7: !state.openList7, open: true }));
  };
  handleOpenList8 = () => {
    this.setState((state) => ({ openList8: !state.openList8, open: true }));
  };

  handleOpenList9 = () => {
    this.setState((state) => ({ openList9: !state.openList9, open: true }));
  };

  logout = () => {
    this.logoutHandler();
    localStorage.clear();
    this.context.logout();
    this.props.history.push("/");
  };

  goToProfile = () => {
    this.props.history.push("/profile");
  };

  logoutHandler = async () => {
    await axios({
      url: `${config.baseUrl}/account/logout`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {})
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  };

  renderImageSrc = (environment, userStore) => {
    const { hostname } = window.location;
    if (hostname.includes("tradebuza.com") || hostname === "localhost") {
      if (environment === "staging") {
        return "https://test.tradebuza.com/build/images/hazie.png";
      }
      return "https://test.tradebuza.com/build/images/t_logo.png";
    } else {
      if (userStore.tenant && userStore.tenant.logo) {
        return userStore.tenant.logo;
      } else {
        return "https://test.tradebuza.com/build/images/t_logo.png";
      }
    }
  };

  renderTitle = (tenant, env) => {
    if (tenant && tenant.name) {
      if (
        tenant._id === 74 &&
        window.location.hostname.includes("tradebuza.com")
      ) {
        return "Tradebuza Capture";
      }
      return tenant.name;
    } else {
      if (env === "staging") {
        return "Hazie";
      }
      return "Tradebuza";
    }
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const openBar = Boolean(anchorEl);

    const environment = process.env.REACT_APP_CUSTOM_NODE_ENV;

    const { userStore } = this.props;
    const { role } = this.props.userStore.role;
    // const initial =
    //   userStore.firstname.split("")[0] + userStore.lastname.split("")[0];
    // console.log(initial, role);

    console.log("role 1234", role);

    return (
      <div className={classes.root}>
        <Helmet>
          <title>
            {this.renderTitle(userStore.tenant, environment)}
            {/* {userStore.tenant && userStore.tenant.name ? userStore.tenant.name : environment === "staging" ? "Hazie" : "Tradebuza"} */}
          </title>
        </Helmet>
        <CssBaseline />
        <AppBar
          position='fixed'
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <div style={{ marginLeft: "auto" }}>
              <IconButton
                style={{ outline: 0 }}
                aria-owns={openBar ? "menu-appbar" : undefined}
                aria-haspopup='true'
                onClick={this.handleMenu}
                color='inherit'
              >
                <Icon style={{ fontSize: 20 }} type='user' />
                <div style={{ marginLeft: "5px" }}>
                  <Title
                    style={{
                      color: "#6C757D",
                      fontWeight: 800,
                      fontSize: 14,
                      margin: "0 5px",
                    }}
                  >
                    {userStore.firstname} {userStore.lastname}
                  </Title>
                  {/* <Title style={{ color: '#009688', fontSize: 18, margin: '0 5px' }}> | </Title> */}
                  <Title
                    style={{ color: "#6C757D", fontSize: 12, fontWeight: 500 }}
                  >
                    {role}
                  </Title>
                </div>
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openBar}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.goToProfile}>
                  <Title
                    style={{ color: "#2A3F54", fontSize: 16, margin: "0 10px" }}
                  >
                    Profile
                  </Title>
                </MenuItem>
                <MenuItem onClick={this.logout}>
                  <Title
                    style={{ color: "#2A3F54", fontSize: 16, margin: "0 10px" }}
                  >
                    Logout
                  </Title>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant='permanent'
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <div className='dashboard-logo'>
              <Link to='/'>
                <img
                  src={this.renderImageSrc(environment, userStore)}
                  className={
                    environment === "staging" ? "hazie-img" : "responsive-img"
                  }
                  // src={userStore.tenant && userStore.tenant.logo ?
                  //   userStore.tenant.logo
                  //   :
                  //   environment === "staging" ?
                  //     "https://test.tradebuza.com/build/images/hazie.png"
                  //     :
                  //     "https://test.tradebuza.com/build/images/t_logo.png"}
                  alt='session-logo'
                />
              </Link>
            </div>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          {/* <div className={classes.toolbar} >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#009688', width: '50px', height: '50px', margin: '0 auto', textAlign: 'center', padding: '10px', borderRadius: '50%', fontSize: '20px', color: "#fff", fontWeight: 700 }}> {initial} </div>
          </div> */}

          <Divider />
          {/* Dvalco Admin Dashboard */}
          {(role === "Dvalco Admin" ||
            role === "Service Provider" ||
            role === "Guest") && (
            <List>
              <ListItem button onClick={this.handleOpenList7}>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faTachometerAlt}
                    style={{ fontSize: 20 }}
                  />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
                {this.state.openList7 ? (
                  <ExpandLess className='expand' />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse in={this.state.openList7} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Link to='/dashboard' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Programme Overview
                      </Title>
                    </ListItem>
                  </Link>
                  {role !== "Guest" && (
                    <>
                      <Link
                        to='/dashboard/gps'
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            GPS Capture Overview
                          </Title>
                        </ListItem>
                      </Link>
                      <Link
                        to='/dashboard/polygons'
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            GPS Captured Map
                          </Title>
                        </ListItem>
                      </Link>
                    </>
                  )}
                </List>
              </Collapse>
            </List>
          )}
          {/* Admin Dashbard */}
          {role === "Admin" && (
            <div>
              <List>
                <ListItem button onClick={this.handleOpenList7}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faTachometerAlt}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Dashboard' />
                  {this.state.openList7 ? (
                    <ExpandLess className='expand' />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={this.state.openList7}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    <Link to='/dashboard' style={{ textDecoration: "none" }}>
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Map View
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/dashboard/admin'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Admin Dashboard
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/dashboard/overview'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Programme Overview
                        </Title>
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </List>
              {/* <List>
                <Link style={{ textDecoration: 'none' }} to="/service-providers">
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faToolbox} style={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary="Service Providers" />
                  </ListItem>
                </Link>
              </List> */}
            </div>
          )}
          {/* Aggregator Dashboard */}
          {role === "Aggregator" && (
            <div>
              <List>
                <Link style={{ textDecoration: "none" }} to='/dashboard'>
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faToolbox}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' />
                  </ListItem>
                </Link>
              </List>
            </div>
          )}
          {role === "Remote Sensing Engineers" && (
            <div>
              <List>
                <Link style={{ textDecoration: "none" }} to='/dashboard'>
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faToolbox}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Vegetation Check' />
                  </ListItem>
                </Link>
              </List>
              <List>
                {/* <Link style={{ textDecoration: 'none' }} to=""> */}
                <ListItem button>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faToolbox}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='River Proximity' />
                </ListItem>
                {/* </Link> */}
              </List>
            </div>
          )}
          {role === "Remote Sensing Engineer" && (
            <div>
              <List>
                <Link style={{ textDecoration: "none" }} to='/dashboard'>
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faToolbox}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Review Farms' />
                  </ListItem>
                </Link>
              </List>
            </div>
          )}
          {role !== "Remote Sensing Engineers" &&
            role !== "Remote Sensing Engineer" && (
              <List>
                <ListItem button onClick={this.handleOpenList}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faUserCog}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='User Management' />
                  {this.state.openList ? (
                    <ExpandLess className='expand' />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse in={this.state.openList} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {role !== "Guest" && (
                      <Link to='/users' style={{ textDecoration: "none" }}>
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Users
                          </Title>
                        </ListItem>
                      </Link>
                    )}
                    <div>
                      <Link to='/farmers' style={{ textDecoration: "none" }}>
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Farmers
                          </Title>
                        </ListItem>
                      </Link>
                      {role === "Aggregator" && (
                        <Link
                          style={{ textDecoration: "none" }}
                          to='/sourcing-agents'
                        >
                          <ListItem button className={classes.nested}>
                            <Title
                              style={{
                                color: "#2A3F54",
                                fontSize: 16,
                                margin: "0 40px",
                              }}
                            >
                              Manage Sourcing Agents
                            </Title>
                          </ListItem>
                        </Link>
                      )}
                      {role === "Admin" && (
                        <Link to='/groups' style={{ textDecoration: "none" }}>
                          <ListItem button className={classes.nested}>
                            <Title
                              style={{
                                color: "#2A3F54",
                                fontSize: 16,
                                margin: "0 40px",
                              }}
                            >
                              Manage Roles
                            </Title>
                          </ListItem>
                        </Link>
                      )}
                    </div>
                  </List>
                </Collapse>
              </List>
            )}

          {role === "Admin" && environment === "staging" && (
            <List>
              <Link style={{ textDecoration: "none" }} to='/farms'>
                <ListItem button>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faPeopleCarry}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Farmers' />
                </ListItem>
              </Link>
            </List>
          )}
          {/* Configuration Module */}
          {role === "Aggregator" || role === "Service Provider" ? (
            <List>
              <ListItem button onClick={this.handleOpenList2}>
                <ListItemIcon>
                  <Icon style={{ fontSize: 20 }} type='setting' />
                </ListItemIcon>
                <ListItemText primary='Configuration' />
                {this.state.openList2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openList2} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Link to='/crop_profiles' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Manage Crop Profiles
                      </Title>
                    </ListItem>
                  </Link>
                  <Link
                    to='/farm_activities'
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Manage Crop Calendar
                      </Title>
                    </ListItem>
                  </Link>
                  <Link to='/seasons' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Manage Farm Seasons
                      </Title>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          ) : null}

          {/* Farm Management */}
          {environment !== "staging" &&
            role !== "Guest" &&
            role !== "Remote Sensing Engineer" && (
              <List>
                <ListItem button onClick={this.handleOpenList3}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faTractor}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Farm Management' />
                  {this.state.openList3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={this.state.openList3}
                  timeout='auto'
                  unmountOnExit
                >
                  {role === "Aggregator" ||
                  role === "Project Manager" ||
                  role === "Service Provider" ||
                  role === "Dvalco Admin" ? (
                    <List component='div' disablePadding>
                      <Link to='/farms' style={{ textDecoration: "none" }}>
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Farm Lots
                          </Title>
                        </ListItem>
                      </Link>
                      <Link
                        to='/farms/clusters'
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Clusters
                          </Title>
                        </ListItem>
                      </Link>
                      <Link
                        to='/farms/sub-clusters'
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Sub Clusters
                          </Title>
                        </ListItem>
                      </Link>
                    </List>
                  ) : (
                    <List component='div' disablePadding>
                      <Link to='/tenants' style={{ textDecoration: "none" }}>
                        <ListItem button className={classes.nested}>
                          <Title
                            style={{
                              color: "#2A3F54",
                              fontSize: 16,
                              margin: "0 40px",
                            }}
                          >
                            Manage Farm Tenants
                          </Title>
                        </ListItem>
                      </Link>
                    </List>
                  )}
                </Collapse>
              </List>
            )}

          {environment === "staging" &&
            role !== "Admin" &&
            role !== "Remote Sensing Engineers" && (
              <List>
                <ListItem button onClick={this.handleOpenList3}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faTractor}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Farm Management' />
                  {this.state.openList3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={this.state.openList3}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    <Link to='/farms' style={{ textDecoration: "none" }}>
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Manage Farm Lots
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/farms/clusters'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Manage Clusters
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/farms/sub-clusters'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Manage Sub Clusters
                        </Title>
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </List>
            )}
          {(role === "Project Manager" || role === "Dvalco Admin") && (
            <List>
              <ListItem button onClick={this.handleOpenList9}>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faTractor} style={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary='Farm Review' />
                {this.state.openList9 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openList9} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {role === "Project Manager" && (
                    <Link
                      to='/pending-reviews'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Pending Reviews
                        </Title>
                      </ListItem>
                    </Link>
                  )}
                  {role === "Dvalco Admin" && (
                    <Link to='/reviews' style={{ textDecoration: "none" }}>
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Submitted Reviews
                        </Title>
                      </ListItem>
                    </Link>
                  )}
                </List>
              </Collapse>
            </List>
          )}
          {role === "Project Manager" && (
            <List>
              <Link
                to='/farm-cluster-management'
                style={{ textDecoration: "none" }}
              >
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faTractor}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Farm Cluster Management' />
                </ListItem>
              </Link>
            </List>
          )}

          {role === "Agent" && (
            <List>
              <Link style={{ textDecoration: "none" }} to='/farmers-units'>
                <ListItem button>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faReceipt}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Farmer Units' />
                </ListItem>
              </Link>
            </List>
          )}

          {(role !== "Aggregator" && role !== "Remote Sensing Engineer") ||
            role === "Service Provider" ||
            role === "Dvalco Admin" && (
              <List>
                <Link style={{ textDecoration: "none" }} to='/input'>
                  <ListItem button>
                    <ListItemIcon>
                      <FontAwesomeIcon
                        icon={faReceipt}
                        style={{ fontSize: 20 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary='Input Tracking' />
                  </ListItem>
                </Link>
              </List>
            )}

          {role === "Aggregator" ? (
            <List>
              <ListItem button onClick={this.handleOpenList4}>
                <ListItemIcon>
                  <Icon style={{ fontSize: 20 }} type='message' />
                </ListItemIcon>
                <ListItemText primary='Communication' />
                {this.state.openList2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openList4} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Link
                    to='/communication/setup'
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Communication Setup
                      </Title>
                    </ListItem>
                  </Link>
                  <Link
                    to='/communication/new_sms'
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        New Message
                      </Title>
                    </ListItem>
                  </Link>
                  <Link
                    to='/communication/sms_history'
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Sent Messages
                      </Title>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          ) : null}
          {role === "Aggregator" && (
            <List>
              <ListItem button onClick={this.handleOpenList5}>
                <ListItemIcon>
                  <Icon style={{ fontSize: 20 }} type='table' />
                </ListItemIcon>
                <ListItemText primary='Collection' />
                {this.state.openList5 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.openList5} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {/* <Link to="/collections/add" style={{ textDecoration: 'none' }}>
                    <ListItem button className={classes.nested}>
                      <Title style={{ color: '#2A3F54', fontSize: 16, margin: '0 40px' }}>Add New Collection</Title>
                    </ListItem>
                  </Link> */}
                  {/* <Link to="/collections/settings" style={{ textDecoration: 'none' }}>
                    <ListItem button className={classes.nested}>
                      <Title style={{ color: '#2A3F54', fontSize: 16, margin: '0 40px' }}>Collection Settings</Title>
                    </ListItem>
                  </Link> */}
                  <Link to='/collections' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Collections
                      </Title>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          )}
          {role === "Aggregator" && (
            <>
              <List>
                <ListItem button onClick={this.handleOpenList6}>
                  <ListItemIcon>
                    <Icon style={{ fontSize: 20 }} type='bank' />
                  </ListItemIcon>
                  <ListItemText primary='Payments' />
                  {this.state.openList6 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={this.state.openList6}
                  timeout='auto'
                  unmountOnExit
                >
                  <List component='div' disablePadding>
                    {/* <Link to="/collections/add" style={{ textDecoration: 'none' }}>
                  <ListItem button className={classes.nested}>
                    <Title style={{ color: '#2A3F54', fontSize: 16, margin: '0 40px' }}>Add New Collection</Title>
                  </ListItem>
                </Link> */}
                    <Link
                      to='/payments/transactions'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Transactions
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/payments/collections'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Collections
                        </Title>
                      </ListItem>
                    </Link>
                    <Link
                      to='/payments/settings'
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <Title
                          style={{
                            color: "#2A3F54",
                            fontSize: 16,
                            margin: "0 40px",
                          }}
                        >
                          Settings
                        </Title>
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </List>
            </>
          )}
          {role === "Admin" && environment !== "staging" && (
            <List>
              <Link style={{ textDecoration: "none" }} to='/utils'>
                <ListItem button>
                  <ListItemIcon>
                    <Icon style={{ fontSize: 20 }} type='sliders' />
                  </ListItemIcon>
                  <ListItemText primary='Utils' />
                </ListItem>
              </Link>
            </List>
          )}
          {role === "Admin" && environment === "staging" && (
            <List>
              <ListItem button onClick={this.handleOpenList8}>
                <ListItemIcon>
                  <Icon style={{ fontSize: 20 }} type='sliders' />
                </ListItemIcon>
                <ListItemText primary='Manual Input' />
                {this.state.openList8 ? (
                  <ExpandLess className='expand' />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse in={this.state.openList8} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Link to='/manual-input' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Single Map View
                      </Title>
                    </ListItem>
                  </Link>
                  <Link to='/verification' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Batch Verification
                      </Title>
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </List>
          )}
          {role === "Admin" && environment !== "staging" && (
            <List>
              <ListItem button onClick={this.handleOpenList8}>
                <ListItemIcon>
                  <Icon style={{ fontSize: 20 }} type='setting' />
                </ListItemIcon>
                <ListItemText primary='Settings' />
                {this.state.openList8 ? (
                  <ExpandLess className='expand' />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse in={this.state.openList8} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  <Link to='/settings' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        App Settings
                      </Title>
                    </ListItem>
                  </Link>
                  <Link to='/countries' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Countries
                      </Title>
                    </ListItem>
                  </Link>
                  <Link to='/currencies' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        Currencies
                      </Title>
                    </ListItem>
                  </Link>
                  <Link to='/states' style={{ textDecoration: "none" }}>
                    <ListItem button className={classes.nested}>
                      <Title
                        style={{
                          color: "#2A3F54",
                          fontSize: 16,
                          margin: "0 40px",
                        }}
                      >
                        States / Regions
                      </Title>
                    </ListItem>
                  </Link>
                  {/* <Link to="/lgas" style={{ textDecoration: 'none' }}>
                    <ListItem button className={classes.nested}>
                      <Title style={{ color: '#2A3F54', fontSize: 16, margin: '0 40px' }}>LGAs / Districts</Title>
                    </ListItem>
                  </Link> */}
                </List>
              </Collapse>
            </List>
          )
          // <List>
          //   <Link style={{ textDecoration: 'none' }} to="/settings">
          //     <ListItem button>
          //       <ListItemIcon>
          //         <Icon style={{ fontSize: 20 }} type="setting" />
          //       </ListItemIcon>
          //       <ListItemText primary="Settings" />
          //     </ListItem>
          //   </Link>
          // </List>
          }
          {(role === "Service Provider" || role === "Dvalco Admin") && (
            <List>
              <Link style={{ textDecoration: "none" }} to='/downloads'>
                <ListItem button>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ fontSize: 20 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary='Downloads' />
                </ListItem>
              </Link>
            </List>
          )}
          {role === "Admin" && environment !== "staging" && (
            <List>
              <Link style={{ textDecoration: "none" }} to='/jobs'>
                <ListItem button>
                  <ListItemIcon>
                    <Icon style={{ fontSize: 20 }} type='tool' />
                  </ListItemIcon>
                  <ListItemText primary='Jobs' />
                </ListItem>
              </Link>
            </List>
          )}
          {/* {this.props.children} */}
        </Drawer>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(MiniDrawer));
