import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import Drawer from "../dashboard/Drawer";
import axios from "axios";
import {
  Form,
  Input,
  Typography,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Breadcrumb,
} from "antd";
import { Link } from "react-router-dom";
import config from "../../config";

const Option = Select.Option;
const TextArea = Input.TextArea;
const { Title } = Typography;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  spin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: "#fff",
    width: "100%",
  },
});

@inject("userStore")
@observer
class AddUser extends React.Component {
  state = {
    isFetching: true,
    loading: false,
    crops: null,
    address: "",
    farmer: null,
    size: "",
    states: [],
    locals: [],
    cropData: [],
    farmers: [],
    defaultFarmers: [],
    selectedState: "",
    selectedLGA: "",
    fetchingFarmers: false,
  };

  componentDidMount() {
    this.fetchContent();
  }

  fetchContent() {
    const { tenant } = this.props.userStore;
    const filterState = tenant
      ? `?country=${encodeURIComponent(tenant.country)}`
      : "";
    axios
      .all([
        axios({
          url: `${config.baseUrl}/states${filterState}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/config/crop_profiles`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `${config.baseUrl}/farmers?per_page=5`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
      ])
      .then(
        axios.spread((res1, res2) => {
          this.setState({
            isFetching: false,
            states: res1.data.entity.states,
            cropData: res2.data.entity.profiles,
            // farmers: res3.data.aaData,
            // defaultFarmers: res3.data.aaData
          });
        })
      )
      .then(() => {
        this.setState({ filterFarmers: this.state.farmers });
      });
  }

  setLGA(state_id) {
    axios({
      url: `${config.baseUrl}/lgas/${state_id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ isFetching: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.setState({
          locals: res.data.entity.cities,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        return this.props.userStore.openNotificationWithIcon(
          "error",
          err.message
        );
      });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      state: this.state.selectedState,
      community: this.state.selectedLGA,
      size: this.state.size,
      farmer: this.state.farmer,
      crops: this.state.crops,
      address: this.state.address,
    };
    if (!this.state.selectedState || !this.state.selectedLGA) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "State and LGA required"
      );
    }
    if (!this.state.size) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a Size"
      );
    }
    if (!this.state.farmer) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a farmer"
      );
    }
    if (!this.state.crops) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select crop"
      );
    }
    if (!this.state.address) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply an address"
      );
    }
    this.setState({ loading: true });
    await axios({
      url: `${config.baseUrl}/farm`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.props.userStore.openNotificationWithIcon(
          "success",
          "Successfully added Farm"
        );
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.userStore.openNotificationWithIcon("error", err.message);
      });
  };

  onSearch = async (query) => {
    this.setState({ fetchingFarmers: true });
    await axios({
      url: `${config.baseUrl}/farmers?query=${query}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        // const farmers = res.data.aaData.filter(item => item.farm_lot !== 1)
        this.setState({ fetchingFarmers: false, farmers: res.data.aaData });
      })
      .catch((err) => {
        this.setState({ fetchingFarmers: false });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#f2f3f4",
              padding: 10,
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/farms'>Manage Farms</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Add New Farm Unit</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {this.state.isFetching ? (
            <div className={classes.spin}>
              <Spin />
            </div>
          ) : (
            <div className='add-form'>
              <Title style={{ color: "#2A3F54" }} level={3}>
                Add New Farm Unit
              </Title>
              <Form onSubmit={this.handleSubmit} className={classes.form}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item style={{ margin: 0 }} label='State'>
                      <Select
                        defaultValue='Select State'
                        onChange={(value) => {
                          this.setState({ selectedState: value });
                          this.setLGA(value);
                        }}
                      >
                        {this.state.states.map((item) => {
                          return (
                            <Option key={item.state_id} value={item.state_id}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item style={{ margin: 0 }} label='LGA'>
                      <Select
                        defaultValue='Select LGA'
                        onChange={(value) =>
                          this.setState({ selectedLGA: value })
                        }
                      >
                        {this.state.locals.map((item) => {
                          return (
                            <Option key={item.local_id} value={item.local_id}>
                              {item.local_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ margin: 0 }} label='Address'>
                  <TextArea
                    onChange={(e) => this.setState({ address: e.target.value })}
                  />
                </Form.Item>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Profile (Clay) %'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ first_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Nutrient Profile (Phosphorus) mg/L'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ middle_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Profile (Loam) %'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ first_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Nutrient Profile (Nitrogen) mg/L'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ middle_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Profile (Slit) %'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ first_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ margin: 0 }}
                      label='Soil Nutrient Profile (Potassium) mg/L'
                    >
                      <Input
                        onChange={(e) =>
                          this.setState({ middle_name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ margin: 0 }} label='Size (Ha)'>
                  <Input
                    onChange={(e) => this.setState({ size: e.target.value })}
                  />
                </Form.Item>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item style={{ margin: 0 }} label='Crop'>
                      <Select
                        defaultValue='Select Crop'
                        onChange={(value) => {
                          this.setState({ crops: value });
                        }}
                      >
                        {this.state.cropData.map((item) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.crop_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item style={{ margin: 0 }} label='Farmer'>
                      <Select
                        showSearch
                        onSearch={this.onSearch}
                        filterOption={false}
                        notFoundContent={
                          this.state.fetchingFarmers ? (
                            <Spin size='small' />
                          ) : null
                        }
                        defaultValue='Select Farmer'
                        onChange={(value) => this.setState({ farmer: value })}
                      >
                        {this.state.farmers.map((item) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.first_name} {item.last_name} (
                              {item.farmer_id})
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ margin: 0 }}>
                  <Button
                    className={classes.button}
                    loading={this.state.loading}
                    type='primary'
                    htmlType='submit'
                    style={{ background: "#009688" }}
                  >
                    {this.state.loading ? "" : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AddUser);
