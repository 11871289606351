import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Typography, Button, Breadcrumb, InputNumber, Spin, Select, Tabs
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { formatCurrency } from '../../config/utils'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    spin: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class AddCollection extends React.Component {

    static contextType = AuthContext
    state = {
        isFetching: true,
        farmers: [],
        weight: 0,
        sourcingAgents: [],
        loading: false,
        banks: [],
        activeTab: "1",
        useWeight: false,
        farmer: null,
        sourcing_agent: null,
        crop: 0,
        amount: 0
    }

    componentDidMount() {
        this.fetchContent()
    }

    fetchContent() {
        axios.all([
            axios({
                url: `${config.baseUrl}/farmers`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/config/crop_profiles`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/sourcing/all`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.setState({ isFetching: false })
                this.props.userStore.openNotificationWithIcon('error', err.message)
            })
        ]).then(axios.spread((res1, res2, res3) => {
            console.log(res2.data.entity.profiles)
            this.setState({
                isFetching: false,
                farmers: res1.data.aaData,
                crops: res2.data.entity.profiles,
                sourcingAgents: res3.data.entity.sourcing_agents
            })
        }));
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        if (!this.state.farmer && !this.state.sourcing_agent) {
            return this.props.userStore.openNotificationWithIcon('error', 'Select Farmer or Sourcing Agent')
        }
        const key = this.state.activeTab === "1" ? "farmer" : "sourcing_agent"
        let data = {}
        data = {
            type: key === "farmer" ? "farmer" : "sp",
            [key]: key === "farmer" ? this.state.farmer : this.state.sourcing_agent,
            crop: this.state.crop,
            amount: this.state.weight * 10000,
            weight: this.state.weight
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/collection`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            this.setState({ loading: false })
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            this.props.userStore.openNotificationWithIcon('success', "Successfully added Collection")
            this.props.history.goBack()

        }).catch((err) => {
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/sourcing-agents">Collections</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Collection
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {this.state.isFetching ?
                        <div className={classes.spin}>
                            <Spin />
                        </div>
                        :
                        <div className="add-form">
                            <Title style={{ color: '#2A3F54' }} level={3}>Add New Collection</Title>
                            <Tabs defaultActiveKey={this.state.activeTab} onChange={(key) => this.setState({ activeTab: key })} type="card">
                                <TabPane tab="Farmer" key="1">
                                    <Form onSubmit={this.handleSubmit} className={classes.form}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Farmer">
                                            <Select defaultValue="Select Farmer" onChange={(value) => {
                                                this.setState({ farmer: value })
                                            }}>
                                                {this.state.farmers.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Crop">
                                            <Select defaultValue="Select Crop" onChange={(value) => {
                                                this.setState({ crop: value })
                                            }}>
                                                {this.state.crops.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.crop_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Weight">
                                            <InputNumber min={1} onChange={(val) => this.setState({ weight: val })} />
                                        </Form.Item>
                                        <br />
                                        <Title level={4}>Price: {formatCurrency(this.state.weight * 10000)}</Title>
                                        <Form.Item>
                                            <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                                {this.state.loading ? "" : "Submit"}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                                <TabPane tab="Sourcing Agent" key="2">
                                    <Form onSubmit={this.handleSubmit} className={classes.form}>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Sourcing Agent">
                                            <Select defaultValue="Select Sourcing Agent" onChange={(value) => {
                                                this.setState({ sourcing_agent: value })
                                            }}>
                                                {this.state.sourcingAgents.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.first_name} {item.last_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Crop">
                                            <Select defaultValue="Select Crop" onChange={(value) => {
                                                this.setState({ crop: value })
                                            }}>
                                                {this.state.crops.map((item) => {
                                                    return (
                                                        <Option key={item._id} value={item._id}>{item.crop_name}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        {/* <Form.Item
                                            style={{ margin: 0 }}
                                            label="Use Weight">
                                            <Switch onChange={(value) => this.setState({ useWeight: value })} />
                                        </Form.Item> */}
                                        <Form.Item
                                            style={{ margin: 0 }}
                                            label="Weight">
                                            <InputNumber min={1} onChange={(val) => this.setState({ weight: val })} />
                                        </Form.Item>
                                        {/* {!this.state.useWeight &&
                                            <Form.Item
                                                style={{ margin: 0 }}
                                                label="Price">
                                                <InputNumber min={1} onChange={(val) => this.setState({ price: val })} />
                                            </Form.Item>
                                        } */}
                                        <br />
                                        <Title level={4}>Price: {formatCurrency(this.state.weight * 10000)}</Title>
                                        <Form.Item>
                                            <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                                                {this.state.loading ? "" : "Submit"}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </TabPane>
                            </Tabs>

                        </div>
                    }

                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddCollection);
