import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import Drawer from "../dashboard/Drawer";
import axios from "axios";
import {
  Form,
  Input,
  Typography,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Breadcrumb,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import config from "../../config";
import { Radio } from "@material-ui/core";

const Option = Select.Option;
const TextArea = Input.TextArea;
const { Title } = Typography;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  spin: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: "#fff",
    width: "100%",
  },
});

@inject("userStore")
@observer
class AgentAddFarmUnit extends React.Component {
  state = {
    isFetching: true,
    loading: false,
    first_name: "",
    last_name: "",
    middle_name: "",
    gender: "",
    mobile: "",
    bvn: "",
    bank: "",
    home_address: "",
    lead_farmer: 0,
    dob: "",
    image: null,
    account_no: "",
    selectedState: "",
    selectedLGA: "",
    account_no: "",
    lead_farmer: 0,
    loan_amount: "",
    farm_state: "",
    community: "",
    size: "",
    crops: "",
    farm_address: "",
    states: [],
    locals: [],
    banks: [],
    allCrops: [],
    stepOne: true,
    stepTwo: false,
    stepThree: false,
  };

  // {
  //   "first_name":"John",
  //   "middle_name": "Doe",
  //   "last_name": "Doe",
  //   "gender": "MALE",
  //   "mobile": "08012345678",
  //   "bvn": "12345678",
  //   "bank": "GTB",
  //   "home_address" : "42 Olateju Street",
  //   "home_state" : "25",
  //   "home_lga" : "509",
  //   "account_no" : "12345678",
  //   "lead_farmer" : 0,
  //   "dob": "2022-08-03",
  //   "loan_amount": 1000,
  //   "farm_state":"7",
  //   "community": "124",
  //   "size": 3,
  //   "crops": 14,
  //   "farm_address" : "No 1 Farm Street, Gboko"
  // }

  componentDidMount() {
    this.fetchContent();
    this.setCrop();
  }

  fetchContent() {
    // const { tenant } = this.props.userStore;
    // const filterState = tenant
    //   ? `?country=${encodeURIComponent(tenant.country)}`
    //   : "";
    axios
      .all([
        axios({
          url: `https://test.tradebuza.com/api/v2/states`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${this.props.userStore.token}`,
            Token: `${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `https://test.tradebuza.com/api/v2/banks`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${this.props.userStore.token}`,
            Token: `${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
        axios({
          url: `https://test.tradebuza.com/api/profiles/all`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${this.props.userStore.token}`,
            sessionId: `${this.props.userStore.token}`,
          },
        }).catch((err) => {
          this.setState({ isFetching: false });
          this.props.userStore.openNotificationWithIcon("error", err.message);
        }),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          console.log("res", res1, res2, res3);
          this.setState({
            isFetching: false,
            states: res1.data.entity.states,
            banks: res2.data.entity,
            // allCrops: res3.entity.profiles,
          });
        })
      );
  }

  getLgas(value) {
    this.state.states.map((state) => {
      console.log("state123", state);
      if (state.state_id === value) {
        this.setState({ locals: state.lgas });
      }
    });
  }

  setCrop() {
    axios({
      url: `https://test.tradebuza.com/api/profiles/all`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        sessionId: `${this.props.userStore.token}`,
      },
    })
      .then((res) => {
        console.log("res crops", res);
        this.setState({
          allCrops: res.data.entity.profiles,
        });
        this.setState({ isFetching: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        // return this.props.userStore.openNotificationWithIcon(
        //   "error",
        //   err.message
        // );
      });
  }

  handleSubmit1 = async (e) => {
    e.preventDefault();
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      middle_name: this.state.middle_name,
      mobile: this.state.mobile,
      dob: this.state.dob,
      gender: this.state.gender,
      home_state: this.state.selectedState,
      home_lga: this.state.selectedLGA,
      home_address: this.state.home_address,
      lead_farmer: this.state.lead_farmer,
    };

    if (!this.state.first_name || !this.state.last_name) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Firstname and Lastname required"
      );
    }

    if (!this.state.mobile) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a mobile number"
      );
    }

    if (!this.state.gender) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select your gender"
      );
    }

    if (!this.state.home_address) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a home address"
      );
    }
    // if (!this.state.selectedState) {
    //     return this.props.userStore.openNotificationWithIcon('error', 'Please supply a home state')
    // }
    // if (!this.state.selectedLGA) {
    //     return this.props.userStore.openNotificationWithIcon('error', 'Please supply a home LGA')
    // }
    this.setState({ stepOne: false, stepTwo: true, stepThree: false });
  };

  handleSubmit2 = async (e) => {
    e.preventDefault();
    const data = {
      bank: this.state.bank,
      bvn: this.state.bvn,
      account_no: this.state.account_no,
      loan_amount: this.state.loan_amount,
    };

    // if (!this.state.bank) {
    //   return this.props.userStore.openNotificationWithIcon(
    //     "error",
    //     "Please select a bank"
    //   );
    // }

    // if (!this.state.bvn) {
    //   return this.props.userStore.openNotificationWithIcon(
    //     "error",
    //     "Please supply a BVN"
    //   );
    // }

    if (!this.state.account_no) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply an account number"
      );
    }

    if (!this.state.loan_amount) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a loan amount"
      );
    }

    this.setState({ stepOne: false, stepTwo: false, stepThree: true });
  };

  handleSubmit3 = async (e) => {
    e.preventDefault();
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      middle_name: this.state.middle_name,
      mobile: this.state.mobile,
      bvn: this.state.bvn,
      gender: this.state.gender,
      lead_farmer: this.state.lead_farmer,
      bank: this.state.bank,
      account_no: this.state.account_no,
      home_address: this.state.home_address,
      home_state: this.state.selectedState,
      home_lga: this.state.selectedLGA,
      dob: this.state.dob,
      loan_amount: this.state.loan_amount,
      farm_state: this.state.farm_state,
      community: this.state.selectedLGA,
      size: this.state.size,
      crops: this.state.crops,
      farm_address: this.state.farm_address,
    };
    // alert(JSON.stringify(data));
    // alert("Successfully added Farmer");
    // return;
    if (!this.state.size) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a size"
      );
    }

    if (!this.state.crops) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please select a crop"
      );
    }

    if (!this.state.farm_address) {
      return this.props.userStore.openNotificationWithIcon(
        "error",
        "Please supply a farm address"
      );
    }

    this.setState({ loading: true });

    await axios({
      url: `https://test.tradebuza.com/api/agent/add-farmer`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.userStore.token}`,
        sessionid: this.props.userStore.token,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.data.status.code !== 100) {
          return this.props.userStore.openNotificationWithIcon(
            "error",
            res.data.status.desc
          );
        }
        this.props.userStore.openNotificationWithIcon(
          "success",
          "Successfully added Farmer"
        );
        this.setState({ stepOne: true, stepTwo: false, stepThree: false });
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.userStore.openNotificationWithIcon("error", err.message);
      });
  };

  render() {
    const { classes } = this.props;
    console.log("token", this.props.userStore.token);
    console.log("selectedLGA", this.state.selectedLGA);
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div
            style={{
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: "#f2f3f4",
              padding: 10,
            }}
          >
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/farmers'>Farmer Management</Link>
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item>Add Farmer Information</Breadcrumb.Item> */}
            </Breadcrumb>
          </div>
          {this.state.isFetching === true ? (
            <div className={classes.spin}>
              <Spin />
            </div>
          ) : (
            <div className='add-form2'>
              <Title style={{ color: "#2A3F54" }} level={4}>
                Add Farmer Information
              </Title>

              {this.state.stepOne && (
                <Form onSubmit={this.handleSubmit1} className={classes.form}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label={
                          <span>
                            <span style={{ color: "red" }}>* </span>First Name
                          </span>
                        }
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ first_name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Middle Name'
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ middle_name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label={
                          <span>
                            <span style={{ color: "red" }}>* </span>Last Name
                          </span>
                        }
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ last_name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Phone Number'
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ mobile: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Date of Birth'
                      >
                        <DatePicker
                          onChange={(_, dob) => this.setState({ dob })}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Gender'
                      >
                        <Select
                          defaultValue='Select Gender'
                          onChange={(value) => this.setState({ gender: value })}
                        >
                          <Option value={"MALE"}>Male</Option>
                          <Option value={"FEMALE"}>Female</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Home State'
                      >
                        <Select
                          defaultValue='Select State'
                          onChange={(value) => {
                            console.log("value", value);
                            // get lgas for the selected state
                            this.setState({
                              selectedState: value,
                            });
                            this.getLgas(value);
                            // this.state.states.filter((state) => {
                            //   if (state.state_id === value) {
                            //     console.log("test", state.state_id, value);
                            //     this.setState({
                            //       selectedState: state.state_id,
                            //       locals: state.lgas,
                            //     });
                            //   }
                            // });

                            // this.setState({
                            //   selectedState: value,
                            //   locals: value,
                            // });

                            console.log("selectedState", this.selectedState);
                          }}
                        >
                          {this.state.states.map((item) => {
                            return (
                              <Option key={item.state_id} value={item.state_id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='LGA'
                      >
                        <Select
                          defaultValue='Select LGA'
                          onChange={(value) =>
                            this.setState({ selectedLGA: value })
                          }
                        >
                          {this.state.locals.map((item) => {
                            return (
                              <Option key={item.local_id} value={item.local_id}>
                                {item.local_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    colon={false}
                    style={{ margin: 0 }}
                    label='Home Address'
                  >
                    <TextArea
                      onChange={(e) =>
                        this.setState({ home_address: e.target.value })
                      }
                    />
                  </Form.Item>
                  <input
                    style={{ marginTop: "20px" }}
                    type='file'
                    onChange={(e) => this.setState({ file: e.target.files[0] })}
                  />

                  {/* <Form.Item colon={false} style={{ margin: 0 }} label='yes'>
                    <Radio.Group
                      onChange={(e) => this.setState({ radio: e.target.value })}
                    >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      className={classes.button}
                      loading={this.state.loading}
                      type='primary'
                      htmlType='submit'
                      style={{ background: "#009688" }}
                    >
                      {this.state.loading ? "" : "Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              )}

              {this.state.stepTwo && (
                <Form onSubmit={this.handleSubmit2} className={classes.form}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Bank Name'
                      >
                        <Select
                          defaultValue='Select Bank'
                          onChange={(value) => this.setState({ bank: value })}
                        >
                          {this.state.banks.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Account Number'
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ account_no: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label={<span>BVN</span>}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ bvn: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='Loan Amount'
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ loan_amount: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      className={classes.button}
                      loading={this.state.loading}
                      type='primary'
                      htmlType='submit'
                      style={{ background: "#009688" }}
                    >
                      {this.state.loading ? "" : "Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              )}

              {this.state.stepThree && (
                <Form onSubmit={this.handleSubmit3} className={classes.form}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='State'
                      >
                        <Select
                          defaultValue='Select State'
                          onChange={(value) => {
                            this.setState({ farm_state: value });
                            //   this.setLGA(value);
                          }}
                        >
                          {this.state.states.map((item) => {
                            return (
                              <Option key={item.state_id} value={item.state_id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label='community'
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ community: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    colon={false}
                    style={{ margin: 0 }}
                    label='Address'
                  >
                    <TextArea
                      onChange={(e) =>
                        this.setState({ farm_address: e.target.value })
                      }
                    />
                  </Form.Item>

                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label={<span>Size (Ha)</span>}
                      >
                        <Input
                          onChange={(e) =>
                            this.setState({ size: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        style={{ margin: 0 }}
                        label={<span>Crop</span>}
                      >
                        {/* <Input
                          onChange={(e) =>
                            this.setState({ crops: e.target.value })
                          }
                        /> */}
                        <Select
                          defaultValue='Select Crop'
                          onChange={(value) => this.setState({ crops: value })}
                        >
                          {this.state.allCrops.map((item) => {
                            return (
                              <Option key={item._id} value={item._id}>
                                {item.crop_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col span={12}>
                    {/* <Form.Item
                      colon={false}
                      style={{ margin: 0 }}
                      label='Select Season'
                    >
                      <Select
                        defaultValue='Select Season'
                        onChange={(value) =>
                          this.setState({ selectedLGA: value })
                        }
                      >
                        {this.state.locals.map((item) => {
                          return (
                            <Option key={item.local_id} value={item.local_id}>
                              {item.local_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item> */}
                  </Col>
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      className={classes.button}
                      loading={this.state.loading}
                      type='primary'
                      htmlType='submit'
                      style={{ background: "#009688" }}
                    >
                      {this.state.loading ? "" : "Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </div>
          )}
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AgentAddFarmUnit);
