import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '../dashboard/Drawer'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import {
    Form, Input, Typography, Button, Breadcrumb, Switch
} from 'antd';
import { Link } from 'react-router-dom'
import config from '../../config'
import { AuthContext } from '../../context/AuthContext'

const { Title } = Typography;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    button: {
        // background: '#2A3F54',
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        color: '#fff',
        width: '100%',
        // '&:hover': {
        //     background: '#1d2c3a',
        // },
        // '&:focus': {
        //     outline: 0,
        //     background: '#1d2c3a',
        // },
    }
});

@inject('userStore')
@observer
class AddCropProfile extends React.Component {

    static contextType = AuthContext
    state = {
        crop_name: "",
        yield: "",
        loading: false,
        startSeason: false,
        seasonName: ""
    }

    handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            crop_name: this.state.crop_name,
            yield: this.state.yield
        }
        if (!this.state.crop_name || !this.state.yield) {
            return this.props.userStore.openNotificationWithIcon('error', 'Crop Name and Yield required')
        }
        if (this.state.startSeason && !this.state.seasonName) {
            return this.props.userStore.openNotificationWithIcon('error', 'Season Name required if you want to start season')
        }
        this.setState({ loading: true })
        await axios({
            url: `${config.baseUrl}/config/crop_profile`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            if (this.state.startSeason) {
                return this.startSeason(res.data.entity)
            }
            return this.props.userStore.openNotificationWithIcon('success', "Crop Profile Created and Season Started")
        }).catch((err) => {

            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }

    startSeason = async (res) => {

        const data = {
            name: this.state.seasonName,
            crop: res._id
        }
        await axios({
            url: `${config.baseUrl}/config/season`,
            method: 'POST',
            data,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then((res) => {
            if (res.data.status.code !== 100) {
                return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
            }
            return this.props.userStore.openNotificationWithIcon('success', "Crop Profile Created and Season Started")
        }).then(() => {
            this.props.history.goBack()
        }).catch((err) => {
            this.setState({ loading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })

    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Drawer />
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <div style={{ marginTop: 10, marginBottom: 20, backgroundColor: '#f2f3f4', padding: 10 }}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/crop_profiles">Manage Crop Profiles</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Add New Crop
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="add-form">
                        <Title style={{ color: '#2A3F54' }} level={3}>Add New Crop Profile</Title>
                        <Form onSubmit={this.handleSubmit} className={classes.form}>
                            <Form.Item
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>Crop Name</span>}>
                                <Input onChange={(e) => this.setState({ crop_name: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label={<span><span style={{ color: 'red' }}>* </span>Yield Forecast(Tonnes per Ha</span>}>
                                <Input type="number" onChange={(e) => this.setState({ yield: e.target.value })} />
                            </Form.Item>
                            <Form.Item
                                style={{ margin: 0 }}
                                label="Start Season">
                                <Switch onChange={(value) => this.setState({ startSeason: value })} />
                            </Form.Item>
                            {this.state.startSeason &&

                                <Form.Item
                                    style={{ margin: 0 }}
                                    label={<span><span style={{ color: 'red' }}>* </span>Season Name</span>}>
                                    <Input onChange={(e) => this.setState({ seasonName: e.target.value })} />
                                </Form.Item>
                            }

                            <Form.Item>
                                <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit" style={{background: '#009688'}}>
                                    {this.state.loading ? "" : "Submit"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </main>
            </div>
        );
    }
}


export default withStyles(styles, { withTheme: true })(AddCropProfile);
