import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography, Tabs, Form, Input, Row, Col, Avatar } from 'antd';
import { observer, inject } from 'mobx-react'
import Drawer from '../dashboard/Drawer'
import axios from 'axios'
import config from '../../config'

const { Title } = Typography;
const TabPane = Tabs.TabPane;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    // background: '#2A3F54',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    color: '#fff',
    // '&:hover': {
    //   background: '#1d2c3a',
    // },
    // '&:focus': {
    //   outline: 0,
    //   background: '#1d2c3a',
    // },
  }
});


@inject('userStore')
@observer
class Profile extends React.Component {

  state = {
    first_name: this.props.userStore.firstname,
    last_name: this.props.userStore.lastname,
    email: this.props.userStore.email,
    mobile: this.props.userStore.mobile,
    current: '',
    password: '',
    password2: ''
  }

  handleEditProfile = async (e) => {
    e.preventDefault()
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      mobile: this.state.mobile
    }
    if (!this.state.first_name || !this.state.last_name) {
      return this.props.userStore.openNotificationWithIcon('error', 'Enter first name and last name')
    }
    if (!this.state.mobile) {
      return this.props.userStore.openNotificationWithIcon('error', 'Enter phone number')
    }
    this.setState({ loading: true })
    await axios({
      url: `${config.baseUrl}/account`,
      method: 'PUT',
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then((res) => {
      this.setState({ loading: false })
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }
      this.props.userStore.updateUser(res.data.entity)
      this.props.userStore.openNotificationWithIcon('success', "Successfully Updated Profile")

    }).catch((err) => {
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }

  handleChangePassword = async (e) => {
    e.preventDefault()
    const data = {
      old_password: this.state.current,
      password: this.state.password,
      confirm_password: this.state.password2
    }
    if (!this.state.current || !this.state.password) {
      return this.props.userStore.openNotificationWithIcon('error', 'Enter current password and new password')
    }
    if (this.state.password !== this.state.password2) {
      return this.props.userStore.openNotificationWithIcon('error', 'Passwords do not match')
    }
    this.setState({ loading: true })
    await axios({
      url: `${config.baseUrl}/account/password`,
      method: 'PUT',
      data,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.props.userStore.token}`
      },
    }).then((res) => {
      this.setState({ loading: false })
      if (res.data.status.code !== 100) {
        return this.props.userStore.openNotificationWithIcon('error', res.data.status.desc)
      }

      this.props.userStore.openNotificationWithIcon('success', "Successfully Changed Password")

    }).catch((err) => {
      this.props.userStore.openNotificationWithIcon('error', err.message)
    })
  }


  render() {
    const { classes } = this.props
    console.log(this.props.userStore)
    return (
      <div className={classes.root}>
        <Drawer />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>
            <Title style={{ color: '#2A3F54' }} level={3}>Profile</Title>
          </div>
          <Row gutter={16} style={{ marginTop: 40 }}>
            <Col span={6}>
              <div>
                <Avatar shape="square" size={100} style={{ backgroundColor: '#2A3F54', marginBottom: 10 }} icon="user" />
                <h5 style={{ color: '#2A3F54' }}>{this.props.userStore.firstname} {this.props.userStore.lastname}</h5>
                <h6 style={{ color: '#2A3F54', margin: 2, fontWeight: 'bold' }}>{this.props.userStore.role.role}</h6>
                <p style={{ color: '#2A3F54', margin: 2 }}>{this.props.userStore.mobile}</p>
                <p style={{ color: '#2A3F54', margin: 2 }}>{this.props.userStore.email}</p>
                <p style={{ color: '#2A3F54', margin: 2 }}>{this.props.userStore.tenant ? this.props.userStore.tenant.name : ""}</p>
              </div>
            </Col>
            <Col span={18}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Edit Profile" key="1">
                  <div className="edit-form">
                    <Form onSubmit={this.handleEditProfile} className={classes.form}>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="First Name">
                        <Input value={this.state.first_name} onChange={(e) => this.setState({ first_name: e.target.value })} />
                      </Form.Item>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Last Name">
                        <Input value={this.state.last_name} onChange={(e) => this.setState({ last_name: e.target.value })} />
                      </Form.Item>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Phone Number">
                        <Input
                          value={this.state.mobile}
                          onChange={(e) => this.setState({ mobile: e.target.value })}
                          placeholder="Enter your Phone Number"
                          // addonAfter={
                          //   <Button type="link">
                          //     <Icon type="close-circle" style={{ color: 'red' }} />
                          //     Not Verified
                          //   </Button>
                          // }
                        />

                      </Form.Item>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Email">
                        <Input value={this.state.email} type="email" disabled />
                      </Form.Item>
                      <Form.Item>
                        <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                          {this.state.loading ? "" : "Submit"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>
                <TabPane tab="Change Password" key="2">
                  <div className="edit-form">
                    <Form onSubmit={this.handleChangePassword} className={classes.form}>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Current Password">
                        <Input type="password" value={this.state.current} onChange={(e) => this.setState({ current: e.target.value })} />
                      </Form.Item>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="New Password">
                        <Input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                      </Form.Item>
                      <Form.Item
                        style={{ margin: 0 }}
                        label="Confirm Password">
                        <Input type="password" value={this.state.password2} onChange={(e) => this.setState({ password2: e.target.value })} />
                      </Form.Item>
                      <Form.Item>
                        <Button className={classes.button} loading={this.state.loading} type="primary" htmlType="submit">
                          {this.state.loading ? "" : "Submit"}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </main>
      </div>
    );
  }
}


export default withStyles(styles, { withTheme: true })(Profile);
