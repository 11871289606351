import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Table, Card, Col, Row, Spin, Select, DatePicker, Progress } from 'antd';
import { observer, inject } from 'mobx-react'

import { AiOutlineAreaChart } from 'react-icons/ai'
import { IoIosPeople } from 'react-icons/io'
import { GoPrimitiveDot } from 'react-icons/go'
import moment from 'moment';
import axios from 'axios';
import config from '../../config'
import { formatNumber } from '../../config/utils'

const { Column } = Table;
const { Option } = Select;
const { RangePicker } = DatePicker;


const styles = theme => ({
    root: {
        display: 'flex',
        backgroundColor: '#F3F7FA',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        backgroundColor: '#F3F7FA'
    },
    card: {
        maxWidth: 400,
        marginBottom: theme.spacing.unit * 2.2,
    },

    cardTitle: {
        fontWeight: 700,
    }
});

@inject('userStore')
@observer
class MetaDash extends React.Component {
    state = {
        seasons: [],
        season: "",
        states: [],
        state: "",
        crops: [],
        crop: "",
        dateFilter: "",
        associations: [],
        association: "",
        date: [],
        refreshing: false,
        isLoading: true
    };

    async componentDidMount() {
        this.fetchContent(this.getPath())
        this.fetchFilters()
    }


    fetchFilters() {
        const { tenant } = this.props.userStore
        const filterState = tenant ? `?country=${encodeURIComponent(tenant.country)}` : ''
        axios.all([
            axios({
                url: `https://stagingapi.tradebuza.com/process/opeissue/fetchCrops/dvc`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/states${filterState}`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/farmers/client_name`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
            axios({
                url: `${config.baseUrl}/project_names`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${this.props.userStore.token}`
                },
            }).catch(err => {
                this.props.userStore.openNotificationWithIcon('error', err.message)
            }),
        ]).then(axios.spread((res1, res2, res3, res4) => {
            this.setState({ crops: res1.data.entity, states: res2.data.entity.states, associations: res3.data.entity, seasons: res4.data.entity })
        }));
    }

    fetchContent = async (path) => {
        await axios({
            url: `${config.baseUrl}/dashboard/cluster/farmers?data=0${path}`,
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.props.userStore.token}`
            },
        }).then(res => {
            this.setState({ isLoading: false, data: res.data.entity })
        }).catch(err => {
            this.setState({ isLoading: false })
            this.props.userStore.openNotificationWithIcon('error', err.message)
        })
    }


    calculateAreaAssigned = (data) => {
        const table = data.table_data2
        const total = table.reduce((a, b) => a + parseFloat(b.land_assigned), 0)
        return total.toFixed(1)
    }


    calculateAreaMapped = (data) => {
        const table = data.table_data2
        const total = table.reduce((a, b) => a + parseFloat(b.land_mapped), 0)
        // console.log(total)
        const perc = (total / this.calculateAreaAssigned(data)) * 100
        return { perc: parseFloat(perc.toFixed(1)), total: total.toFixed(1) }
    }

    getPath = () => {
        const filterState = this.state.state ? `&state=${this.state.state}` : ''
        const filterCrop = this.state.crop ? `&crop=${this.state.crop}` : ''
        const filterSeason = this.state.season ? `&season=${this.state.season}` : '';
        const filterAssoc = this.state.association ? `&association=${this.state.association}` : '';
        const filterDateFrom = this.state.dateFilter && this.state.date && this.state.date.length && this.state.date[0] ? `&date_from=${this.state.date[0]}` : '';
        const dateFilter = this.state.date.length && this.state.dateFilter ? `&date_filter=${this.state.dateFilter}` : ``
        const filterDateTo = this.state.dateFilter && this.state.date && this.state.date.length && this.state.date[1] ? `&date_to=${this.state.date[1]}` : '';
        const filterPath = filterState + filterCrop + filterSeason + filterDateFrom + filterDateTo + dateFilter + filterAssoc;

        return filterPath;
    }


    checkFilter = () => {
        const path = this.getPath()
        this.setState({ isLoading: true })
        this.fetchContent(path)
    }


    renderArea = (data, check) => {
        const result = data && data.find(item => item.v_result === check)
        if (!result) {
            return 0;
        }
        else {
            return formatNumber(parseFloat(result.area).toFixed(2))
        }
    }



    render() {
        const { classes } = this.props;
        return (
            <div>
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Select
                            style={{ width: '15%' }}
                            defaultValue="Filter Season"
                            onChange={(value) => {
                                this.setState({
                                    season: value
                                }, () => {
                                    this.checkFilter();
                                });
                            }}>
                            <Option value="">All</Option>
                            {this.state.seasons.filter(item => item.season).map((item) => {
                                return (
                                    <Option key={item.season} value={item.season}>{item.season}</Option>
                                )
                            })}
                        </Select>
                        <Select
                            style={{ width: '15%' }}
                            defaultValue="Filter State"
                            onChange={(value) => {
                                this.setState({
                                    state: value
                                }, () => {
                                    this.checkFilter();
                                });
                            }}>
                            <Option value="">All</Option>
                            {this.state.states.map(state => (
                                <Option key={state.state_id} value={state.state_id}>{state.name}</Option>

                            ))}
                        </Select>

                        <Select
                            style={{ width: '15%' }}
                            defaultValue="Filter Crop"
                            onChange={(value) => {
                                this.setState({
                                    crop: value
                                }, () => {
                                    this.checkFilter();
                                });
                            }}>
                            <Option value="">All</Option>
                            {this.state.crops.map(crop => (
                                <Option key={crop._id} value={crop._id}>{crop.crop_name}</Option>
                            ))}
                        </Select>
                        <Select
                            style={{ width: '15%' }}
                            defaultValue="Filter Association"
                            onChange={(value) => {
                                this.setState({
                                    association: value
                                }, () => {
                                    this.checkFilter();
                                });
                            }}>
                            <Option value="">All</Option>
                            {this.state.associations.filter(assoc => assoc.client_name).map((item, i) => (
                                <Option key={i} value={item.client_name}>{item.client_name}</Option>
                            ))}

                        </Select>
                        <Select
                            style={{ width: '20%' }}
                            defaultValue="Date Filter Category"

                            onChange={(value) => {
                                this.setState({
                                    dateFilter: value
                                });
                            }}>
                            <Option value="">All</Option>
                            <Option value="created">Date Created</Option>
                            <Option value="captured_date">KYC Captured Date</Option>
                            <Option value="mapped_date">Farm Mapped Date</Option>
                            <Option value="bvn_submitted_date">BVN Submitted Date</Option>
                        </Select>
                        <RangePicker
                            style={{ width: '20%' }}
                            disabled={!this.state.dateFilter}
                            defaultValue={[]}
                            ranges={{
                                Today: [moment(), moment()],
                                'Yesterday': [moment().add(-1, 'days'), moment().add(-1, 'days')],
                                'Last 7 Days': [moment().add(-7, 'days'), moment()],
                                'Last 30 Days': [moment().add(-30, 'days'), moment()],
                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                'Last Month': [moment().subtract(1, 'months').date(1), moment().subtract(1, 'months').endOf('month')],
                                'All Time': [],
                            }}
                            onChange={(_, date) => {
                                this.setState({
                                    date
                                }, () => {

                                    this.checkFilter(date)
                                });
                            }}
                        />

                    </div>
                    <br />
                </>
                {this.state.isLoading ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Spin />
                    </div> :
                    <>
                        <div style={{ margin: '20px auto' }}>
                            <Row gutter={8}>
                                <Col span={7}>
                                    <Card style={{ borderRadius: 5, height: 377 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>Total Pool</h6>
                                        </div>
                                        <div className="table-container">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <div>
                                                    <AiOutlineAreaChart style={{ fontSize: 50, color: '#3c9af7' }} />
                                                </div>
                                                <div>
                                                    <h5 style={{ textAlign: 'right', fontWeight: 700, color: '#3c9af7' }}>{formatNumber(this.calculateAreaAssigned(this.state.data))}</h5>
                                                    <p style={{ textAlign: 'right' }}>Land Area Assigned (Ha)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="table-container">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <div>
                                                    <Progress type="circle" percent={this.calculateAreaMapped(this.state.data).perc} width={50} strokeColor="#2135fa" />
                                                </div>
                                                <div>
                                                    <h5 style={{ textAlign: 'right', fontWeight: 700, color: '#2135fa' }}>{formatNumber(this.calculateAreaMapped(this.state.data).total)}</h5>
                                                    <p style={{ textAlign: 'right' }}>Area Mapped (Ha)</p>
                                                </div>
                                            </div>
                                            <Progress percent={this.calculateAreaMapped(this.state.data).perc} size="small" showInfo={false} strokeWidth={5} strokeColor="#2135fa" />
                                        </div>
                                    </Card>

                                </Col>
                                <Col span={7}>
                                    <Card style={{ borderRadius: 5, height: 377 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>Clusters</h6>
                                        </div>
                                        <div className="table-container">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>

                                                <div>
                                                    <IoIosPeople style={{ fontSize: 50, color: '#3173d3' }} />
                                                </div>
                                                <div>
                                                    <h5 style={{ textAlign: 'right', fontWeight: 700, color: '#3173d3' }}>{this.state.data.farmers_assigned}</h5>
                                                    <p style={{ fontWeight: 700, textAlign: 'right' }}>Clusters Assigned</p>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="table-container">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                <div>
                                                    <Progress type="circle" percent={!isNaN ? ((this.state.data.kyc_captured / this.state.data.farmers_assigned) * 100).toFixed(1) : 0} width={50} strokeColor="#4fcb51" />
                                                </div>
                                                <div>
                                                    <h5 style={{ textAlign: 'right', fontWeight: 700, color: "#4fcb51" }}>{this.state.data.kyc_captured}</h5>
                                                    <p style={{ fontWeight: 700 }}>KYF Captured</p>
                                                </div>
                                            </div>
                                            <Progress percent={!isNaN ? ((this.state.data.kyc_captured / this.state.data.farmers_assigned) * 100).toFixed(1) : 0} size="small" showInfo={false} strokeWidth={5} strokeColor="#4fcb51" />
                                        </div>
                                    </Card>

                                </Col>
                                <Col span={10}>
                                    <Card style={{ borderRadius: 5, height: 377 }}>
                                        <div>
                                            <h6 className={classes.cardTitle}>ID produced</h6>
                                        </div>
                                        <br />
                                        <div>
                                            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Progress type="circle" percent={0} width={160} strokeWidth={20} format={percent => `${percent}`} strokeColor="#8a1b94" />

                                                    <div style={{ marginLeft: 20 }}>
                                                        <div style={{ maarginBottom: 10 }}><span><GoPrimitiveDot style={{ color: "#8a1b94" }} /></span>Submitted</div>

                                                        <div style={{ maarginBottom: 10 }}><span><GoPrimitiveDot style={{ color: "#dbdbdb" }} /></span>Produced</div>
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                    <div>
                                                        <p>Produced</p>
                                                        <h6>0</h6>
                                                    </div>
                                                    <div>
                                                        <p>Submited to DFO</p>
                                                        <h6>0</h6>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card>

                                </Col>
                            </Row>
                            <br />
                            <div className="table-container">
                                <Row gutter={8}>
                                    <Col span={7}>
                                        <Card style={{ borderRadius: 5 }}>
                                            <div>
                                                <h6 className={classes.cardTitle}>Digital Satellite Monitoring Verification</h6>
                                            </div>
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #f1f1f1', padding: '10px 20px' }}>
                                                    <div>
                                                        <Progress type="circle" percent={100} width={40} strokeWidth={3} strokeColor="#ccc" />
                                                    </div>
                                                    <div>
                                                        <h5 style={{ textAlign: 'right', fontWeight: 700, color: "#4fcb51" }}>{this.renderArea(this.state.data.dsm, "PASS")} </h5>
                                                        <p style={{ textAlign: 'right' }}>Area Approved (Ha)</p>
                                                    </div>
                                                </div>
                                                <br />
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid #f1f1f1', padding: '10px 20px' }}>
                                                    <div>
                                                        <Progress type="circle" percent={100} width={40} status="exception" strokeColor="#ccc" />
                                                    </div>
                                                    <div>
                                                        <h5 style={{ textAlign: 'right', fontWeight: 700, color: "#ea1b2a" }}>{this.renderArea(this.state.data.dsm, "FAIL")}</h5>
                                                        <p style={{ textAlign: 'right' }}>Area Rejected (Ha)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                        <Card style={{ borderRadius: 5 }}>
                                            <div>
                                                <h6 className={classes.cardTitle}>Input Distribution</h6>
                                            </div>
                                            <div className="table-container">
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div>
                                                        <Progress type="circle" percent={0} width={80} strokeWidth={20} format={percent => `${percent}`} strokeColor="#2534ef" />
                                                        <br />

                                                        <p style={{ marginTop: 10 }}>% Input Distributed</p>
                                                    </div>
                                                    <div>
                                                        <h5 style={{ textAlign: 'right', fontWeight: 700, color: "#2534ef" }}>0</h5>
                                                        <p style={{ textAlign: 'right' }}>Farmers</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col span={17}>
                                        <Card bordered={false} style={{ borderRadius: 5, }}>
                                            <div>
                                                <Table
                                                    size="middle"
                                                    pagination={{ pageSize: 5 }}
                                                    loading={this.state.isLoading}
                                                    bordered
                                                    rowKey={(record, i) => i}
                                                    dataSource={this.state.data.table_data2}
                                                >
                                                    <Column
                                                        title="State"
                                                        dataIndex="home_state"
                                                        key="home_state"
                                                    />
                                                    <Column
                                                        title="Association"
                                                        dataIndex="client_name"
                                                        key="client_name"
                                                    />
                                                    <Column
                                                        title="Land Assigned (Ha)"
                                                        dataIndex="land_assigned"
                                                        key="land_assigned"
                                                        render={(text) => (
                                                            <span>{formatNumber(text)}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Land Mapped (Ha)"
                                                        dataIndex="land_mapped"
                                                        key="land_mapped"
                                                        render={(text) => (
                                                            <span>{formatNumber(text)}</span>
                                                        )}
                                                    />
                                                    <Column
                                                        title="Clusters Assigned"
                                                        dataIndex="farmers_assigned"
                                                        key="farmers_assigned"
                                                    />
                                                    <Column
                                                        title="KYF Captured"
                                                        dataIndex="total_kyc_captured"
                                                        key="total_kyc_captured"
                                                    />

                                                </Table>
                                            </div>
                                        </Card>

                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </>
                }
            </div>

        );
    }
}


export default withStyles(styles, { withTheme: true })(MetaDash);
